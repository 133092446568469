<form id="confidentialForm" *ngIf="!isLoading && feedbackForm" [formGroup]="feedbackForm"
    novalidate>
    <div *ngIf="isSaved && !feedbackId && !dismissed" class="row form-group p-3">
        <mat-card appearance="outlined" class="bg-success text-white">
            <mat-card-content class="flex">
                <button type="button" class="close" aria-label="Close"
                    (click)="closeAlert()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="ml-4">
                    <span><i class="far fa-check-circle"></i></span> You've
                    successfully saved a feedback draft. <a (click)="navigateToFeedback()" (keyup.enter)="navigateToFeedback()" [tabindex]="0" class="alert-link">Create another?</a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="row form-group col-md-12 col-sm-12 col-xs-12"
        [ngClass]="{'has-error': feedbackForm.get('onliner')?.errors && feedbackForm.get('onliner')?.touched}">
        <div class="row form-group col-md-12 col-sm-6 col-xs-12">
            <div class="input-pad">
                <mat-form-field appearance="outline" class="onliner-width"
                    [ngClass]="{'editable': !(isSaved)}">
                    <mat-label class="required-label">Choose Onliner</mat-label>
                    <div>
                        <input class="onliner-width" [readonly]="isSaved"
                            matInput placeholder="Choose Onliner"
                            aria-label="Onliner" name="onliner"
                            [matAutocomplete]="onlinerauto"
                            formControlName="onliner"
                            [errorStateMatcher]="errorMatcher"
                            [style.color]="isDraft ? disabledColor: defaultColor" />
                        <mat-autocomplete #onlinerauto="matAutocomplete"
                            [displayWith]="onlinerDisplay"
                            (optionSelected)="onlinerSelected($event)">
                            <mat-option class="options"
                                *ngFor="let option of filteredOnliners | async"
                                [value]="option">
                                {{ onlinerDisplay(option) }}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <mat-error *ngIf="showError('onliner')">Please enter the
                        name of the Onliner</mat-error>
                </mat-form-field>
            </div>
            <div
                *ngIf="onlinerAssigned && !(feedbackForm.get('onliner')?.errors)">
                <mat-form-field appearance="outline" class="onliner-width">
                    <mat-label>Practice</mat-label>
                    <input class="onliner-width mat-test" matInput type="text"
                        placeholder="Practice" aria-label="Practice"
                        [disabled]="true" [value]="practice" />
                </mat-form-field>
            </div>
        </div>

        <div class="question-width">
            <div *ngFor="let question of questionsForThisForm">
                <div class="label-header reduce-label-spacing"
                    [ngClass]="{'required-label' : !question.isOptional }">{{ displayQuestion(question) }}</div>
                <mat-card
                    class="label-header reduce-label-spacing" class="col-md-12 col-sm-10 col-xs-12">
                    <mat-card-content>
                        <div *ngIf="question.type.toString() === 'Radio'">
                            <div
                                class="row form-group col-md-12 col-sm-10 col-xs-12"
                                [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                                <mat-radio-group
                                    formControlName="{{question.id}}">
                                    <mat-radio-button
                                        *ngFor="let option of validQuestionOptions(question)"
                                        [value]="option.id">
                                        <span class="radio-rap">{{
                                            optionDisplay(option) }}</span>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-content *ngIf="question.type.toString() === 'Radio_Text'">
                        <div class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                            <mat-radio-group formControlName="{{question.id}}">
                              <mat-radio-button *ngFor="let option of validQuestionOptions(question)" [value]="option.id" (change)="radioTextChange($event.value, question.id)">
                                <span class="radio-rap">{{ optionDisplay(option) }}</span>
                              </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <mat-form-field appearance="outline" class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getRadioTextQuestionId(question.id))?.errors && feedbackForm.get(getRadioTextQuestionId(question.id))?.touched }">
                        <textarea type="text"
                                    matInput
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="5"
                                    style="overflow:hidden"
                                    id="{{getRadioTextQuestionId(question.id)}}"
                                    formControlName="{{getRadioTextQuestionId(question.id)}}"
                                    placeholder="Enter comments here..."></textarea>
                        </mat-form-field>
                    </mat-card-content>
                    <div *ngIf="question.type.toString() === 'Text'">
                        <mat-form-field id="confidentialForm" appearance="outline"
                            class="form-group col-md-12 col-sm-10 col-xs-12 mb-0 pb-0"
                            [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                            <textarea class="text" type="text" matInput
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="5" style="overflow:hidden"
                                id="{{getID(question.id)}}"
                                formControlName="{{getID(question.id)}}">
                            </textarea>
                        </mat-form-field>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="row form-group">
        <button type="button" mat-stroked-button
            class="save-btn"
            color="basic"
            (click)="saveFeedback()"
            [disabled]="isSaveButtonDisabled() || feedbackForm.pristine || !hasQuestions">
            <span *ngIf="isSaving"><i class="fa fa-spinner fa-spin"
                    aria-hidden="true"></i></span>{{ isSaved ? 'UPDATE'
            : 'SAVE' }}
        </button>
        <button type="button" mat-stroked-button
            class="submit-btn"
            color="primary"
            (click)="submitFeedback()"
            [disabled]="!feedbackForm.valid || isSubmitting || isSaving || isSaveButtonDisabled() || !hasQuestions">
            <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin"
                    aria-hidden="true"></i></span> SUBMIT
        </button>
    </div>
</form>
