import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription, forkJoin } from 'rxjs';
import { Client, CommonService } from 'src/app/core/services/common.service';
import {
  FeedbackService,
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionOptionGroup,
} from 'src/app/core/services/feedback.service';
import { Employee } from 'src/app/core/services/onliner.service';
import {
  FeedbackStatusEnum,
  FeedbackTypeEnum,
} from 'src/app/shared/enums/enums.model';
// import { AuthService } from "../services/auth.service";
import { DatePipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  FbFeedbacksSelfAssessment,
  FbSelfAssessment,
  FbSelfAssessmentAnswer,
  FbSelfAssessmentFeedback,
  FbSelfAssessmentReviewer,
  FbSelfAssessmentReviewerAnswer,
  FocalReviewBaseCamp,
  FocalReviewDetails,
  FocalReviewRevenue,
  FocalSickTime,
  FocalUtilization,
  FocalWorkHistory,
  OnlinerGoals,
  PDBudgetSummary,
  PDDeductionSummary,
  SelfAssessmentService,
} from 'src/app/core/services/self-assessment.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { OnlinerPersonalInfo } from 'src/app/shared/models/onliner-personal-info.model';
// import * as formValidators from 'src/app/shared/validators/form-validator';
import {
  CompControlErrorStateMatcher,
  requiredAndNoWhiteSpaceValidator,
} from 'src/app/shared/validators/form-validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-self-assessment-detail',
  templateUrl: './view-self-assessment-detail.component.html',
  styleUrls: ['./view-self-assessment-detail.component.scss'],
})
export class ViewSelfAssessmentDetailComponent implements OnInit, OnChanges {
  @ViewChild('autosize')
  autosize!: CdkTextareaAutosize;

  reportForm!: UntypedFormGroup;
  selectedFormId: number = FeedbackTypeEnum.SelfAssessment;

  questionOptions!: QuestionOption[];
  questionOptionGroupIDs!: number[];
  questions!: Question[];
  questionGroups!: QuestionGroup[];
  questionGroupIDs!: number[];
  questionOptionGroups!: QuestionOptionGroup[];
  selectedType!: FormType;
  selfAssessmentFormType: FormType = {
    Id: FeedbackTypeEnum.SelfAssessment,
    name: 'Self Assessment',
  };
  onliners!: Employee[];
  clients!: Client[];
  onlinerCM = '';
  onlinerCMUserId = '';
  tooltipItems!: string[];
  toolTip!: string;
  onlinerAssigned = false;
  clientAssigned = false;
  dismissed = false;
  questionsForThisForm!: Question[];
  isDraft = false;
  reloadRequired = false;
  reports!: FbSelfAssessment;
  role!: string;
  selfAssessmentId!: number;
  firstName!: string;
  lastName!: string;
  year!: number;
  isLead!: boolean;
  isReviewer!: boolean;
  isEditorReviewer!: boolean;
  isAdmin!: boolean;
  isAssessmentFromPreviousYear = true;
  isUser = false;
  submitterPractice!: string;
  fbLeadPractice!: string;
  selfAssessmentReviewers: FbSelfAssessmentReviewer[] = [];
  submissionYears!: FbSelfAssessment[];
  submissionYearsList!: number[];
  allSelfAssessmentDetailsList: SelfAssessmentDetails[] = [];
  yearlySAReport!: FbSelfAssessment;
  yearlySAReports!: FbSelfAssessment[];
  submissionYear!: number;
  selectedYear!: number;
  startDate!: string;
  primaryCompetency!: string;
  consultantCompetency!: string;
  practice!: string;
  isSaved = false;
  onlinerGoals!: OnlinerGoals[];
  hasOnlinerGoals = false;
  fbRequestCountProvidedForOnliners!: number;
  fbRequestCountRequestedByOnliners!: number;
  fbRequestCountRequestedFromClients!: number;
  fbRequestCountRequestedFromOnliners!: number;
  resumeUpdateDate!: string;
  countLateTimeSheetSubmission!: string;
  countWeeklyHealthSubmission!: string;
  submitterId!: string;
  feedbackCount = 0;
  mainQuestionsForThisForm!: Question[];
  subQuestionsForThisForm!: Question[];
  PDBudgetSummaryCurrent!: PDBudgetSummary;
  PDBudgetSummaryPrevious!: PDBudgetSummary;
  PDDeductionSummaryUnitsCurrent: PDDeductionSummary[] = [];
  PDDeductionSummaryUnitsPrevious: PDDeductionSummary[] = [];
  PDDeductionSummaryHoursCurrent: PDDeductionSummary[] = [];
  PDDeductionSummaryHoursPrevious: PDDeductionSummary[] = [];
  focalReviewDetails!: FocalReviewDetails;
  focalReviewBaseCamp!: FocalReviewBaseCamp;
  focalReviewRevenue!: FocalReviewRevenue;
  focalSickTime!: FocalSickTime;
  focalUtilization: FocalUtilization[] = [];
  focalWorkHistory: FocalWorkHistory[] = [];

  // feedback section variables
  feedbacks: FbSelfAssessmentFeedback[] = [];
  submitterName!: string;
  feedbackType: string[] = [];
  feedbackTypeDescr!: string;
  feedbackQuestions: string[] = [];
  feedbackAnswers: string[] = [];
  feedbackId: number[] = [];
  feedbackQuestionType: string[] = [];
  uniqueFeedbacks: FbFeedbacksSelfAssessment[] = [];

  defaultColor = 'rgb(156, 30, 54)';
  disabledColor = 'rgba(0,0,0,.38)';
  currentYear!: number;
  previousYear!: number;
  previousHours!: number;
  currentHours!: number;
  previousBillableHours!: number;
  currentBillableHours!: number;
  previousSickHours!: number;
  currentSickHours!: number;
  budgetYearCurrent!: string;
  budgetYearPrevious!: string;
  deliveryDisclaimerMonthYear!: string;

  unsolicitedFormId: number = FeedbackTypeEnum.AdhocFeedback;
  clientreportFormId: number = FeedbackTypeEnum.ClientFeedback;
  ciaFormId: number = FeedbackTypeEnum.CultureInAction;
  autoSaveSubscription!: Subscription;
  scheduleId!: number;

  get onlinerFilterControl() {
    return this.reportForm.controls['onliner'];
  }

  get onlinerAndClientValidation() {
    return this.reportForm.controls['onliner'].valid;
  }

  isLoading!: boolean;
  nameReady = false;
  isSaving = false;
  isSubmitting = false;
  isReviewedForThisYear = false;
  isFeedbackAdmin!: boolean;
  feedbackTypeIndicator!: number;
  isNew!: boolean;
  isFeedbackRequest!: boolean;
  minDate: Date = new Date();
  isDisplay = false;
  user!: OnlinerPersonalInfo;

  errorMatcher = new CompControlErrorStateMatcher();

  questionEnumToGroupTitle = new Map<string, string>([
    ['CoreValueSA', 'CORE VALUES'],
    ['CorpCitizenshipSA', 'CORPORATE CITIZENSHIP'],
    ['GrowthDevSA', 'GROWTH & DEVELOPMENT'],
    ['DeliverySA', 'Delivery'],
  ]);

  theCheckbox = false;

  submittedForThisYear = false;

  constructor(
    private selfAssessmentService: SelfAssessmentService,
    private snackBarService: SnackBarService,
    private feedbackService: FeedbackService,
    private commonService: CommonService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private location: Location
  ) {}

  showError = (controlName: string) => {
    const control = this.reportForm.controls[controlName];

    if (control && control.hasError('required') && control.touched) {
      return true;
    }
    return false;
  };

  ngOnInit() {
    this.isLead = this.authService.doesUserHaveRole([
      environment.roles.FeedbackLead,
    ]);

    this.isReviewer = this.authService.doesUserHaveRole([
      environment.roles.FeedbackReviewer,
    ]);

    this.isAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackAdmin,
    ]);

    this.selfAssessmentService.GetSelfAssessmentReviewers().subscribe({
      next: data => (this.selfAssessmentReviewers = data),
    });

    if (!this.selfAssessmentId) {
      this.route.params.subscribe(params => {
        this.selfAssessmentId = +params['id'];
        this.scheduleId = +params['scheduleId'];
      });
      this.route.queryParams.subscribe(params => {
        this.isEditorReviewer = params['isEditor'] === 'true';
      });
    }

    this.setupPage();
  }

  getQuestions() {
    forkJoin([
      this.commonService.getQuestionsForSchedule(this.scheduleId),
      this.commonService.getQuestionGroups(),
      this.commonService.getQuestionOptions(),
      this.commonService.getQuestionOptionGroups(),
    ]).subscribe(
      ([questions, questionGroups, questionOptions, questionOptionGroups]) => {
        this.questions = [...questions];
        this.questionGroups = [...questionGroups];
        this.questionOptions = [...questionOptions];
        this.questionOptionGroups = [...questionOptionGroups];

        const group = this.questionGroups.filter(
          qg => qg.type.valueOf() === this.selectedFormId
        );
        if (group) {
          if (group.length === 1) {
            this.questionGroupIDs = [group[0].id];
            this.populateQuestionOptionGroupIDs(group[0]);
          } else if (group.length > 1) {
            if (!this.questionGroupIDs) {
              this.questionGroupIDs = [];
            }
            group.forEach(q => {
              this.questionGroupIDs.push(q.id);
              this.populateQuestionOptionGroupIDs(q);
            });
          }
        }

        this.questionGroupIDs.forEach(qgId => {
          const questionsToFill = this.questions.filter(
            q => q.groupId === qgId
          );
          this.questionsForThisForm = [...questionsToFill];
        });

        this.selectedType = this.selfAssessmentFormType;
        this.createFeedbackForm(this.selectedType);
      }
    );
  }

  ngOnChanges() {
    this.setupPage();
  }

  closeAlert() {
    this.dismissed = true;
  }

  populateForm() {
    this.selfAssessmentService
      .getSelfAssessmentDetail(this.selfAssessmentId)
      .subscribe(
        data => {
          this.reports = data;
          if (
            this.authService.getUserId().toLowerCase() ===
            this.reports.submittedBy.toLowerCase()
          ) {
            this.isUser = true;
            this.isReviewer = false;
          }
          this.getFullName(this.reports.submittedBy);
          this.getYearlyAssessments();
          this.selectedYear = data.fiscalYear;
          this.year = data.fiscalYear;
          this.checkEndDate();
          this.isReviewedForThisYear =
            this.reports.log.findIndex(
              l =>
                l.statusId === FeedbackStatusEnum.Reviewed ||
                l.statusId === FeedbackStatusEnum.Reviewed_Updated
            ) > -1;
          const allQuestions = this.questionsForThisForm;
          // Questions for self assessment year 2021 or older
          if (this.selectedYear < 2022) {
            this.isDisplay = false;
            const commentBoxQuestion = this.questionsForThisForm.filter(
              q => !q.isOptional && q.optionGroupId === null
            );
            this.mainQuestionsForThisForm = this.questionsForThisForm.filter(
              el => {
                return !commentBoxQuestion.includes(el);
              }
            );
          } else if (this.selectedYear === 2022) {
            // Questions for new self assessment year 2022 or after
            const mainQuestions = allQuestions.filter(
              q => q.type.toString() !== 'Text' && !q.isOptional
            );
            const subQuestions = allQuestions.filter(
              q => q.type.toString() === 'Text' && !q.isOptional
            );
            this.mainQuestionsForThisForm = [...mainQuestions];
            this.subQuestionsForThisForm = [...subQuestions];
            this.GetOnlinerGoals();
            this.GetFeedbackData();
            this.GetCountOfFeedbacks();
            this.getCorporateCitizenship();
            this.GetPDHistory();
            this.GetFocalReviewDetails();
            this.isDisplay = true;
          } else {
            if (this.questionGroupIDs !== null) {
              const groupedQuestions = this.questions.reduce(
                (acc: { [key: string]: Question }, question: Question) => {
                  const { questionEnum } = question;
                  if (!acc[questionEnum]) {
                    acc[questionEnum] = question;
                  }
                  return acc;
                },
                {}
              );
              // Any new question enum has to be added to the questionEnumToGroupTitle map.
              const questionEnums = Array.from(
                this.questionEnumToGroupTitle.keys()
              );
              this.mainQuestionsForThisForm = questionEnums.map(
                questionEnum => groupedQuestions[questionEnum]
              );
              this.subQuestionsForThisForm = this.questions.filter(
                question => !this.mainQuestionsForThisForm.includes(question)
              );
              this.questionsForThisForm = this.questions.filter(
                q => this.questionGroupIDs.includes(q.groupId) && !q.isOptional
              );
            }
            this.GetFeedbackData();
            this.GetCountOfFeedbacks();
            this.getCorporateCitizenship();
            this.GetPDHistory();
            this.GetFocalReviewDetails();
            this.isDisplay = true;
          }
          this.getReport();
        },
        () => {
          this.isLoading = false;
          this.showConfirmationDialog();
        }
      );
  }

  fiscalYearForFRDetails(isCurrentYear: boolean) {
    const currentYear = this.year;
    const previousYear = this.year - 1;

    return isCurrentYear ? currentYear : previousYear;
  }

  sumOfDeductionValues(deductions: PDDeductionSummary[]): number {
    let sum = 0;
    deductions.forEach(deduction => {
      sum = sum + deduction.deductionValue;
    });
    return sum;
  }

  checkUtilizationAvailability(yearString: string) {
    let utilization: FocalUtilization[] = [];
    if (yearString === 'previous year') {
      utilization = this.focalUtilization.filter(
        u => u.fiscalYear === this.selectedYear - 1
      );
    } else if (yearString === 'current year') {
      utilization = this.focalUtilization.filter(
        u => u.fiscalYear === this.selectedYear
      );
    }
    return utilization.length === 0 ? false : true;
  }

  getUtilization(yearString: string): number {
    let utilization: FocalUtilization[] = [];
    if (yearString === 'previous year') {
      utilization = this.focalUtilization.filter(
        u => u.fiscalYear === this.selectedYear - 1
      );
    } else if (yearString === 'current year') {
      utilization = this.focalUtilization.filter(
        u => u.fiscalYear === this.selectedYear
      );
    }
    return utilization[0].utilization;
  }
  displayQuestion(question: Question): string {
    return question.question;
  }

  getID(questionID: number): string {
    const idString = '' + questionID + '';
    return idString;
  }

  getRadioTextQuestionId(questionId: number): string {
    const idString = '' + questionId + '_text';
    return idString;
  }

  radioOptionValueDisplay(option?: QuestionOption): number | undefined {
    const value = option?.score;
    return value;
  }

  filterSortThisQuestionOption(question: Question): QuestionOption[] {
    return this.questionOptions
      .filter(qo => qo.groupId === question.optionGroupId && qo.id > 1000)
      .sort((a, b) => a.score - b.score);
  }

  validQuestionOptions(question: Question) {
    return this.questionOptions.filter(
      qo => qo.groupId === question.optionGroupId
    );
  }

  optionDisplay = (option?: QuestionOption): string | undefined => {
    const text = option?.value;
    return text;
  };

  radioTextChange(optionId: number) {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    if (option !== null && option !== undefined) {
      const question = this.questions.find(
        q => q.optionGroupId === option.groupId
      );
      if (option.score === -1) {
        this.reportForm.controls[`${question?.id}_text`].setValidators([
          Validators.required,
          Validators.minLength(1),
        ]);
        if (question?.id) {
          this.commentRequired(question.id, optionId);
        }
      } else {
        this.reportForm.controls[`${question?.id}_text`].clearValidators();
      }

      this.reportForm.controls[`${question?.id}_text`].updateValueAndValidity();
    }
  }

  updateReportForm() {
    // default onliner to logged in user
    this.reportForm.controls['onliner'].setValue(
      this.authService.getUserId().toLowerCase()
    );
    this.onlinerAssigned = true;
    const txt = document.createElement('textarea');
    let length = this.questionsForThisForm.length;
    let q: Question | null = null;
    let datePlusTimezoneOffset = new Date();

    // default client to Online
    // this.reportForm.controls['client'].setValue(this.getClientFromDropdown(this.feedback.client.clientId));
    this.clientAssigned = true;

    // fill in answers to the questions, if there are any...
    for (let i = 0; i < length; i++) {
      q = this.questionsForThisForm[i];

      // Fill in onliner's answers
      if (this.reports.answers) {
        this.reports.answers.forEach((a: FbSelfAssessmentAnswer) => {
          if (q && q.id === a.questionId) {
            if (q.type.toString() === 'Text') {
              txt.innerHTML = a.stringValue;
              this.reportForm.controls[`${q.id}`].setValue(txt.value);
            } else if (q.type.toString() === 'Scale') {
              this.reportForm.controls[`${q.id}`].setValue(a.intValue);
            } else if (q.type.toString() === 'Date_Input') {
              datePlusTimezoneOffset = new Date(a.stringValue);
              datePlusTimezoneOffset.setMinutes(
                datePlusTimezoneOffset.getMinutes() +
                  datePlusTimezoneOffset.getTimezoneOffset()
              );
              this.reportForm.controls[`${q.id}`].setValue(
                datePlusTimezoneOffset.toISOString()
              );
            } else {
              if (q.type.toString() === 'Radio') {
                this.reportForm.controls[`${q.id}`].setValue(a.optionId);
              } else if (this.reportForm.controls[`${q.id}`].value !== '') {
                this.reportForm.controls[`${q.id}`].setValue(a.optionId);
              }

              if (q.type.toString() === 'Radio_Text') {
                this.reportForm.controls[`${q.id}`].setValue(a.optionId);
                this.reportForm.controls[`${q.id}_text`].setValue(
                  a.stringValue
                );
                if (a.optionId) {
                  this.radioTextChange(a.optionId);
                }
              }
            }
          }
        });
      }

      // Fill in reviewer's answers
      if (this.reports.selfAssessmentReviewerAnswers) {
        this.reports.selfAssessmentReviewerAnswers.forEach(
          (a: FbSelfAssessmentReviewerAnswer) => {
            if (q && q.id === a.questionId) {
              if (q.type.toString() === 'Text') {
                txt.innerHTML = a.stringValue;
                this.reportForm.controls[`review-${q.id}`].setValue(txt.value);
              } else {
                if (q.type.toString() === 'Radio') {
                  this.reportForm.controls[`review-${q.id}`].setValue(
                    a.optionId
                  );
                }
              }
            }
          }
        );
      }
      length = this.questionsForThisForm.length;
    }

    this.feedbackTypeIndicator = this.selectedFormId;
    this.reportForm.updateValueAndValidity();
    // this.reportForm.markAsDirty();
    this.changeDetectorRef.detectChanges();
  }

  enableFeedbackFields(): void {
    this.questionsForThisForm.forEach(q => {
      this.reportForm.controls[`${q.id}`].enable();
    });
  }

  optionsHaveScore(question: Question) {
    const qOptions = this.validQuestionOptions(question);
    let haveScore = false;

    qOptions.forEach(qo => {
      haveScore = haveScore || qo.score !== null;
    });

    return haveScore;
  }

  navigateToFeedback() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home']));
  }

  populateQuestionOptionGroupIDs(group: QuestionGroup) {
    this.questionOptionGroupIDs = [];
    this.questions.forEach(q => {
      if (q.optionGroupId && q.groupId === group.id) {
        if (this.questionOptionGroupIDs.length > 0) {
          if (!this.questionOptionGroupIDs.some(qo => qo === q.optionGroupId)) {
            this.questionOptionGroupIDs.push(q.optionGroupId);
          }
        } else {
          this.questionOptionGroupIDs.push(q.optionGroupId);
        }
      }
    });
  }

  createFeedbackForm(selection: FormType) {
    const questionGroup = this.questionGroups.filter(
      qg => qg.type.valueOf() === selection.Id
    );
    if (questionGroup) {
      this.questionGroupIDs = [];

      questionGroup.forEach(q => {
        // only push unique IDs
        if (
          this.questionGroupIDs.findIndex(question => question === q.id) < 0
        ) {
          this.questionGroupIDs.push(q.id);
        }
      });
    }

    const group: {
      [key: string]: AbstractControl<unknown, unknown>;
    } = {};
    group['onliner'] = new UntypedFormControl('');

    this.questionGroupIDs.forEach(qg => {
      this.questions
        .filter(qu => qu.groupId === qg)
        .forEach(q => {
          // Create the formControls for Onliner answers
          group[`${q.id}`] = new UntypedFormControl({
            value: '',
            disabled: true,
          });

          // Create the formControls for Reviewer comments
          if (q.reviewerQuestion) {
            if (q.reviewerQuestion.type.toString() === 'Text') {
              group[`review-${q.id}`] = new UntypedFormControl(
                { value: '', disabled: !this.isReviewer },
                q.reviewerQuestion.isOptional
                  ? {}
                  : requiredAndNoWhiteSpaceValidator
              );
            } else {
              group[`review-${q.id}`] = new UntypedFormControl(
                { value: '', disabled: !this.isReviewer },
                q.reviewerQuestion.isOptional ? {} : Validators.required
              );
            }
          }
        });
    });

    this.reportForm = new UntypedFormGroup(group);
  }

  getFullName(submitterId: string) {
    this.submitterId = submitterId;
    this.feedbackService
      .getSubmitterDetails(submitterId)
      .subscribe((data: OnlinerPersonalInfo) => {
        this.user = data;
        this.user.startDate = new Date(data.startDate);
        const month = this.user.startDate.toLocaleString('en-US', {
          month: 'long',
        });
        this.startDate = `${month} ${this.user.startDate.getDay()}, ${this.user.startDate.getFullYear()}`;
        this.nameReady = true;
      });
  }
  getSelfAssessmentReportDetailsByYear(year: number) {
    const filteredSelfAssessment = this.allSelfAssessmentDetailsList.find(
      a => a.submissionYear === year
    );

    if (filteredSelfAssessment) {
      this.selfAssessmentId = filteredSelfAssessment.assessmentId;
      this.scheduleId = filteredSelfAssessment.scheduleId;
      const routeToNavigate = `viewYearlyAssessmentReport/${this.scheduleId}/${this.selfAssessmentId}`;

      this.location.replaceState(routeToNavigate);
    }

    this.PDDeductionSummaryHoursCurrent = [];
    this.PDDeductionSummaryHoursPrevious = [];
    this.PDDeductionSummaryUnitsCurrent = [];
    this.PDDeductionSummaryUnitsPrevious = [];
    this.uniqueFeedbacks = [];
    this.feedbackAnswers = [];
    this.feedbackQuestions = [];
    this.feedbackType = [];
    this.submitterName = '';
    this.setupPage();
  }

  goBack() {
    this.location.back();
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }

  goalStatus(status: number) {
    switch (status) {
      case 0:
        return 'Active';

      case 1:
        return 'On Hold';

      case 2:
        return 'Achieved';

      case 3:
        return 'Cancelled';

      default:
        return undefined;
    }
  }

  dateFormating(date: Date) {
    return this.datepipe.transform(date, 'MM/dd/yyyy');
  }

  getQuestionGroupTitle(questionEnum: string) {
    return this.questionEnumToGroupTitle.get(questionEnum);
  }

  saveFeedback() {
    this.isSaving = true;
    this.updateReportFromForm();
    let message = '';

    this.selfAssessmentService.saveReview(this.reports).subscribe(
      () => {
        message = 'Your Review has been saved.';

        this.snackBarService.message(message);
        this.isSaving = false;
        this.isFeedbackRequest = true;
        this.isDraft = true;
        this.reportForm.markAsPristine();
        this.setupPage();
        this.isSaved = true;
      },
      error => {
        this.isSaving = false;
        this.snackBarService.error(error);
      }
    );
  }

  displayUnSubmissionConfirmation() {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.message =
      'Are you sure you want to un-submit the Self Assessment for ' +
      this.user.firstName +
      ' ' +
      this.user.lastName +
      '?';
    confirmDialog.title = 'Un-Submission Confirmation';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';

    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        data: confirmDialog,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((choice: string) => {
        if (choice === 'ok') {
          this.unsubmitFeedback();
        }
      });
  }

  unsubmitFeedback() {
    this.isSubmitting = true;
    let message = '';

    this.selfAssessmentService.unsubmitReport(this.reports).subscribe(
      () => {
        message = 'Your Self Assessment form has been un-submitted.';

        this.snackBarService.message(message);
        this.isSubmitting = false;
        this.isSaving = false;
        this.reportForm.markAsPristine();

        // if the user has already submitted a form, disable the form and display a message indicating that they've alredy submitted a form
        this.goBack();
      },
      error => {
        this.isSubmitting = false;
        this.snackBarService.error(error);
      }
    );
  }

  displayPreSubmissionConfirmation() {
    const confirmDialog = new ConfirmDialog();

    confirmDialog.message = 'Are you sure you want to submit your review?';
    confirmDialog.title = 'Submission confirmation';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';

    this.dialog
      .open(ConfirmationDialogComponent, {
        width: '400px',
        data: confirmDialog,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((choice: string) => {
        if (choice === 'ok') {
          this.submitFeedback();
          // this.reportForm.markAsPristine();
        }
      });
  }

  submitFeedback() {
    this.isSubmitting = true;
    this.reportForm.disable();
    this.updateReportFromForm();
    let message = '';

    this.selfAssessmentService.submitReview(this.reports).subscribe(
      () => {
        message = 'Your Review has been submitted.';

        this.snackBarService.message(message);
        this.isSubmitting = false;
        this.isReviewedForThisYear = true;
        this.isSaving = false;
        this.reportForm.markAsPristine();

        // if the user has already submitted a form, disable the form and display a message indicating that they've alredy submitted a form
        this.navigateToFeedback();
      },
      error => {
        this.isSubmitting = false;
        this.enableFeedbackFields();
        this.snackBarService.error(error);
      }
    );
  }

  asFormControl = (x: AbstractControl) => x as UntypedFormControl;

  private setupPage() {
    this.feedbackTypeIndicator = this.selectedFormId;
    // this.isFeedbackAdmin;
    this.isLoading = true;
    this.submissionYearsList = [];
    new Promise(resolve => {
      this.getQuestions();
      resolve('');
    }).then(() => {
      this.populateForm();
    });
  }

  private checkEndDate() {
    this.selfAssessmentService.getSchedule(this.year).subscribe(s => {
      const dueDate = new Date(s.endDate);
      this.isAssessmentFromPreviousYear =
        dueDate.getTime() < new Date().getTime();
    });
  }

  private GetFocalReviewDetails() {
    this.selfAssessmentService
      .GetFocalReviewDetails(this.selectedYear, true, this.submitterId)
      .subscribe(data => {
        this.focalReviewDetails = data;
        if (data) {
          this.focalReviewBaseCamp = data.focalReviewBaseCamp;
          this.previousHours = this.focalReviewBaseCamp.previousHours;
          this.currentHours = this.focalReviewBaseCamp.currentHours;
          this.focalReviewRevenue = data.focalReviewRevenue;
          this.previousBillableHours =
            this.focalReviewRevenue.previousBillableHours;
          this.currentBillableHours =
            this.focalReviewRevenue.currentBillableHours;
          this.focalUtilization = data.focalUtilizations.slice(0, 2);
          this.focalSickTime = data.focalSickTime;
          this.previousSickHours = this.focalSickTime.previousSickHours;
          this.currentSickHours = this.focalSickTime.currentSickHours;
          this.focalWorkHistory = data.focalWorkHistory;
          if (this.focalUtilization.length > 0) {
            this.deliveryDisclaimerMonthYear =
              this.focalUtilization[0].monthYear === ''
                ? 'NA'
                : this.focalUtilization[0].monthYear;
          }
        }
      });
  }

  private GetPDHistory() {
    this.selfAssessmentService
      .GetPDHistory(this.selectedYear, true, false, this.submitterId)
      .subscribe(data => {
        this.PDBudgetSummaryCurrent = data;
        if (
          this.PDBudgetSummaryCurrent !== null &&
          this.PDBudgetSummaryCurrent.pdDeductionSummary !== null
        ) {
          this.PDDeductionSummaryHoursCurrent =
            this.PDBudgetSummaryCurrent.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'H'
            );
          this.budgetYearCurrent = this.PDBudgetSummaryCurrent.budgetYear;

          this.PDDeductionSummaryUnitsCurrent =
            this.PDBudgetSummaryCurrent.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'U'
            );
        }
      });

    this.selfAssessmentService
      .GetPDHistory(this.selectedYear - 1, true, true, this.submitterId)
      .subscribe(data => {
        this.PDBudgetSummaryPrevious = data;
        if (
          this.PDBudgetSummaryPrevious !== null &&
          this.PDBudgetSummaryPrevious.pdDeductionSummary !== null
        ) {
          this.PDDeductionSummaryHoursPrevious =
            this.PDBudgetSummaryPrevious.pdDeductionSummary.filter(
              (deduction: PDDeductionSummary) =>
                deduction.deductionTypeId === 'H'
            );
          this.budgetYearPrevious = this.PDBudgetSummaryPrevious.budgetYear;

          this.PDDeductionSummaryUnitsPrevious =
            this.PDBudgetSummaryPrevious.pdDeductionSummary.filter(
              deduction => deduction.deductionTypeId === 'U'
            );
        }
      });
  }

  private GetOnlinerGoals() {
    this.selfAssessmentService
      .GetOnlinerGoals(this.selectedYear, true, this.submitterId)
      .subscribe(data => {
        this.onlinerGoals = data;
        this.hasOnlinerGoals = true;
      });
  }

  private GetFeedbackData() {
    this.selfAssessmentService
      .GetFeedbackData(this.selectedYear, true, this.submitterId)
      .subscribe(data => {
        if (data && data.length !== 0) {
          this.feedbacks = data;
          let index = 0;
          let currentAssessmentId: number;
          if (this.feedbacks.length !== 0) {
            currentAssessmentId = this.feedbacks[0].id; // the first assessments id
          }
          this.feedbacks.forEach(feedback => {
            if (feedback.id !== currentAssessmentId) {
              // move to the next assessment (it's not reading the last assessment)
              const assessment: FbFeedbacksSelfAssessment =
                new FbFeedbacksSelfAssessment();
              assessment.answers = this.feedbackAnswers;
              assessment.questions = this.feedbackQuestions;
              assessment.submitterName = this.submitterName;
              assessment.questionType = this.feedbackType;
              assessment.typeDescr = this.feedbackTypeDescr;
              this.uniqueFeedbacks[index] = assessment;

              index++; // next index/corresponding assessment
              currentAssessmentId = feedback.id;

              // reset the values and go again
              this.feedbackAnswers = [];
              this.feedbackQuestions = [];
              this.feedbackType = [];
              this.submitterName = '';
            }
            this.feedbackType.push(feedback.type);
            this.submitterName = feedback.submitterId;
            this.feedbackTypeDescr = feedback.typeDescr;
            this.feedbackQuestions.push(feedback.question);
            this.feedbackId.push(feedback.id);
            this.feedbackAnswers.push(this.htmlDecode(feedback.answer));
          });

          // the last assessment
          const lastAssessment: FbFeedbacksSelfAssessment =
            new FbFeedbacksSelfAssessment();
          lastAssessment.answers = this.feedbackAnswers;
          lastAssessment.questions = this.feedbackQuestions;
          lastAssessment.questionType = this.feedbackType;
          lastAssessment.typeDescr = this.feedbackTypeDescr;
          lastAssessment.submitterName = this.submitterName;
          this.uniqueFeedbacks[index] = lastAssessment;
        }
      });
  }

  private GetCountOfFeedbacks() {
    this.selfAssessmentService
      .GetCountOfFeedbacks(this.selectedYear, true, this.submitterId)
      .subscribe(data => {
        this.fbRequestCountProvidedForOnliners = data[0];
        this.fbRequestCountRequestedByOnliners = data[1];
        this.fbRequestCountRequestedFromClients = data[2];
        this.fbRequestCountRequestedFromOnliners = data[3];
      });
  }

  private getCorporateCitizenship() {
    this.selfAssessmentService
      .getCorporateCitizenship(this.selectedYear, true, this.submitterId)
      .subscribe(data => {
        this.resumeUpdateDate = data[2];
        this.countLateTimeSheetSubmission = data[0];
        this.countWeeklyHealthSubmission = data[1];
      });
  }

  private commentRequired(questionId: number, optionId: number): boolean {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    let required = false;

    if (option !== null && option !== undefined) {
      if (option.score === -1) {
        this.reportForm.controls[`${questionId}_text`].markAsTouched();
        required = true;
      }
    }

    return required;
  }

  private getReport() {
    this.updateReportForm();
    this.isLoading = false;
  }

  private showConfirmationDialog() {
    const confirmDialog = new ConfirmDialog();
    confirmDialog.title = 'Access Error';
    confirmDialog.message =
      "<p> You don't have access to this feedback using this section of the app.</p>";
    confirmDialog.okButtonTitle = 'Continue';
    // confirmDialog.cancelButtonTitle = "Cancel";
    this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: confirmDialog,
      disableClose: true,
    });
  }

  private getYearlyAssessments() {
    this.selfAssessmentService.getAllYearlySelfAssessment().subscribe(data => {
      this.yearlySAReports = data;
      this.yearlySAReports.forEach(report => {
        this.getListOfAssessmentDetails(report);
      });
    });
  }

  private getListOfAssessmentDetails(report: FbSelfAssessment) {
    // prepare submission year list for dropdownlist
    this.submissionYearsList.push(report.fiscalYear);
    this.submissionYearsList.sort((a, b) => b - a);
    // prepare assessmentId and submission year list of all available assessment report
    this.allSelfAssessmentDetailsList.push({
      assessmentId: report.id,
      submissionYear: report.fiscalYear,
      scheduleId: report.scheduleId,
    });
  }

  private updateReportFromForm() {
    let selfAssessmentReviewerAnswers: FbSelfAssessmentReviewerAnswer[] = [];

    if (this.reports.selfAssessmentReviewerAnswers) {
      selfAssessmentReviewerAnswers = [
        ...this.reports.selfAssessmentReviewerAnswers,
      ];
      this.reports.selfAssessmentReviewerAnswers = [];
    }

    const reviewerQuestions = this.questionsForThisForm.filter(
      q => q.reviewerQuestion
    );

    reviewerQuestions.forEach(q => {
      const options = this.questionOptions.filter(
        qo => qo.groupId === q.optionGroupId
      );

      let stringValue: string | null = '';
      let optionID: number | null = 0;

      if (q.type.toString() === 'Text') {
        stringValue = this.reportForm.value[`review-${q.id}`] as string;
        optionID = null;
      } else {
        const selectedValue = this.reportForm.value[`review-${q.id}`];
        const selectedOption = options.find(sv => sv.id === selectedValue);

        stringValue = selectedOption?.value ?? '';
        optionID = selectedOption?.id ?? null;
      }

      const index = selfAssessmentReviewerAnswers.findIndex(
        (a: FbSelfAssessmentReviewerAnswer) => a.questionId === q.id
      );

      if (index > -1) {
        this.reports.selfAssessmentReviewerAnswers.push({
          id: selfAssessmentReviewerAnswers[index].id,
          questionId: selfAssessmentReviewerAnswers[index].questionId,
          question: selfAssessmentReviewerAnswers[index].question,
          stringValue: stringValue || '',
          optionId: optionID,
          option: selfAssessmentReviewerAnswers[index].option,
        });
      } else {
        this.reports.selfAssessmentReviewerAnswers.push({
          id: -1,
          questionId: q.id,
          question: q,
          stringValue: stringValue || '',
          optionId: q.type.toString() === 'Text' ? null : optionID,
          option: null,
        });
      }
    });
  }
}

export class FormType {
  Id!: number;
  name!: string;
}

export class SelfAssessmentDetails {
  assessmentId!: number;
  submissionYear!: number;
  scheduleId!: number;
}
