import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  Client,
  CommonService,
  SubmissionDate,
} from 'src/app/core/services/common.service';
import {
  Question,
  QuestionOption,
} from 'src/app/core/services/feedback.service';
import {
  Employee,
  OnlinerService,
} from 'src/app/core/services/onliner.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  FbWeeklyReport,
  FbWeeklyReportAnswer,
  WeeklyReportService,
} from 'src/app/core/services/weekly-report-service';
import {
  ConfirmDialog,
  ConfirmationDialogComponent,
} from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { FeedbackTypeEnum } from 'src/app/shared/enums/enums.model';
import * as formValidators from 'src/app/shared/validators/form-validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-weekly-health-delivery-form',
  templateUrl: './weekly-health-delivery-form.component.html',
  styleUrls: ['./weekly-health-delivery-form.component.scss'],
})
export class WeeklyHealthDeliveryFormComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('autosize')
  autosize!: CdkTextareaAutosize;

  @Input()
  reportId!: number | null;
  @Input()
  reportForm!: UntypedFormGroup;
  @Input()
  selectedFormId: number = FeedbackTypeEnum.WeeklyHealthDelivery;
  @Input()
  selectedTypeLoaded!: boolean;
  @Input() set questionGroupIDs(qGroupIds: number[]) {
    if (qGroupIds !== null) {
      if (qGroupIds.length === 1) {
        if (
          !this._questionGroupIds ||
          this._questionGroupIds[0] === qGroupIds[0]
        ) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = true;
        }
      } else if (qGroupIds.length > 1) {
        if (!this._questionGroupIds) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = !this._questionGroupIds.every(qg =>
            qGroupIds.every(question => question === qg)
          );
        }
      }

      this._questionGroupIds = [...qGroupIds];
    }
  }
  @Input()
  questionOptions!: QuestionOption[];
  @Input()
  questionOptionGroupIDs!: number[];
  @Input()
  questions!: Question[];
  @Input()
  targetWeek!: Date;

  @Output() draftCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() updateFormSelection: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  onliners!: Employee[];
  clients!: Client[];
  onlinerCM = '';
  onlinerCMUserId = '';
  tooltipItems!: string[];
  toolTip!: string;
  onlinerAssigned = false;
  clientAssigned = false;
  dismissed = false;
  questionsForThisForm: Question[] = [];
  initialQuestions: Question[] = [];
  isDraft = false;
  reloadRequired = false;
  report!: FbWeeklyReport | null;
  reports!: FbWeeklyReport[];
  submissionDates!: SubmissionDate[];

  defaultColor = 'rgb(156, 30, 54)';
  disabledColor = 'rgba(0,0,0,.38)';

  unsolicitedFormId: number = FeedbackTypeEnum.AdhocFeedback;
  clientreportFormId: number = FeedbackTypeEnum.ClientFeedback;
  ciaFormId: number = FeedbackTypeEnum.CultureInAction;
  autoSaveSubscription!: Subscription | null;
  // get isVacationSelected() { return this.vacationSelected; }

  filteredOnliners!: Observable<Employee[]>;
  filteredClients!: Observable<Client[]>;

  isLoading!: boolean;
  isSaving = false;
  isSubmitting = false;
  isFeedbackAdmin!: boolean;
  feedbackTypeIndicator!: number;
  isNew!: boolean;
  isFeedbackRequest!: boolean;
  submittedForThisWeek = false;
  minDate: Date = new Date();
  vacationSelected = false;

  errorMatcher = new formValidators.CompControlErrorStateMatcher();

  theCheckbox = false;

  private _questionGroupIds!: number[];
  private readonly CurrentAssignmentQuestionId = 37;
  private readonly BCQuestionGroup = 8;
  private readonly ClientQuestionGroup = 9;
  private readonly defaultOptionId = -1;

  constructor(
    private weeklyReportService: WeeklyReportService,
    private commonService: CommonService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    if (this._questionGroupIds) {
      this._questionGroupIds.forEach(qgId => {
        if (
          qgId !== this.BCQuestionGroup &&
          qgId !== this.ClientQuestionGroup
        ) {
          const questionsToFill = this.questions.filter(
            q => q.groupId === qgId
          );
          this.initialQuestions = this.initialQuestions.concat(questionsToFill);
        }
      });
      this.assignmentChange(this.defaultOptionId);
    }

    this.setupPage();
  }

  ngOnDestroy() {
    this.autoSaveSubscription?.unsubscribe();
    this.autoSaveSubscription = null;
  }

  ngOnChanges() {
    if (this.reloadRequired) {
      if (this._questionGroupIds !== null) {
        this.questionsForThisForm = [];
        this._questionGroupIds.forEach(qgId => {
          const questionsToFill = this.questions.filter(
            q => q.groupId === qgId
          );
          this.questionsForThisForm = [
            ...this.questionsForThisForm,
            ...questionsToFill,
          ];
        });
      }

      this.setupPage();
    }
  }

  isDraftCheck() {
    this.draftCheck.emit(this.isDraft);
  }

  get onlinerFilterControl() {
    return this.reportForm.controls['onliner'];
  }

  get onlinerAndClientValidation() {
    return this.reportForm.controls['onliner'].valid;
  }

  get clientControl() {
    return this.reportForm.controls['client'];
  }

  get isSaved() {
    return this.report && this.report.id > 0;
  }

  showError = (controlName: string) => {
    const control = this.reportForm.controls[controlName];

    if (control && control.hasError('required') && control.touched) {
      return true;
    }
    return false;
  };
  assignmentChangeCheck(
    optionId: number | null | undefined,
    questionId: number
  ) {
    for (const q of this.questionsForThisForm) {
      if (
        this.reportForm.controls[q.id].dirty ||
        (this.reportForm.controls[q.id].value as string) !== ''
      ) {
        break;
      }
    }
    if (optionId && questionId === this.CurrentAssignmentQuestionId) {
      this.assignmentChange(optionId);
    }
  }

  assignmentChange(optionId: number) {
    const questionOption = this.questionOptions.find(q => q.id === optionId);
    let questionsTemp = [...this.initialQuestions];

    if (questionOption) {
      const questions = this.questions.filter(
        q =>
          q.groupId === questionOption.score &&
          this._questionGroupIds.includes(q.groupId)
      );
      if (questionOption.score > -1) {
        questionsTemp = questionsTemp.concat(questions);

        this.reportForm.enable();

        this.questions.forEach(ques => {
          if (this.reportForm.contains(`${ques.id}`)) {
            if (this.initialQuestions.findIndex(q => q.id === ques.id) < 0) {
              this.reportForm.controls[`${ques.id}`].setValue('');

              if (questionsTemp.findIndex(q => q.id === ques.id) < 0) {
                this.reportForm.controls[`${ques.id}`].disable();
                if (ques.type.toString() === 'Radio_Text') {
                  this.reportForm.controls[`${ques.id}_text`].setValue('');
                  this.reportForm.controls[`${ques.id}_text`].disable();
                }
              }
            }
          }
        });

        // remove duplicates
        questionsTemp = [...new Set(questionsTemp)];

        // sort questions
        questionsTemp.sort((a, b) => a.sortOrder - b.sortOrder);
        this.questionsForThisForm = [...questionsTemp];
      } else {
        this.reportForm.enable();
        questionsTemp = [...this.initialQuestions].concat(
          this.questions.filter(
            q =>
              q.groupId === this.BCQuestionGroup ||
              q.groupId === this.ClientQuestionGroup
          )
        );
        questionsTemp.sort((a, b) => a.sortOrder - b.sortOrder);
        this.questionsForThisForm = [...questionsTemp];
      }
    } else {
      this.questionsForThisForm = [...this.initialQuestions];
    }
  }

  closeAlert() {
    this.dismissed = true;
  }

  formatDate(date: Date) {
    if (!date) {
      return '';
    }

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    const partialDate = [year, month, day].join('-');
    const time = [hours, minutes, seconds].join(':');

    const fullDate = '' + partialDate + ' ' + time;

    return fullDate;
  }

  displayQuestion(question: Question): string {
    return question.question;
  }

  getID(questionID: number): string {
    const idString = '' + questionID + '';
    return idString;
  }

  getRadioTextQuestionId(questionId: number): string {
    const idString = '' + questionId + '_text';
    return idString;
  }

  getSliderTickInterval(question: Question) {
    const options = this.filterSortThisQuestionOption(question);
    const interval = options[1].score - options[0].score;
    return interval;
  }

  getIntervalMin(question: Question) {
    const minOption = this.filterSortThisQuestionOption(question);
    return minOption[0].score;
  }

  getIntervalMax(question: Question) {
    const maxOption = this.filterSortThisQuestionOption(question);
    return maxOption[maxOption.length - 1].score;
  }

  getIntervalTextValue(question: Question, minOrMax: number): string {
    let textValue: string;

    if (minOrMax === 0) {
      const minOption = this.filterSortThisQuestionOption(question);
      textValue = minOption[0].value;
    } else {
      const maxOption = this.filterSortThisQuestionOption(question);
      textValue = maxOption[maxOption.length - 1].value;
    }

    return textValue;
  }

  filterSortThisQuestionOption(question: Question): QuestionOption[] {
    return this.questionOptions
      .filter(qo => qo.groupId === question.optionGroupId && qo.id > 1000)
      .sort((a, b) => a.score - b.score);
  }

  validQuestionOptions(question: Question) {
    return this.questionOptions.filter(
      qo => qo.groupId === question.optionGroupId
    );
  }

  optionDisplay = (option?: QuestionOption): string | undefined => {
    const text = option?.value;
    return text;
  };

  onSearchChange(target: QuestionOption): void {
    localStorage.setItem(target.id.toString(), target.value);
  }

  radioTextChange(optionId: number) {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    if (option !== null && option !== undefined) {
      const question = this.questions.find(
        q => q.optionGroupId === option.groupId
      );
      if (option.score === -1) {
        this.reportForm.controls[`${question?.id}_text`].setValidators([
          Validators.required,
          Validators.minLength(1),
          formValidators.requiredAndNoWhiteSpaceValidator,
        ]);
        if (question?.id) {
          this.commentRequired(question.id, optionId);
        }
      } else {
        this.reportForm.controls[`${question?.id}_text`].clearValidators();
      }

      this.reportForm.controls[`${question?.id}_text`].updateValueAndValidity();
    }
  }

  filterDates = (dateVal: Date): boolean => {
    let invalidDate = true;
    let startDate = null;
    let endDate = null;

    if (this.reports) {
      if (this.reports.length > 0) {
        this.reports.some(r => {
          if (r) {
            if (r.log.findIndex(l => l.statusId === 0) > -1) {
              const submittedDate = new Date(r.submittedDate);
              let targetWeekDate = this.targetWeek;

              if (submittedDate.getDate() === targetWeekDate.getDate()) {
                targetWeekDate = new Date(this.formatDate(targetWeekDate));

                const subDate = this.submissionDates.find(
                  s =>
                    new Date(s.targetDate).toLocaleDateString() ===
                    targetWeekDate.toLocaleDateString()
                );
                if (subDate) {
                  const cutoffDate = new Date(subDate.cutoffDate);
                  startDate = cutoffDate.getDate() - 7;
                  endDate = cutoffDate;
                  if (dateVal.getDate() >= startDate && dateVal <= endDate) {
                    invalidDate = false;
                    return true;
                  }
                }
              }
            }
          }
          return false;
        });
      }
    }

    return invalidDate;
  };

  resetReportForm() {
    this.reportForm.reset();
    this.report = null;
    this.reportId = null;
    if (this.selectedFormId !== FeedbackTypeEnum.CultureInAction) {
      // not cia-feedback
      this.isFeedbackRequest = false;
      this.isDraft = true;
    }
  }

  updateReportForm() {
    // default onliner to logged in user
    this.reportForm.controls['onliner'].setValue(
      this.authService.getUserId().toLowerCase()
    );
    this.onlinerAssigned = true;
    const txt = document.createElement('textarea');
    let q: Question | null = null;
    let datePlusTimezoneOffset = new Date();

    if (this.isDraft) {
      // this.noEventOnlinerSelected(this.feedback.employee);
      // this.onlinerCMUserId = this.feedback.employee.careerManager.toLowerCase();
      this.isLoading = false;
    }

    // default client to Online
    // this.reportForm.controls['client'].setValue(this.getClientFromDropdown(this.feedback.client.clientId));
    this.clientAssigned = true;

    if (!this.submittedForThisWeek) {
      // fill in answers to the questions, if there are any...
      if (this.report && this.report.answers) {
        this.report.answers.some(answer => {
          if (answer.questionId === this.CurrentAssignmentQuestionId) {
            this.assignmentChangeCheck(answer.optionId, answer.questionId);
          }
        });
        this.report.answers.forEach(a => {
          q =
            this.questions.find(question => question.id === a.questionId) ||
            null;
          if (q !== null) {
            if (q.type.toString() === 'Text') {
              txt.innerHTML = a.stringValue;
              this.reportForm.controls[`${q.id}`].setValue(txt.value);
            } else if (q.type.toString() === 'Scale') {
              this.reportForm.controls[`${q.id}`].setValue(a.intValue);
            } else if (q.type.toString() === 'Date_Input') {
              datePlusTimezoneOffset = new Date(a.stringValue);
              datePlusTimezoneOffset.setMinutes(
                datePlusTimezoneOffset.getMinutes() +
                  datePlusTimezoneOffset.getTimezoneOffset()
              );
              this.reportForm.controls[`${q.id}`].setValue(
                datePlusTimezoneOffset.toISOString()
              );
            } else {
              if (q.type.toString() === 'Radio') {
                this.reportForm.controls[`${q.id}`].setValue(a.optionId);
              } else if (this.reportForm.controls[`${q.id}`].value !== '') {
                this.reportForm.controls[`${q.id}`].setValue(a.optionId);
              }

              if (q.type.toString() === 'Radio_Text') {
                const decodedString = new DOMParser().parseFromString(
                  a.stringValue,
                  'text/html'
                ).documentElement.textContent;
                this.reportForm.controls[`${q.id}`].setValue(a.optionId);
                this.reportForm.controls[`${q.id}_text`].setValue(
                  decodedString
                );
                if (a.intValue === -1)
                  this.reportForm.controls[`${q.id}_text`].setValidators([
                    Validators.required,
                    Validators.minLength(1),
                    formValidators.requiredAndNoWhiteSpaceValidator,
                  ]);
              }
            }
          }
        });
      }
      // length = this.questionsForThisForm.length; // TODO: why do we need to change the window length?
    } else {
      // default the answers to the away form and disable the question...?
      this.assignmentChange(this.defaultOptionId);
    }

    this.feedbackTypeIndicator = this.selectedFormId;
    this.reportForm.updateValueAndValidity();
    // this.reportForm.markAsDirty();
    this.changeDetectorRef.detectChanges();
  }

  saveFeedback() {
    this.isSaving = true;
    this.updateReportFromForm();
    let message = '';

    if (this.report) {
      this.weeklyReportService.saveReport(this.report).subscribe(
        savedReport => {
          this.report = savedReport;
          const transformDate = this.targetWeek.toLocaleString('default', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          });

          message = `Your Weekly PulseCheck form for ${transformDate} has been saved.`;

          this.snackBarService.message(message);
          this.isSaving = false;
          this.isFeedbackRequest = true;
          this.isDraft = true;
          this.reportForm.markAsPristine();
        },
        error => {
          this.isSaving = false;
          this.snackBarService.error(error);
        }
      );
    }
  }

  enableFeedbackFields(): void {
    this.questionsForThisForm.forEach(q => {
      this.reportForm.controls[`${q.id}`].enable();
    });
  }

  displayPreSubmissionConfirmation() {
    const confirmDialog = new ConfirmDialog();
    let message = '';

    message = 'Are you sure you want to submit a form for this week?';

    confirmDialog.message = message;
    confirmDialog.title = 'Submission confirmation';
    confirmDialog.okButtonTitle = 'Yes';
    confirmDialog.cancelButtonTitle = 'No';

    const dialogRef: MatDialogRef<ConfirmationDialogComponent, string> =
      this.dialog.open<ConfirmationDialogComponent, ConfirmDialog>(
        ConfirmationDialogComponent,
        {
          width: '400px',
          data: confirmDialog,
          disableClose: true,
        }
      );

    dialogRef.afterClosed().subscribe(choice => {
      if (choice === 'ok') {
        this.submitFeedback();
        // this.reportForm.markAsPristine();
      }
    });
  }

  submitFeedback() {
    this.isSubmitting = true;
    this.reportForm.disable();
    this.updateReportFromForm();
    let message = '';

    if (this.report) {
      this.weeklyReportService.submitReport(this.report).subscribe(
        () => {
          const transformDate = this.targetWeek.toLocaleString('default', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          });

          message = `Your Weekly PulseCheck form for ${transformDate} has been submitted.`;

          this.snackBarService.message(message);
          this.isSubmitting = false;
          this.submittedForThisWeek = true;
          this.isSaving = false;
          // this.enableFeedbackFields();
          // this.postSubmissionProcess();
          // this.resetReportForm();
          // this.onlinerAssigned = false;
          // this.clientAssigned = false;
          // this.reportForm.disable();
          this.reportForm.markAsPristine();

          // if the user has already submitted a form, disable the form and display a message indicating that they've alredy submitted a form
          this.navigateToFeedback();
        },
        error => {
          this.isSubmitting = false;
          this.enableFeedbackFields();
          this.snackBarService.error(error);
        }
      );
    }
  }

  navigateToFeedback() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/home']));
  }

  isSaveButtonDisabled(): boolean {
    const isDisabled =
      this.isSaving ||
      this.isSubmitting ||
      this.reportForm.get('onliner')?.errors !== null;
    // if (this.selectedFormId !== this.ciaFormId) {
    // isDisabled = isDisabled;
    // }
    return isDisabled;
  }

  get hasQuestions() {
    return this.questionsForThisForm.length > 0;
  }

  private displayConfirmationMessage(optionId: number) {
    const confirmDialog = new ConfirmDialog();
    const message =
      'You have entered answers to some questions on the form. They will be cleared if you change your selection. Do you wish to continue?';
    confirmDialog.message = message;
    confirmDialog.title = 'Form Type Switch confirmation';
    confirmDialog.okButtonTitle = 'OK';
    confirmDialog.cancelButtonTitle = 'Cancel';

    const dialogRef: MatDialogRef<ConfirmationDialogComponent, string> =
      this.dialog.open<ConfirmationDialogComponent, ConfirmDialog>(
        ConfirmationDialogComponent,
        {
          width: '400px',
          data: confirmDialog,
          disableClose: true,
        }
      );

    dialogRef.afterClosed().subscribe(choice => {
      if (choice === confirmDialog.okButtonTitle.toLowerCase()) {
        this.assignmentChange(optionId);
      }
      this.reportForm.markAsPristine();
    });
  }

  private setupPage() {
    this.feedbackTypeIndicator = this.selectedFormId;
    this.isLoading = true;
    const date = this.formatDate(this.targetWeek);

    forkJoin([
      this.onlinerService.getOnlinersExcludingDefault(),
      this.commonService.getClientsExcludingDefault(),
      this.weeklyReportService.getWeeklyReportChecks(date),
      this.commonService.getSubmissionDates(),
    ]).subscribe(
      data => {
        this.onliners = data[0];
        this.clients = data[1];
        this.reports = data[2];
        this.submissionDates = data[3];

        if (this.reports) {
          const length = this.reports.length;

          if (length > 0) {
            this.report = this.reports[length - 1];
          } else {
            this.report = null;
          }
        } else {
          this.report = null;
        }

        this.setupAutoSave();

        if (this.report) {
          this.getReport();
        } else {
          this.isLoading = false;
          this.isDraftCheck();
        }
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
    this.isDraftCheck();
    this.reportForm?.markAsPristine();
  }

  private commentRequired(questionId: number, optionId: number): boolean {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    let required = false;

    if (option !== null && option !== undefined) {
      if (option.score === -1) {
        this.reportForm.controls[`${questionId}_text`].markAsTouched();
        required = true;
      }
    }

    return required;
  }

  private getReport() {
    if (this.report && this.report.log.findIndex(l => l.statusId === 3) > -1) {
      this.isDraft = true;
    } else {
      this.isDraft = false;
    }

    if (this.report && this.report.log.findIndex(l => l.statusId === 0) > -1) {
      this.submittedForThisWeek = true;
      this.isDraft = false;
      // this.showAlreadySubmittedDialog();
      this.report.id = -1;
    } else {
      this.submittedForThisWeek = false;
    }

    if (!this.submittedForThisWeek) {
      this.isDraftCheck();
      this.updateReportForm();
    }

    this.isLoading = false;
  }

  private showAlreadySubmittedDialog() {
    const confirmDialog = new ConfirmDialog();
    const transformDate = this.targetWeek.toLocaleString('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    confirmDialog.title = `Form Submitted For week of ${transformDate}`;
    confirmDialog.message = `<p> You have already submitted a form for this week. Thank you for your attentiveness.
                                 Feel free to submit absent forms if you will be away in the future.</p>`;
    confirmDialog.okButtonTitle = 'Continue';
    this.dialog.open<ConfirmationDialogComponent, ConfirmDialog>(
      ConfirmationDialogComponent,
      {
        width: '600px',
        data: confirmDialog,
        disableClose: true,
      }
    );
  }

  private setupAutoSave() {
    this.autoSaveSubscription = this.reportForm.valueChanges
      .pipe(debounceTime(environment.autoSaveInterval))
      .subscribe(() => {
        if (
          this.onlinerAndClientValidation &&
          this.reportForm.dirty &&
          !this.vacationSelected
        ) {
          this.saveFeedback();
        }
      });
  }

  private updateReportFromForm() {
    let answers: FbWeeklyReportAnswer[] = [];

    if (!this.report) {
      this.report = new FbWeeklyReport();
      this.report.id = -1;
    } else {
      if (this.report.answers) {
        answers = [...this.report.answers];
        this.report.answers = [];
      }
    }

    this.questionsForThisForm.forEach(q => {
      if (this.report && !this.report.answers) {
        this.report.answers = [];
      }

      const options = this.questionOptions.filter(
        qo => qo.groupId === q.optionGroupId
      );

      let stringValue: string | null = '';
      let intValue: number | null = 0;
      let optionID: number | null = 0;

      if (q.type.toString() === 'Scale') {
        intValue = this.reportForm.controls[q.id].value;
        optionID =
          this.questionOptions.find(
            qo =>
              qo.score === intValue &&
              qo.groupId ===
                this.questionOptionGroupIDs.find(
                  qoid => qoid === q.optionGroupId
                )
          )?.id || null; // this is slightly better
      } else {
        if (
          q.type.toString() === 'Text' ||
          q.type.toString() === 'Date_Input'
        ) {
          stringValue = this.reportForm.value[q.id];
          intValue = null;
          optionID = null;
        } else {
          if (q.type.toString() === 'Radio_Text') {
            stringValue = this.reportForm.value[`${q.id}_text`];
          } else {
            stringValue = !this.reportForm.value[q.id]
              ? null
              : options.find(sv => sv.id === this.reportForm.value[q.id])
                  ?.value || null;
          }
          intValue = !this.reportForm.value[q.id]
            ? null
            : options?.find(sv => sv.id === this.reportForm.value[q.id])
                ?.score || null;
          optionID = !this.reportForm.value[q.id]
            ? null
            : options.find(sv => sv.id === this.reportForm.value[q.id])?.id ||
              null;
        }
      }

      const index = answers.findIndex(a => a.questionId === q.id);

      if (index > -1) {
        this.report?.answers.push({
          id: answers[index].id,
          questionId: answers[index].questionId,
          question: answers[index].question,
          stringValue: stringValue?.trim() || '',
          intValue:
            q.type.toString() === 'Text' ? answers[index].intValue : intValue,
          optionId: optionID,
          option: answers[index].option,
        });
      } else {
        this.report?.answers.push({
          id: -1,
          questionId: q.id,
          question: q,
          stringValue: stringValue?.trim() || '',
          intValue: q.type.toString() === 'Text' ? null : intValue,
          optionId: q.type.toString() === 'Text' ? null : optionID,
          option: null,
        });
      }
    });
  }
}
