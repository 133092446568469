import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { QuestionTypeEnum } from 'src/app/shared/enums/enums.model';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { AuthorizedHttp } from './authorized-http.service';
import { Question } from './feedback.service';
import { Employee } from './onliner.service';
export const defaultPracticeType: PracticeType = { practice: 'All' };

@Injectable({
  providedIn: 'root',
})
export class SelfAssessmentService {
  private _apiEndpoint = `${environment.apiEndpoint}/api/selfAssessment`;

  constructor(
    private http: AuthorizedHttp,
    private authService: AuthService
  ) {}

  public CheckUserAssessment(): Observable<FbSelfAssessment> {
    return this.http
      .get<FbSelfAssessment>(`${this._apiEndpoint}/selfAssessmentReport`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public saveReport(feedback: FbSelfAssessment): Observable<FbSelfAssessment> {
    return this.http
      .post<FbSelfAssessment>(
        `${this._apiEndpoint}/saveSelfAssessment`,
        feedback
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public submitReport(feedback: FbSelfAssessment): Observable<number> {
    return this.http
      .post<number>(`${this._apiEndpoint}/submitSelfAssessment`, feedback)
      .pipe(
        map(f => f),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public unsubmitReport(feedback: FbSelfAssessment): Observable<number> {
    return this.http
      .post<number>(`${this._apiEndpoint}/unsubmitSelfAssessment`, feedback)
      .pipe(
        map(f => f),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public saveReview(feedback: FbSelfAssessment): Observable<FbSelfAssessment> {
    return this.http
      .post<FbSelfAssessment>(
        `${this._apiEndpoint}/saveSelfAssessmentReview`,
        feedback
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public submitReview(feedback: FbSelfAssessment): Observable<number> {
    return this.http
      .post<number>(`${this._apiEndpoint}/submitSelfAssessmentReview`, feedback)
      .pipe(
        map(f => f),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getAllSelfAssessmentReports(): Observable<FbSelfAssessment[]> {
    return this.http
      .get<FbSelfAssessment[]>(
        `${this._apiEndpoint}/getAllSelfAssessmentReportsFromUser`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getSelfAssessmentDetail(
    selfAssessmentId: number
  ): Observable<FbSelfAssessment> {
    return this.http
      .get<FbSelfAssessment>(
        `${this._apiEndpoint}/getSelfAssessmentDetail/${selfAssessmentId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getSelfAssessmentPractices(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this._apiEndpoint}/selfAssessmentPractices`)
      .pipe(
        map(results => results),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getRecentSelfAssessment(): Observable<FbSelfAssessment> {
    return this.http
      .get<FbSelfAssessment>(`${this._apiEndpoint}/getRecentSelfAssessment`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getAllYearlySelfAssessment(): Observable<FbSelfAssessment[]> {
    return this.http
      .get<FbSelfAssessment[]>(
        `${this._apiEndpoint}/getAllYearlySelfAssessment`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public GetOnlinerGoals(
    year: number,
    readOnlyForm: boolean,
    submitterId: string
  ): Observable<OnlinerGoals[]> {
    return this.http
      .get<OnlinerGoals[]>(
        `${this._apiEndpoint}/GetOnlinerGoals/${year}/${readOnlyForm}/${submitterId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getCorporateCitizenship(
    year: number,
    readOnlyForm: boolean,
    submitterId: string
  ): Observable<string[]> {
    return this.http
      .get<string[]>(
        `${this._apiEndpoint}/GetCorporateCitizenship/${year}/${readOnlyForm}/${submitterId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public GetCountOfFeedbacks(
    year: number,
    readOnlyForm: boolean,
    submitterId: string
  ): Observable<number[]> {
    return this.http
      .get<number[]>(
        `${this._apiEndpoint}/GetCountOfFeedbacks/${year}/${readOnlyForm}/${submitterId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public GetFeedbackData(
    year: number,
    readOnlyForm: boolean,
    submitterId: string
  ): Observable<FbSelfAssessmentFeedback[]> {
    return this.http
      .get<FbSelfAssessmentFeedback[]>(
        `${this._apiEndpoint}/GetFeedbackDataForOnliners/${year}/${readOnlyForm}/${submitterId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }
  public GetFiscalDetail(): Observable<SAFiscalDetails> {
    return this.http
      .get<SAFiscalDetails>(`${this._apiEndpoint}/getFiscalDetails`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public GetPDHistory(
    year: number,
    readOnlyForm: boolean,
    previousYear: boolean,
    submitterId: string
  ): Observable<PDBudgetSummary> {
    return this.http
      .get<PDBudgetSummary>(
        `${this._apiEndpoint}/GetPDHistory/${year}/${readOnlyForm}/${previousYear}/${submitterId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public GetFocalReviewDetails(
    year: number,
    readOnlyForm: boolean,
    submitterId: string
  ): Observable<FocalReviewDetails> {
    return this.http
      .get<FocalReviewDetails>(
        `${this._apiEndpoint}/GetFocalReviewDetails/${year}/${readOnlyForm}/${submitterId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public GetSelfAssessmentReviewers(): Observable<FbSelfAssessmentReviewer[]> {
    return this.http
      .get<FbSelfAssessmentReviewer[]>(
        `${this._apiEndpoint}/selfAssessmentReviewer/`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public GetLatestSelfAssessmentSchedule(): Observable<FbSelfAssessmentSchedule> {
    return this.http
      .get<FbSelfAssessmentSchedule>(
        `${this._apiEndpoint}/GetLatestSelfAssessmentSchedule`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }

  public getSchedule(year: number): Observable<FbSelfAssessmentSchedule> {
    return this.http
      .get<FbSelfAssessmentSchedule>(
        `${this._apiEndpoint}/GetSelfAssessmentSchedule/${year}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse?.error?.exceptionMessage || 'Server error')
        )
      );
  }
}

export class FocalReviewBaseCamp {
  employeeId!: string;
  previousHours!: number;
  currentHours!: number;
}

export class FocalReviewRevenue {
  employeeId!: string;
  previousBillableHours!: number;
  currentBillableHours!: number;
}

export class FocalSickTime {
  employeeId!: string;
  previousSickHours!: number;
  currentSickHours!: number;
}

export class FocalUtilization {
  employeeId!: string;
  utilization!: number;
  fiscalYear!: number;
  monthYear!: string;
}

export class FocalWorkHistory {
  employeeId!: string;
  hours!: number;
  companyName!: number;
}

export class FocalReviewDetails {
  focalReviewBaseCamp!: FocalReviewBaseCamp;
  focalReviewRevenue!: FocalReviewRevenue;
  focalUtilizations!: FocalUtilization[];
  focalSickTime!: FocalSickTime;
  focalWorkHistory!: FocalWorkHistory[];
}

export class OnlinerGoals {
  goalTrackerId!: number;
  userId!: string;
  fiscalYear!: number;
  goal!: string;
  createdDate!: Date;
  lastModifiedDate!: Date;
  showGoal!: boolean;
  goalStatus!: number;
}

export class PDBudgetSummary {
  budgetId!: number;
  budgetYear!: string;
  userId!: number;
  pdDeductionSummary!: PDDeductionSummary[];
}

export class PDDeductionSummary {
  pdDeductionId!: number;
  deductionValue!: number;
  comment!: string;
  deductionId!: number;
  deductionTypeId!: string;
  deductionDesc!: string;
  activeInd!: string;
}

export class FbSelfAssessment {
  id!: number;
  answers!: FbSelfAssessmentAnswer[];
  selfAssessmentReviewerAnswers!: FbSelfAssessmentReviewerAnswer[];
  submittedByEmployee!: Employee;
  submittedBy!: string;
  submittedDate!: Date;
  log!: FbSelfAssessmentAudit[];
  fiscalYear!: number;
  scheduleId!: number;
  reviewDate!: Date;

  RemoveTimePartFromDate(): void {
    const date = new Date(this.submittedDate);
    this.submittedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
}

export class FbSelfAssessmentReviewer {
  id!: number;
  reviewerId!: string;
  geogId!: string;
  regionId!: string;
  competencyRoleId!: number;
  competencyLevelId!: number;
  effectiveDate!: Date;
  endDate!: Date;
  isActive!: boolean;
  isEditor!: boolean;
}

export class FbSelfAssessmentAnswer {
  id!: number;
  questionId!: number;
  question!: Question;
  intValue?: number | null;
  stringValue!: string;
  optionId?: number | null;
  option!: FbQuestionOption | null;
}

export class FbSelfAssessmentReviewerAnswer {
  id!: number;
  questionId!: number;
  question!: Question;
  stringValue!: string;
  optionId?: number | null;
  option?: FbQuestionOption | null;
}

export class FbSelfAssessmentAudit {
  id!: number;
  statusId!: number;
  changedByEmployee!: Employee;
  changedBy!: string;
  changedDate!: Date;
  description!: string;
}

export class FbQuestion {
  id!: number;
  question!: string;
  isOptional!: boolean;
  isActive!: boolean;
  sortOrder!: number;
  type!: QuestionTypeEnum;
  groupId!: number;
  questionGroupSortOrder!: number;
  questionGroupType!: number;
  optionGroupId!: number;
  optionGroup!: FbQuestionOptionGroup;
}

export class FbQuestionOption {
  id!: number;
  isActive!: boolean;
  value!: string;
  groupId!: number;
  sortOrder!: number;
  score!: number;
  groupLabel!: number;
}

export class FbQuestionOptionGroup {
  id!: number;
  label!: string;
  options!: FbQuestionOption[];
}

export class FbSelfAssessmentFeedback {
  submitterId!: string;
  typeDescr!: string;
  type!: string;
  id!: number;
  question!: string;
  answer!: string;
}

export class SAFiscalDetails {
  startDay!: number;
  startMonth!: number;
  endDay!: number;
  endMonth!: number;
}

export class FbSelfAssessmentSchedule {
  id!: number;
  startDate!: Date;
  endDate!: Date;
  fiscalYear!: number;
  formVersion!: number;
}

export class FbFeedbacksSelfAssessment {
  submitterName!: string;
  questions!: string[];
  typeDescr!: string;
  questionType!: string[];
  answers!: string[];
}

export class PracticeType {
  practice!: string;
}
