import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
// import { FeedbackService, Question, QuestionOption } from '../services/feedback.service';
import { Observable, Subscription } from 'rxjs';
// import { Employee, OnlinerService } from '../services/onliner.service';
// import { AuthService } from '../services/auth.service';
// import { SnackBarService } from '../services/snackbar.service';
// import { FeedbackTypeEnum, ClientIdEnum } from '../shared/Enums/enums.model';
import { environment } from 'src/environments/environment';
// import { FbAssessment, AssessmentService, FbAnswer } from '../services/assessment.service';
// import { AutoSuggestService } from '../services/auto-suggest.service';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { debounceTime } from 'rxjs/operators';
import {
  AssessmentService,
  FbAnswer,
  FbAssessment,
} from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import { CareerMentor } from 'src/app/core/services/career-mentor.service';
import {
  FeedbackService,
  Question,
  QuestionOption,
} from 'src/app/core/services/feedback.service';
import {
  Employee,
  OnlinerService,
} from 'src/app/core/services/onliner.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  ClientIdEnum,
  FeedbackTypeEnum,
} from 'src/app/shared/enums/enums.model';

@Component({
  selector: 'app-cm-feedback-form',
  templateUrl: './cm-feedback-form.component.html',
  styleUrls: ['./cm-feedback-form.component.scss'],
})
export class CmFeedbackFormComponent implements OnInit, OnDestroy {
  @Input()
  feedbackForm!: UntypedFormGroup;
  @Input() isAnonymous = false;
  @Input() selectedFormId: number = FeedbackTypeEnum.AdhocFeedback;
  @Input()
  feedbackId!: number | null;
  @Input() set questionGroupIDs(qGroupIds: number[]) {
    if (qGroupIds !== null) {
      if (qGroupIds.length === 1) {
        if (
          !this._questionGroupIds ||
          this._questionGroupIds[0] === qGroupIds[0]
        ) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = true;
        }
      } else if (qGroupIds.length > 1) {
        if (!this._questionGroupIds) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = !this._questionGroupIds.every(qg =>
            qGroupIds.every(question => question === qg)
          );
        }
      }

      this._questionGroupIds = [...qGroupIds];
    }
  }
  @Input()
  questionOptions!: QuestionOption[];
  @Input()
  questionOptionGroupIDs!: number[];
  @Input()
  questions!: Question[];

  @Output() formSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading = false;
  isSubmitting = false;
  isSaving = false;
  routePostfix!: string;
  onlinerCMUserId = '';
  isQuestionFilled = false;
  isTextQuestionFilled = false;
  textQuestionStatus: boolean[] = [];
  scaleQuestions: string[] = [];

  filteredOnliners!: Observable<Employee[]> | Observable<CareerMentor[]>;
  careerMentor?: Employee;
  cmFeedback!: FbAssessment | null;

  onliners!: Employee[];
  feedback!: FbAssessment;
  onlinerCM = '';
  tooltipItems!: string[];
  toolTip!: string;
  onlinerAssigned = false;
  dismissed = false;
  questionsForThisForm: Question[] = [];
  isDraft = false;
  reloadRequired = false;
  textInput!: string;

  isFeedbackAdmin!: boolean;
  feedbackTypeIndicator!: number;
  isNew!: boolean;
  isFeedbackRequest!: boolean;
  autoSaveSubscription!: Subscription | null;
  private _questionGroupIds!: number[];

  constructor(
    private snackBarService: SnackBarService,
    private authService: AuthService,
    private onlinerService: OnlinerService,
    private autoSuggestService: AutoSuggestService,
    private assessmentService: AssessmentService,
    private changeDetectorRef: ChangeDetectorRef,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit() {
    this.isLoading = false;
    this.isFeedbackAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackCFRAdmin,
    ]);
    let index = -1;
    if (this._questionGroupIds) {
      this._questionGroupIds.forEach(qgId => {
        const questionsToFill = this.questions.filter(q => q.groupId === qgId);
        this.questionsForThisForm =
          this.questionsForThisForm.concat(questionsToFill);
      });

      // set up the variables necessary to support save detection for text questions
      this.questionsForThisForm.forEach(q => {
        if (q.type.toString() === 'Text') {
          this.textQuestionStatus[q.id] = false;
        } else if (q.type.toString() === 'Scale') {
          this.scaleQuestions[q.id] = 'N/A';
          index = this.questionOptions.findIndex(
            qo =>
              qo.groupId === q.optionGroupId &&
              qo.value === this.scaleQuestions[q.id]
          );
          if (index > -1) {
            this.feedbackForm.controls[q.id].setValue(
              this.questionOptions[index].score
            );
          } else {
            this.feedbackForm.controls[q.id].setValue(0);
          }
        }
      });
    }

    this.setupPage();
  }

  ngOnDestroy() {
    this.autoSaveSubscription?.unsubscribe();
    this.autoSaveSubscription = null;
  }

  submittedForm() {
    this.formSubmit.emit();
  }
  get onlinerFilterControl() {
    return this.feedbackForm.controls['onliner'];
  }

  get isSaved() {
    return this.cmFeedback && this.cmFeedback.id > 0;
  }

  setOnlinerFilter() {
    this.filteredOnliners = this.autoSuggestService.setOnlinerFilter(
      this.onlinerFilterControl,
      this.onliners
    );
  }

  validateOnliner(): ValidatorFn {
    return (onlinerControl: AbstractControl) => {
      if (!onlinerControl.value || !onlinerControl.value.userId) {
        const temp: ValidationErrors = {};
        temp['required'] = true;
        return temp;
      }
      return null;
    };
  }

  updateDisplayOption(
    question: Question,
    event: { source: MatSliderThumb; parent: MatSlider; value: number }
  ) {
    if (event !== undefined) {
      this.feedbackForm.controls[question.id].setValue(event.value);
    }

    this.scaleQuestions[question.id] = this.displayOptionDescription(question);
  }

  displayOptionDescription(question: Question) {
    const optionScore = this.feedbackForm.controls[question.id].value;
    let option: QuestionOption | undefined;
    if (optionScore) {
      option = this.filterSortThisQuestionOption(question).find(
        qo => qo.score === optionScore
      );
    } else {
      option = this.filterSortThisQuestionOption(question).find(
        qo => qo.score === this.getIntervalMin(question)
      );
    }
    return option?.value ?? '';
  }

  getSliderTickInterval(question: Question) {
    const options = this.filterSortThisQuestionOption(question);
    const interval = options[1].score - options[0].score;
    return interval;
  }

  getIntervalMin(question: Question) {
    const minOption = this.filterSortThisQuestionOption(question);
    return minOption[0].score;
  }

  getIntervalMax(question: Question) {
    const maxOption = this.filterSortThisQuestionOption(question);
    return maxOption[maxOption.length - 1].score;
  }

  filterSortThisQuestionOption(question: Question): QuestionOption[] {
    return this.questionOptions
      .filter(qo => qo.groupId === question.optionGroupId)
      .sort((a, b) => a.score - b.score);
  }

  getIntervalTextValue(question: Question, minOrMax: number): string {
    let textValue: string;

    if (minOrMax === 0) {
      const minOption = this.filterSortThisQuestionOption(question);
      textValue = minOption[0].value;
    } else {
      const maxOption = this.filterSortThisQuestionOption(question);
      textValue = maxOption[maxOption.length - 1].value;
    }

    return textValue;
  }

  validQuestionOptions(question: Question) {
    return this.questionOptions.filter(
      qo => qo.groupId === question.optionGroupId
    );
  }

  getOnlinerCM() {
    return this.careerMentor?.firstName + ' ' + this.careerMentor?.lastName;
  }

  getID(questionID: number): string {
    const idString = '' + questionID + '';
    return idString;
  }

  getRadioTextQuestionId(questionId: number): string {
    const idString = '' + questionId + '_text';
    return idString;
  }

  questionDisplay = (option?: Question, iter = -1): string | undefined => {
    let text;

    if (iter === -1) {
      text = option?.question;
    } else {
      text = iter + '. ' + option?.question;
    }

    return text;
  };

  optionDisplay = (option?: QuestionOption): string | undefined => {
    const text = option?.value;
    return text;
  };

  displayQuestion(question: Question): string {
    return question.question;
  }

  enableFeedbackFields(): void {
    this.questionsForThisForm.forEach(q => {
      this.feedbackForm.controls[`${q.id}`].enable();
    });
  }

  closeAlert() {
    this.dismissed = true;
  }

  updateFeedbackForm() {
    let answer = new FbAnswer();
    const txt = document.createElement('textarea');

    this.questionsForThisForm.forEach(q => {
      if (this.feedback.answers) {
        if (this.feedback.answers.length > 0) {
          answer =
            this.feedback.answers.find(a => a.questionId === q.id) ??
            new FbAnswer();

          if (
            answer.stringValue !== '' ||
            answer.stringValue !== null ||
            answer.optionId !== null
          ) {
            this.questionFilled();
          }

          if (q.type.toString() === 'Text') {
            txt.innerHTML = answer.stringValue || '';
            this.feedbackForm.controls[`${q.id}`].setValue(txt.value);
          } else if (q.type.toString() === 'Scale') {
            this.feedbackForm.controls[`${q.id}`].setValue(answer.intValue);
            this.scaleQuestions[q.id] = this.displayOptionDescription(q);
          } else if (q.type.toString() === 'Radio_Text') {
            this.feedbackForm.controls[`${q.id}`].setValue(answer.optionId);
            this.feedbackForm.controls[`${q.id}_text`].setValue(
              answer.stringValue
            );

            if (answer.intValue === -1) {
              this.feedbackForm.controls[`${q.id}_text`].setValidators([
                Validators.required,
                Validators.minLength(1),
              ]);
            }
          } else {
            this.feedbackForm.controls[`${q.id}`].setValue(answer.optionId);
          }
        }
      }
    });

    this.feedbackTypeIndicator = this.selectedFormId;

    if (
      this.feedback &&
      this.feedback.externalId === null &&
      this.feedback.requestedBy === null
    ) {
      this.isNew = true;
    } else {
      this.isNew = false;
    }

    if (!this.cmFeedback) {
      this.cmFeedback = new FbAssessment();
      this.cmFeedback.id = this.feedback.id;
    }

    this.isLoading = false;
    this.feedbackForm.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  formatComment = (comment: string) => comment.replace(/\n/g, '<br>');

  getOnlinerFromDropdown(employeeId: string) {
    return this.onliners
      ? (this.onliners.find(
          onliner => onliner.userId === employeeId
        ) as Employee)
      : null;
  }

  questionFilled() {
    this.isQuestionFilled = true;
  }

  textQuestionFilled(event: string, questionId: number) {
    if (event !== '') {
      this.textQuestionStatus[questionId] = true;
    } else {
      this.textQuestionStatus[questionId] = false;
    }

    this.isTextQuestionFilled = this.getTextQuestionStatus();
  }

  getTextQuestionStatus(): boolean {
    let status = false;

    this.textQuestionStatus.forEach(t => {
      status = t || status;
    });

    return status;
  }

  submitFeedback() {
    this.isSubmitting = true;
    this.feedbackForm.disable();
    this.updateFeedbackFromForm();

    if (this.cmFeedback) {
      this.assessmentService.submitFeedback(this.cmFeedback).subscribe(
        () => {
          this.snackBarService.message(
            'Your CM feedback submission was successful! It has been submitted to People Care for review.'
          );
          this.isSubmitting = false;
          this.enableFeedbackFields();
          this.resetFeedbackForm();
          this.isDraft = false;
        },
        error => {
          this.isSubmitting = false;
          this.enableFeedbackFields();
          this.snackBarService.error(error);
        }
      );
    }
  }

  saveFeedback() {
    this.isSaving = true;
    this.updateFeedbackFromForm();

    if (this.cmFeedback) {
      this.assessmentService.saveFeedback(this.cmFeedback).subscribe(
        result => {
          this.snackBarService.message(
            'Your CM feedback was saved successfully!'
          );
          if (this.cmFeedback) {
            this.cmFeedback.id = result.id;
          }
          this.setAnswerIds(result.answers);
          this.isSaving = false;
          this.isDraft = true;
          this.feedbackForm.markAsPristine();
        },
        error => {
          this.isSaving = false;
          this.snackBarService.error(error);
        }
      );
    }
  }

  resetFeedbackForm() {
    this.feedbackForm.reset();
    this.submittedForm();
    this.cmFeedback = null;
    this.feedbackId = null;
    this.isQuestionFilled = false;
    this.isTextQuestionFilled = false;
    this.ngOnInit();
  }

  onSearchChange(target: QuestionOption): void {
    localStorage.setItem(String(target.id), target.value);
  }

  radioTextChange(optionId: number, questionId: number) {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    if (option !== null && option !== undefined) {
      const question = this.questions.find(q => q.id === questionId);
      if (option.score === -1) {
        this.feedbackForm.controls[`${question?.id}_text`].setValidators([
          Validators.required,
          Validators.minLength(1),
        ]);
        if (question?.id) {
          this.commentRequired(question.id, optionId);
        }
      } else {
        this.feedbackForm.controls[`${question?.id}_text`].clearValidators();
      }

      this.feedbackForm.controls[
        `${question?.id}_text`
      ]?.updateValueAndValidity();
    }
  }

  get hasQuestions(): boolean {
    return this.questionsForThisForm.length > 0;
  }

  private commentRequired(questionId: number, optionId: number): boolean {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    let required = false;

    if (option !== null && option !== undefined) {
      if (option.score === -1) {
        this.feedbackForm.controls[`${questionId}_text`].markAsTouched();
        required = true;
      }
    }

    return required;
  }

  private setupPage() {
    this.feedbackTypeIndicator = this.selectedFormId;
    this.isLoading = true;

    this.onlinerService.getOnlinersExcludingDefault().subscribe(
      data => {
        const userID = this.authService.getUserId().toLowerCase();
        this.careerMentor = data.find(
          e =>
            e.userId === data.find(emp => emp.userId === userID)?.careerManager
        );

        if (this.feedbackId) {
          this.getFeedback();
        } else {
          this.isLoading = false;
        }
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
    this.setupAutoSave();
  }
  private getFeedback() {
    if (this.feedbackId) {
      this.assessmentService.getAssessment(this.feedbackId).subscribe(
        f => {
          this.cmFeedback = f;
          this.feedback = f;
          this.isFeedbackRequest =
            this.feedback.requestedBy === null &&
            this.feedback.externalId === null
              ? false
              : true;
          this.isDraft = true;
          this.isAnonymous = f.isAnonymous;
          this.feedbackService.isAnonymousUpdated(this.isAnonymous);
          this.updateFeedbackForm();
        },
        error => {
          this.snackBarService.error(error);
          this.isLoading = false;
        }
      );
    }
  }

  private setupAutoSave() {
    this.autoSaveSubscription = this.feedbackForm?.valueChanges
      .pipe(debounceTime(environment.autoSaveInterval))
      .subscribe(() => {
        if (this.feedbackForm.dirty) {
          this.saveFeedback();
        }
      });
  }

  private updateFeedbackFromForm() {
    if (!this.cmFeedback) {
      this.cmFeedback = new FbAssessment();
    }

    // set initial values that we will already know
    if (this.careerMentor) {
      this.cmFeedback.employee = this.careerMentor;
    }
    this.cmFeedback.client = {
      clientId: ClientIdEnum.OnlineBusinessSystems,
      clientName: 'Online Business Systems',
    }; // default selection of Online as client for backend...
    this.cmFeedback.feedbackTypeId = FeedbackTypeEnum.CareerMentorFeedback;
    this.cmFeedback.isAnonymous = this.isAnonymous;
    this.cmFeedback.submittedBy = this.authService.getUserId().toLowerCase();

    const newAnswers: FbAnswer[] = [];

    this.questionsForThisForm.forEach(q => {
      if (this.cmFeedback && !this.cmFeedback.answers) {
        this.cmFeedback.answers = [];
      }

      const options = this.questionOptions.filter(
        qo => qo.groupId === q.optionGroupId
      );

      let stringValue: string | null = '';
      let intValue: number | null = 0;
      let optionID: number | null = 0;

      if (q.type.toString() === 'Scale') {
        intValue = this.feedbackForm.controls[q.id].value;
        optionID =
          this.questionOptions.find(
            qo =>
              qo.score === intValue &&
              qo.groupId ===
                this.questionOptionGroupIDs.find(
                  qoid => qoid === q.optionGroupId
                )
          )?.id || null; // this is slightly better
      } else if (q.type.toString() === 'Radio_Text') {
        intValue = !this.feedbackForm.value[q.id]
          ? null
          : options.find(sv => sv.id === this.feedbackForm.value[q.id])
              ?.score || null;
        stringValue = this.feedbackForm.controls[`${q.id}_text`].value;
        optionID = !this.feedbackForm.value[q.id]
          ? null
          : options.find(sv => sv.id === this.feedbackForm.value[q.id])?.id ||
            null;
      } else {
        stringValue =
          q.type.toString() === 'Text'
            ? this.feedbackForm.value[q.id]
            : !this.feedbackForm.value[q.id]
            ? null
            : options.find(sv => sv.id === this.feedbackForm.value[q.id])
                ?.value || null;
        intValue =
          q.type.toString() === 'Text'
            ? null
            : !this.feedbackForm.value[q.id]
            ? null
            : options.find(sv => sv.id === this.feedbackForm.value[q.id])
                ?.score || null;
        optionID =
          q.type.toString() === 'Text'
            ? null
            : !this.feedbackForm.value[q.id]
            ? null
            : options.find(sv => sv.id === this.feedbackForm.value[q.id])?.id ||
              null;
      }

      if (this.cmFeedback?.answers) {
        const index = this.cmFeedback.answers.findIndex(
          a => a.questionId === q.id
        );

        if (index > -1) {
          const foundAnswer = this.cmFeedback.answers[index];
          foundAnswer.stringValue = stringValue;
          foundAnswer.intValue = intValue;
          foundAnswer.optionId = optionID;
          newAnswers.push(foundAnswer);
        } else {
          newAnswers.push({
            id: -1,
            questionId: q.id,
            question: q,
            stringValue: stringValue || '',
            intValue: q.type.toString() === 'Text' ? null : intValue,
            optionId: q.type.toString() === 'Text' ? null : optionID,
            option: null,
          });
        }
      }
    });

    this.cmFeedback.answers = newAnswers;
  }

  private setAnswerIds(answers: FbAnswer[]) {
    answers.forEach(ans => {
      this.cmFeedback?.answers.forEach(cmAns => {
        if (cmAns.questionId === ans.questionId) {
          cmAns.id = ans.id;
        }
      });
    });
  }
}
