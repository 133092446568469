import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  AssessmentService,
  FbAnswer,
  FbAssessment,
} from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { AutoSuggestService } from 'src/app/core/services/auto-suggest.service';
import { CareerMentor } from 'src/app/core/services/career-mentor.service';
import { Client, CommonService } from 'src/app/core/services/common.service';
import {
  Question,
  QuestionOption,
} from 'src/app/core/services/feedback.service';
import {
  Employee,
  GoalTrackerGoals,
  OnlinerService,
} from 'src/app/core/services/onliner.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  ClientIdEnum,
  FeedbackTypeEnum,
  GoalTrackerStatusEnum,
} from 'src/app/shared/enums/enums.model';
import * as formValidators from 'src/app/shared/validators/form-validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  feedbackId!: number | null;
  @ViewChild('autosize')
  autosize!: CdkTextareaAutosize;

  @Input()
  feedbackForm!: UntypedFormGroup;
  @Input() selectedFormId?: number = FeedbackTypeEnum.AdhocFeedback;
  @Input()
  selectedTypeLoaded!: boolean;
  @Input() set questionGroupIDs(qGroupIds: number[]) {
    if (qGroupIds !== null) {
      if (qGroupIds.length === 1) {
        if (
          !this._questionGroupIds ||
          this._questionGroupIds[0] === qGroupIds[0]
        ) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = true;
        }
      } else if (qGroupIds.length > 1) {
        if (!this._questionGroupIds) {
          this.reloadRequired = false;
        } else {
          this.reloadRequired = !this._questionGroupIds.every(qg =>
            qGroupIds.every(question => question === qg)
          );
        }
      } else {
        this.reloadRequired = true;
      }

      this._questionGroupIds = [...qGroupIds];
    }
  }
  @Input()
  questionOptions!: QuestionOption[];
  @Input()
  questionOptionGroupIDs!: number[];
  @Input()
  questions!: Question[];
  @Input()
  isThirdParty!: boolean;

  @Output() draftCheck: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() feedbackHasBeenSaved: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  onliners!: Employee[];
  clients!: Client[];
  feedback!: FbAssessment | null;
  onlinerCM = '';
  onlinerCMUserId = '';
  tooltipItems!: string[];
  toolTip!: string;
  onlinerAssigned = false;
  clientAssigned = false;
  dismissed = false;
  questionsForThisForm: Question[] = [];
  isDraft = false;
  reloadRequired = false;

  defaultColor = 'rgb(156, 30, 54)';
  disabledColor = 'rgba(0,0,0,.38)';

  unsolicitedFormId: number = FeedbackTypeEnum.AdhocFeedback;
  clientFeedbackFormId: number = FeedbackTypeEnum.ClientFeedback;
  ciaFormId: number = FeedbackTypeEnum.CultureInAction;
  thirdPartyId: number = FeedbackTypeEnum.ThirdPartyFeedback;
  autoSaveSubscription!: Subscription | null;

  filteredOnliners!: Observable<Employee[]> | Observable<CareerMentor[]>;
  filteredClients!: Observable<Client[]>;

  isLoading!: boolean;
  isSaving!: boolean;
  isSubmitting!: boolean;
  isFeedbackAdmin!: boolean;
  feedbackTypeIndicator?: number;
  isNew!: boolean;
  isFeedbackRequest = false;
  beenSaved = false;

  errorMatcher = new formValidators.CompControlErrorStateMatcher();
  theCheckbox = false;

  private _questionGroupIds!: number[];

  constructor(
    private assessmentService: AssessmentService,
    private commonService: CommonService,
    private onlinerService: OnlinerService,
    private snackBarService: SnackBarService,
    private autoSuggestService: AutoSuggestService,
    private changeDetectorRef: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isFeedbackAdmin = this.authService.doesUserHaveRole([
      environment.roles.FeedbackCFRAdmin,
    ]);
    if (this._questionGroupIds) {
      this._questionGroupIds.forEach(qgId => {
        const questionsToFill = this.questions.filter(q => q.groupId === qgId);

        if (!this.questionsForThisForm) {
          this.questionsForThisForm = [...questionsToFill];
        } else {
          this.questionsForThisForm =
            this.questionsForThisForm.concat(questionsToFill);
        }
      });
    } else {
      this.questionsForThisForm = [];
    }

    if (this.selectedFormId !== FeedbackTypeEnum.ClientFeedback) {
      // To be removed after Client Feedback form is implemented
      this.setupPage(this.selectedFormId);
    }
  }

  ngOnDestroy() {
    if (this.autoSaveSubscription) {
      this.autoSaveSubscription.unsubscribe();
      this.autoSaveSubscription = null;
    }
  }

  ngOnChanges() {
    if (this.reloadRequired) {
      if (this.isThirdParty) {
        this.resetFeedbackForm();
      }

      if (this._questionGroupIds !== null) {
        this.questionsForThisForm = [];
        this._questionGroupIds.forEach(qgId => {
          const questionsToFill = this.questions.filter(
            q => q.groupId === qgId
          );
          this.questionsForThisForm = [
            ...this.questionsForThisForm,
            ...questionsToFill,
          ];
        });
      }

      if (!this.autoSaveSubscription) {
        this.setupAutoSave();
      }

      if (this.selectedFormId !== FeedbackTypeEnum.ClientFeedback) {
        // To be removed after Client Feedback form is implemented
        this.setupPage(this.selectedFormId);
      }
    }
  }

  isDraftCheck() {
    this.draftCheck.emit(this.isDraft);
  }
  hasBeenSaved() {
    this.feedbackHasBeenSaved.emit(this.beenSaved);
  }

  get onlinerFilterControl() {
    return this.feedbackForm.controls['onliner'];
  }

  get onlinerAndClientValidation() {
    return (
      this.feedbackForm.controls['onliner'].valid &&
      this.feedbackForm.controls['client'].valid
    );
  }

  get clientControl() {
    return this.feedbackForm.controls['client'];
  }
  get isSaved() {
    return this.feedback && this.feedback.id > 0;
  }

  showError = (controlName: string) => {
    const control = this.feedbackForm.controls[controlName];

    if (control && control.hasError('required') && control.touched) {
      return true;
    }
    return false;
  };

  closeAlert() {
    this.dismissed = true;
  }

  displayQuestion(question: Question): string {
    return question.question;
  }

  getID(questionID: number): string {
    const idString = '' + questionID + '';
    return idString;
  }
  getSliderTickInterval(question: Question) {
    const options = this.filterSortThisQuestionOption(question);
    const interval = options[1].score - options[0].score;
    return interval;
  }
  getIntervalMin(question: Question) {
    const minOption = this.filterSortThisQuestionOption(question);
    return minOption[0].score;
  }
  getIntervalMax(question: Question) {
    const maxOption = this.filterSortThisQuestionOption(question);
    return maxOption[maxOption.length - 1].score;
  }
  filterSortThisQuestionOption(question: Question): QuestionOption[] {
    return this.questionOptions
      .filter(qo => qo.groupId === question.optionGroupId && qo.id > 1000)
      .sort((a, b) => a.score - b.score);
  }
  getIntervalTextValue(question: Question, minOrMax: number): string {
    let textValue: string;
    if (minOrMax === 0) {
      const minOption = this.filterSortThisQuestionOption(question);
      textValue = minOption[0].value;
    } else {
      const maxOption = this.filterSortThisQuestionOption(question);
      textValue = maxOption[maxOption.length - 1].value;
    }
    return textValue;
  }

  validQuestionOptions(question: Question): QuestionOption[] {
    return this.questionOptions.filter(
      qo => qo.groupId === question.optionGroupId
    );
  }

  optionDisplay = (option?: QuestionOption): string | undefined => {
    const text = option?.value;
    return text;
  };

  onSearchChange(target: QuestionOption): void {
    localStorage.setItem(String(target.id), target.value);
  }

  resetFeedbackForm() {
    this.feedbackForm.reset();
    this.feedback = null;
    this.feedbackId = null;
    if (this.selectedFormId !== FeedbackTypeEnum.CultureInAction) {
      // not cia-feedback
      this.isFeedbackRequest = false;
      if (this.isThirdParty) {
        this.isDraft = false;
      } else {
        this.isDraft = true;
      }
    }
  }

  updateFeedbackForm() {
    this.feedbackForm.controls['onliner'].setValue(
      this.getOnlinerFromDropdown(this.feedback?.employee.userId)
    );
    this.onlinerAssigned = true;
    const txt = document.createElement('textarea');

    if (this.isDraft) {
      this.noEventOnlinerSelected(this.feedback?.employee);
      if (this.feedback) {
        this.onlinerCMUserId =
          this.feedback.employee.careerManager.toLowerCase();
      }
      this.isLoading = false;
    }

    if (this.feedback) {
      this.getOnlinerPublicGoals(this.feedback.employee.userId);
    }

    if (this.selectedFormId !== FeedbackTypeEnum.CultureInAction) {
      this.feedbackForm.controls['client'].setValue(
        this.getClientFromDropdown(this.feedback?.client.clientId)
      );
      this.feedbackForm.controls['otherClient'].setValue(
        this.feedback?.otherClient
      );
      this.clientAssigned = true;
    }

    // fill in answers to the questions, if there are any...
    this.questionsForThisForm.forEach(q => {
      if (this.feedback?.answers) {
        this.feedback.answers.forEach(a => {
          if (q.id === a.questionId) {
            if (q.type.toString() === 'Text') {
              txt.innerHTML = a.stringValue || '';
              this.feedbackForm.controls[`${q.id}`].setValue(txt.value);
            } else if (q.type.toString() === 'Scale') {
              this.feedbackForm.controls[`${q.id}`].setValue(a.intValue);
            } else if (q.type.toString() === 'Radio_Text') {
              this.feedbackForm.controls[`${q.id}`].setValue(a.optionId);
              this.feedbackForm.controls[`${q.id}_text`].setValue(
                a.stringValue
              );

              if (a.intValue === -1) {
                this.feedbackForm.controls[`${q.id}_text`].setValidators([
                  Validators.required,
                  Validators.minLength(1),
                  formValidators.requiredAndNoWhiteSpaceValidator,
                ]);
              }
            } else {
              this.feedbackForm.controls[`${q.id}`].setValue(a.optionId);
            }
          }
        });
      }
    });

    this.feedbackTypeIndicator = this.selectedFormId;

    if (
      this.feedback?.externalId === null &&
      this.feedback?.requestedBy === null
    ) {
      this.isNew = true;
    } else {
      this.isNew = false;
    }

    this.feedbackForm.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  validateOnliner(): ValidatorFn {
    return (onlinerControl: AbstractControl) => {
      // this.feedbackForm.controls['onliner'].updateValueAndValidity();
      if (!onlinerControl.value || !onlinerControl.value.userId) {
        const temp: ValidationErrors = {};
        temp['required'] = true;
        return temp;
      }
      return null;
    };
  }

  validateClient(): ValidatorFn {
    return (clientControl: AbstractControl) => {
      this.feedbackForm.controls['otherClient'].updateValueAndValidity();
      if (!clientControl.value || !clientControl.value.clientName) {
        const temp: ValidationErrors = {};
        temp['required'] = true;
        return temp;
      }
      return null;
    };
  }

  validateOtherClient(): ValidatorFn {
    return (otherClientControl: AbstractControl) => {
      const isOtherClient =
        this.feedbackForm.controls['client'].value &&
        this.feedbackForm.controls['client'].value.clientName === 'Other';
      if (
        (!otherClientControl.value ||
          otherClientControl.value.trim().length <= 0) &&
        isOtherClient
      ) {
        const temp: ValidationErrors = {};
        temp['required'] = true;
        return temp;
      }
      return null;
    };
  }

  setOnlinerFilter() {
    const result = this.autoSuggestService.setOnlinerFilter(
      this.onlinerFilterControl,
      this.onliners
    );
    this.filteredOnliners = result;
  }

  setClientFilter() {
    this.filteredClients = this.autoSuggestService.setClientFilter(
      this.clientControl,
      this.clients
    );
  }

  getOnlinerCM() {
    let onlinerCM = new Employee();
    let cmDisplay = '';

    if (this.feedbackForm.value.onliner && !this.isDraft) {
      const onlinerCMId = this.feedbackForm.value.onliner.careerManager;
      onlinerCM = this.onliners.find(
        onliner => onliner.userId === onlinerCMId
      ) as Employee;
    } else if (this.isDraft) {
      onlinerCM = this.onliners.find(
        onliner => onliner.userId === this.onlinerCMUserId
      ) as Employee;
    }

    cmDisplay = this.onlinerDisplay(onlinerCM);
    this.onlinerCM = cmDisplay;
    return cmDisplay;
  }

  getOnliner() {
    let onliner = '';

    if (this.feedbackForm.value.onliner) {
      onliner = this.onlinerDisplay(this.feedbackForm.value.onliner);
    }

    return onliner;
  }

  getClient() {
    let client = '';

    if (this.feedbackForm.value.client) {
      if (this.feedbackForm.value.client.clientName) {
        client = this.clientDisplay(this.feedbackForm.value.client);
      }
    }

    return client;
  }

  noEventOnlinerSelected(emp?: Employee) {
    if (emp) {
      this.onlinerAssigned = true;
      this.getOnlinerPublicGoals(emp.userId.toLowerCase());
    }
  }

  onlinerSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;

    if ((e.source && value) || value === '') {
      this.onlinerAssigned = true;
      this.getOnlinerPublicGoals(value.userId);
    }
  }

  clientSelected(e: MatAutocompleteSelectedEvent) {
    const value = e.option.value;

    if ((e.source && value) || value === '') {
      this.clientAssigned = true;
    }
  }

  radioTextChange(optionId: number, questionId: number) {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    if (option !== null && option !== undefined) {
      const question = this.questions.find(q => q.id === questionId);
      if (option.score === -1) {
        this.feedbackForm.controls[`${question?.id}_text`].setValidators([
          Validators.required,
          Validators.minLength(1),
          formValidators.requiredAndNoWhiteSpaceValidator,
        ]);
        if (question?.id) {
          this.commentRequired(question.id, optionId);
        }
      } else {
        this.feedbackForm.controls[`${question?.id}_text`].clearValidators();
      }

      this.feedbackForm.controls[
        `${question?.id}_text`
      ].updateValueAndValidity();
    }
  }

  getRadioTextQuestionId(questionId: number): string {
    const idString = '' + questionId + '_text';
    return idString;
  }

  getOnlinerPublicGoals(onlinerId: string) {
    this.onlinerService.getOnlinerPublicGoals(onlinerId).subscribe(
      goals => {
        this.feedbackForm.controls['goals'].setValue(this.formatGoals(goals));
      },
      error => {
        this.snackBarService.error(error);
      }
    );
  }

  formatGoals(goals: GoalTrackerGoals[]): string {
    let activeGoalString = '';

    goals.forEach(g => {
      if (g.goalStatus === GoalTrackerStatusEnum.Active) {
        const goal = `  • ${this.htmlDecode(g.goal)}`.replace(/\n/g, '\n    ');
        const spacer = activeGoalString.length > 0 ? '\n' : '';
        activeGoalString = `${activeGoalString}${spacer}${goal}`;
      }
    });

    if (activeGoalString.length > 0) {
      activeGoalString = `Active Goals:\n${activeGoalString}`;
    }

    return `${activeGoalString}`;
  }

  htmlDecode(input: string) {
    const txt = document.createElement('textarea');
    txt.innerHTML = input;
    return txt.value;
  }

  getOnlinerFromDropdown(employeeId?: string) {
    return this.onliners
      ? (this.onliners.find(
          onliner => onliner.userId === employeeId
        ) as Employee)
      : null;
  }

  getClientFromDropdown(clientId?: number) {
    return this.clients
      ? (this.clients.find(c => c.clientId === clientId) as Client)
      : null;
  }

  saveFeedback() {
    if (!this.isThirdParty) {
      this.isSaving = true;
      this.updateFeedbackFromForm();

      if (this.feedback) {
        this.assessmentService.saveFeedback(this.feedback).subscribe(
          savedFeedback => {
            this.feedback = savedFeedback;
            this.beenSaved = true;
            this.hasBeenSaved();
            this.snackBarService
              .message(`Save successful! Your feedback for ${this.feedbackForm.value.onliner.firstName}  ${this.feedbackForm.value.onliner.lastName}
            has been saved`);
            this.isSaving = false;
            if (this.selectedFormId !== FeedbackTypeEnum.CultureInAction) {
              // not cia-feedback
              this.isFeedbackRequest = true;
              this.isDraft = true;
            }
            this.feedbackForm.markAsPristine();
          },
          error => {
            this.isSaving = false;
            this.snackBarService.error(error);
          }
        );
      }
    }
  }

  enableFeedbackFields(): void {
    this.feedbackForm.controls['onliner'].enable();

    if (this.selectedFormId !== FeedbackTypeEnum.CultureInAction) {
      this.feedbackForm.controls['otherClient'].enable();
      this.feedbackForm.controls['client'].enable();
    }

    this.questionsForThisForm.forEach(q => {
      this.feedbackForm.controls[`${q.id}`].enable();
    });
  }

  submitFeedback() {
    this.isSubmitting = true;
    this.feedbackForm.disable();
    this.updateFeedbackFromForm();

    if (this.feedback) {
      this.assessmentService.submitFeedback(this.feedback).subscribe(
        submittedFeedback => {
          this.snackBarService
            .message(`Submission successful! Your feedback for ${submittedFeedback.employeeName}
          has been submitted and an email was sent to ${this.feedbackForm.value.onliner.firstName}'s career mentor. `);
          this.isSubmitting = false;
          this.isSaving = false;
          this.enableFeedbackFields();
          this.resetFeedbackForm();
          this.onlinerAssigned = false;
          this.clientAssigned = false;
          this.navigateToFeedback();
        },
        error => {
          this.isSubmitting = false;
          this.enableFeedbackFields();
          this.snackBarService.error(error);
        }
      );
    }
  }

  onlinerDisplay = (option?: Employee | CareerMentor): string => {
    const display = this.autoSuggestService.onlinerDisplay(option);
    return display;
  };

  clientDisplay = (option?: Client): string =>
    this.autoSuggestService.clientDisplay(option);

  navigateToFeedback() {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate(['/feedback']));
  }

  isSaveButtonDisabled(): boolean {
    let isDisabled =
      this.isSaving ||
      this.isSubmitting ||
      this.feedbackForm.get('onliner')?.errors !== null;
    if (this.selectedFormId !== this.ciaFormId) {
      isDisabled =
        isDisabled || this.feedbackForm.get('client')?.errors !== null;
    }
    return isDisabled;
  }

  get hasQuestions(): boolean {
    return this.questionsForThisForm.length > 0;
  }

  private setupPage(selectedFormId?: number) {
    this.feedbackTypeIndicator = this.selectedFormId;
    this.isLoading = true;

    forkJoin([
      this.onlinerService.getOnlinersExcludingDefault(),
      this.commonService.getClientsExcludingDefault(),
    ]).subscribe(
      data => {
        this.onliners = data[0];
        this.setOnlinerFilter();
        this.feedbackForm.controls['onliner'].setValidators(
          this.validateOnliner()
        );
        if (selectedFormId !== FeedbackTypeEnum.CultureInAction) {
          // not cia-feedback
          this.clients = data[1];
          this.setClientFilter();
          this.feedbackForm.controls['client'].setValidators(
            this.validateClient()
          );
          this.feedbackForm.controls['otherClient'].setValidators(
            this.validateOtherClient()
          );
        } else {
          this.clientAssigned = true;
        }

        this.setupAutoSave();

        this.tooltipItems = [
          'Choose "Other" if the client is not in the list.',
          'Choose "Online Business Systems" if the Onliner is in a non-billable role.',
        ];
        this.toolTip = this.tooltipItems.join('\r\n');

        if (this.feedbackId) {
          this.getFeedback();
        } else {
          this.isLoading = false;
          this.isDraftCheck();
        }
      },
      error => {
        this.isLoading = false;
        this.snackBarService.error(error);
      }
    );
  }

  private getFeedback() {
    if (this.feedbackId) {
      this.assessmentService.getAssessment(this.feedbackId).subscribe(
        f => {
          this.feedback = f;
          this.isFeedbackRequest =
            this.feedback.requestedBy === null &&
            this.feedback.externalId === null
              ? false
              : true;
          this.isDraft = true;
          this.isDraftCheck();
          this.updateFeedbackForm();
        },
        error => {
          this.snackBarService.error(error);
          this.isLoading = false;
        }
      );
    }
  }

  private setupAutoSave() {
    if (!this.isThirdParty) {
      this.autoSaveSubscription = this.feedbackForm.valueChanges
        .pipe(debounceTime(environment.autoSaveInterval))
        .subscribe(() => {
          if (this.onlinerAndClientValidation && this.feedbackForm.dirty) {
            this.saveFeedback();
          }
        });
    } else {
      this.autoSaveSubscription?.unsubscribe();
      this.autoSaveSubscription = null;
    }
  }

  private commentRequired(questionId: number, optionId: number): boolean {
    const option = this.questionOptions.find(qo => qo.id === optionId);
    let required = false;

    if (option !== null && option !== undefined) {
      if (option.score === -1) {
        this.feedbackForm.controls[`${questionId}_text`].markAsTouched();
        required = true;
      }
    }

    return required;
  }

  private updateFeedbackFromForm() {
    if (!this.feedback) {
      this.feedback = new FbAssessment();
      this.feedback.id = -1;
    }

    this.feedback.employee = this.feedbackForm.value.onliner
      ? this.feedbackForm.value.onliner
      : null;

    if (this.selectedFormId === FeedbackTypeEnum.CultureInAction) {
      this.feedback.client = {
        clientId: ClientIdEnum.OnlineBusinessSystems,
        clientName: 'Online Business Systems',
      }; // client for cia-feedback is set to Online Business Systems
      this.feedback.feedbackTypeId = FeedbackTypeEnum.CultureInAction;
    } else {
      this.feedback.client = this.feedbackForm.value.client
        ? this.feedbackForm.value.client
        : null;
      this.feedback.otherClient = this.feedbackForm.value.otherClient
        ? this.feedbackForm.value.otherClient
        : null;
      if (this.selectedFormId) {
        this.feedback.feedbackTypeId = this.selectedFormId;
      }
      this.onlinerCMUserId =
        this.feedbackForm.value.onliner.careerManager.toLowerCase();
    }

    const newAnswers: FbAnswer[] = [];

    this.questionsForThisForm.forEach(q => {
      if (this.feedback && !this.feedback.answers) {
        this.feedback.answers = [];
      }

      const options = this.questionOptions.filter(
        qo => qo.groupId === q.optionGroupId
      );

      let stringValue: string | null = '';
      let intValue: number | null = 0;
      let optionID: number | null = 0;

      if (q.type.toString() === 'Scale') {
        intValue = this.feedbackForm.controls[q.id].value;
        optionID =
          this.questionOptions.find(
            qo =>
              qo.score === intValue &&
              qo.groupId ===
                this.questionOptionGroupIDs.find(
                  qoid => qoid === q.optionGroupId
                )
          )?.id || null; // this is slightly better
      } else if (q.type.toString() === 'Radio_Text') {
        intValue = !this.feedbackForm.value[q.id]
          ? null
          : options.find(sv => sv.id === this.feedbackForm.value[q.id])
              ?.score || null;
        stringValue = this.feedbackForm.controls[`${q.id}_text`].value;
        optionID = !this.feedbackForm.value[q.id]
          ? null
          : options.find(sv => sv.id === this.feedbackForm.value[q.id])?.id ||
            null;
      } else {
        stringValue =
          q.type.toString() === 'Text'
            ? this.feedbackForm.value[q.id]
            : !this.feedbackForm.value[q.id]
            ? null
            : options.find(sv => sv.id === this.feedbackForm.value[q.id])
                ?.value || null;
        intValue =
          q.type.toString() === 'Text'
            ? null
            : !this.feedbackForm.value[q.id]
            ? null
            : options.find(sv => sv.id === this.feedbackForm.value[q.id])
                ?.score || null;
        optionID =
          q.type.toString() === 'Text'
            ? null
            : !this.feedbackForm.value[q.id]
            ? null
            : options.find(sv => sv.id === this.feedbackForm.value[q.id])?.id ||
              null;
      }

      if (this.feedback) {
        const index: number = this.feedback.answers.findIndex(
          a => a.questionId === q.id
        );

        if (index > -1) {
          const foundAnswer = this.feedback.answers[index];
          foundAnswer.stringValue = stringValue;
          foundAnswer.intValue = intValue;
          foundAnswer.optionId = optionID;
          newAnswers.push(foundAnswer);
        } else {
          newAnswers.push({
            id: -1,
            questionId: q.id,
            question: q,
            stringValue: stringValue || '', // this.feedbackForm.value[q.id],
            intValue: q.type.toString() === 'Text' ? null : intValue, // q.optionGroup.options.find(o => o.value == this.feedbackForm.value.qID).score,
            optionId: q.type.toString() === 'Text' ? null : optionID, // optionId: q.type.toString() == 'Text' ? null: q.optionGroup.options.find(o => o.value == this.feedbackForm.value.qID).id,
            option: null, // q.type.toString() == 'Text' ? null: q.optionGroup.options.find(o => o.value == this.feedbackForm.value.qID),
          });
        }
      }
    });
    this.feedback.answers = newAnswers;
  }
}
