import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import {
  FeedbackService,
  Question,
  QuestionGroup,
  QuestionOption,
  QuestionOptionGroup,
} from 'src/app/core/services/feedback.service';
import {
  FbSelfAssessment,
  FbSelfAssessmentSchedule,
  SelfAssessmentService,
} from 'src/app/core/services/self-assessment.service';
import {
  FbWeeklyReport,
  WeeklyReportService,
} from 'src/app/core/services/weekly-report-service';
import {
  FeedbackStatusEnum,
  FeedbackTypeEnum,
} from 'src/app/shared/enums/enums.model';
import { OnlinerPersonalInfo } from 'src/app/shared/models/onliner-personal-info.model';
import { environment } from 'src/environments/environment';
import * as formValidators from '../../../shared/validators/form-validator';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit, OnDestroy {
  isLoading!: boolean;
  isCollapsed = true;
  feedbackId!: number;
  feedbackTypeId?: number;
  isFeedbackRequest = true;
  isAnonymous!: boolean;
  routePostfix!: string;
  isDraft = true;
  isCIAFeedback = false;
  hasBeenSaved = false;
  isThirdParty = false;
  isUnsolicited = false;
  initialSelectedType?: FormType;
  presentFormSelected = true;
  submittedForThisWeek!: boolean;
  submittedForThisYear = false;
  latestScheduled!: FbSelfAssessmentSchedule;
  user!: OnlinerPersonalInfo;
  role!: string;
  firstName!: string;
  lastName!: string;
  office!: string;
  isRetreivingData = true;
  startDate!: string;
  primaryCompetency!: string;
  consultantCompetency!: string;
  practice!: string;

  reports!: FbWeeklyReport[];
  assessment!: FbSelfAssessment;

  questions!: Question[];
  questionGroups!: QuestionGroup[];
  questionOptions!: QuestionOption[];
  questionOptionGroups!: QuestionOptionGroup[];
  questionGroupIDs!: number[];
  questionOptionGroupIDs: number[] = [];
  subscriptions: Subscription[] = [];

  feedbackForm!: UntypedFormGroup;
  formTypes: FormType[] = [
    { Id: FeedbackTypeEnum.AdhocFeedback, name: 'Unsolicited' },
    { Id: FeedbackTypeEnum.CultureInAction, name: 'Culture In Action' },
  ];
  cmFeedbackFormType: FormType = {
    Id: FeedbackTypeEnum.CareerMentorFeedback,
    name: 'Career Mentor Feedback',
  };
  thirdPartyFormType: FormType = {
    Id: FeedbackTypeEnum.ThirdPartyFeedback,
    name: 'Third Party Feedback',
  };
  weeklyDeliveryFormType: FormType = {
    Id: FeedbackTypeEnum.WeeklyHealthDelivery,
    name: 'Weekly PulseCheck Report',
  };
  selfAssessmentFormType: FormType = {
    Id: FeedbackTypeEnum.SelfAssessment,
    name: 'Self Assessment',
  };
  confidentialFeedbackFormType: FormType = {
    Id: FeedbackTypeEnum.ConfidentialFeedback,
    name: 'Confidential Feedback',
  };
  selectedType!: FormType;
  selectedTypeLoaded!: boolean;
  cfrAdminRole: string = environment.roles.FeedbackCFRAdmin;

  constructor(
    private route: ActivatedRoute,
    private commonService: CommonService,
    private feedbackService: FeedbackService,
    private weeklyReportService: WeeklyReportService,
    private selfAssessmentService: SelfAssessmentService,
    private router: Router
  ) {}

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): boolean | Observable<boolean> {
    return !this.feedbackForm || !this.feedbackForm.dirty;
  }

  get targetWeek() {
    const currentDate = new Date();
    let result = new Date();
    const friday = 5; // friday's day of the week, Sunday: 0 - Saturday: 6
    const weekLength = 7;
    const monday = 1;
    const midnight = 23;
    // const sevenDays = 7;
    let dateModifier = 0;
    const timeEquivalent = 86400000;
    // if it's Sunday or Monday before midnight (11:59pm) we want to look at the previous week still
    if (currentDate.getDay() === 0) {
      dateModifier = 2 * timeEquivalent;
    } else if (
      currentDate.getDay() === monday &&
      currentDate.getHours() <= midnight
    ) {
      dateModifier = 3 * timeEquivalent;
    } else if (currentDate.getDay() === 6) {
      dateModifier = timeEquivalent;
    }

    if (dateModifier === 0) {
      result.setDate(
        currentDate.getDate() +
          ((friday + weekLength - currentDate.getDay()) % weekLength)
      );
    } else {
      result = new Date(currentDate.getTime() - dateModifier);
    }

    return result;
  }

  get targetWeekMonday() {
    const prevMonday = new Date();
    prevMonday.setMonth(this.targetWeek.getMonth());
    prevMonday.setDate(this.targetWeek.getDate() - 4);
    return prevMonday;
  }

  draftCheck($event: boolean) {
    this.isDraft = $event;
  }

  isSaved($event: boolean) {
    this.hasBeenSaved = $event;
  }

  updateFormSelection($event: boolean) {
    this.presentFormSelected = $event;
  }

  ngOnInit() {
    this.isLoading = true;
    this.subscriptions.push(
      this.feedbackService.isAnonymousUpdated$.subscribe(
        isAnon => (this.isAnonymous = isAnon)
      )
    );
    this.route.params.subscribe((param: Params) => {
      this.feedbackId = parseInt(param['id'], 10) || this.feedbackId;
      this.feedbackTypeId =
        parseInt(param['feedbackTypeId'], 10) || this.feedbackTypeId;
      this.isFeedbackRequest = param['isFeedbackRequest'] === 'true';
    });

    this.route.parent?.url.subscribe(url => {
      this.routePostfix = url[0].path;

      if (this.routePostfix === 'selfAssessment') {
        this.selfAssessmentService
          .GetLatestSelfAssessmentSchedule()
          .subscribe(data => {
            this.latestScheduled = data;
            this.getQuestionsForSelfAssessment(this.latestScheduled.id);
          });
      } else {
        this.getQuestionsForOtherForms();
      }
    });
  }

  getQuestionsForOtherForms() {
    forkJoin([
      this.commonService.getQuestions(),
      this.commonService.getQuestionGroups(),
      this.commonService.getQuestionOptions(),
      this.commonService.getQuestionOptionGroups(),
    ]).subscribe(
      ([questions, questionGroups, questionOptions, questionOptionGroups]) => {
        this.questionGroups = [...questionGroups];
        this.questions = [...questions].filter(q =>
          questionGroups.some(g => g.id === q.groupId)
        );
        this.questionOptions = [...questionOptions];
        this.questionOptionGroups = [...questionOptionGroups];

        if (this.feedbackTypeId === undefined) {
          this.feedbackTypeId = this.getFeedbackTypeId(this.routePostfix);
        }

        const predefinedFormTypes: FormType[] = [
          this.cmFeedbackFormType,
          this.confidentialFeedbackFormType,
          this.weeklyDeliveryFormType,
          this.selfAssessmentFormType,
        ];
        this.selectedType =
          predefinedFormTypes.find(
            formType => formType.Id === this.feedbackTypeId
          ) ||
          this.formTypes.find(
            f => f.Id === this.feedbackTypeId || FeedbackTypeEnum.AdhocFeedback
          ) ||
          this.selectedType;
        // this.selectedType = this.feedbackTypeId === this.cmFeedbackFormType.Id ? this.cmFeedbackFormType :
        //     this.feedbackTypeId === this.confidentialFeedbackFormType.Id ? this.confidentialFeedbackFormType :
        //         this.feedbackTypeId === this.weeklyDeliveryFormType.Id ? this.weeklyDeliveryFormType :
        //             this.feedbackTypeId === this.selfAssessmentFormType.Id ? this.selfAssessmentFormType :
        //                 this.feedbackTypeId ? this.formTypes.find(f => f.Id === this.feedbackTypeId) :
        //                     this.formTypes.find(f => f.Id === FeedbackTypeEnum.AdhocFeedback);

        this.isUnsolicited =
          this.selectedType?.Id ===
          this.formTypes.find(f => f.Id === FeedbackTypeEnum.AdhocFeedback)?.Id;

        const group = this.questionGroups.filter(
          qg => qg.type === this.selectedType?.Id
        );
        if (group) {
          if (group.length === 1) {
            this.questionGroupIDs = [group[0].id];
            this.populateQuestionOptionGroupIDs(group[0]);
          } else if (group.length > 1) {
            if (!this.questionGroupIDs) {
              this.questionGroupIDs = [];
            }
            group.forEach(q => {
              this.questionGroupIDs.push(q.id);
              this.populateQuestionOptionGroupIDs(q);
            });
          }
        }

        this.initialSelectedType = { ...this.selectedType };
        this.createFeedbackForm(this.selectedType);
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  formatYear(date: Date) {
    const result = date.toLocaleString('default', { year: 'numeric' });
    return result;
  }

  formatDate(date: Date) {
    const result = date.toLocaleString('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
    return result;
  }

  createFeedbackForm(selection: FormType) {
    const questionGroup = this.questionGroups.filter(
      qg => qg.type.valueOf() === selection.Id
    );
    if (questionGroup) {
      this.questionGroupIDs = [];

      questionGroup.forEach(q => {
        // only push unique IDs
        if (
          this.questionGroupIDs.findIndex(question => question === q.id) < 0
        ) {
          this.questionGroupIDs.push(q.id);
        }
      });
    }

    const group: {
      [key: string]: AbstractControl<unknown, unknown>;
    } = {};
    if (
      selection.Id !== FeedbackTypeEnum.CareerMentorFeedback &&
      selection.Id !== FeedbackTypeEnum.WeeklyHealthDelivery &&
      selection.Id !== FeedbackTypeEnum.SelfAssessment &&
      selection.Id !== FeedbackTypeEnum.ConfidentialFeedback
    ) {
      group['onliner'] = new UntypedFormControl('');
      group['goals'] = new UntypedFormControl('');

      switch (selection.Id) {
        case FeedbackTypeEnum.AdhocFeedback:
          group['client'] = new UntypedFormControl('');
          group['otherClient'] = new UntypedFormControl('');
          break;

        case FeedbackTypeEnum.ThirdPartyFeedback:
          group['client'] = new UntypedFormControl('');
          group['otherClient'] = new UntypedFormControl('');
          break;

        case FeedbackTypeEnum.ClientFeedback:
          group['unsolicited'] = new UntypedFormControl('');
          break;
      }
    } else if (selection.Id === FeedbackTypeEnum.WeeklyHealthDelivery) {
      this.getMyHealthDeliveryFormSubmission(this.formatDate(this.targetWeek));
      group['onliner'] = new UntypedFormControl('');
    } else if (selection.Id === FeedbackTypeEnum.SelfAssessment) {
      this.getSelfAssessment();
      group['onliner'] = new UntypedFormControl('');
    } else if (selection.Id === FeedbackTypeEnum.ConfidentialFeedback) {
      group['onliner'] = new UntypedFormControl('');
    }

    this.questionGroupIDs.forEach(qg => {
      this.questions
        .filter(qu => qu.groupId === qg)
        .forEach(q => {
          if (q.isOptional) {
            group[`${q.id}`] = new UntypedFormControl('');
          } else {
            if (q.type.toString() === 'Text') {
              group[`${q.id}`] = new UntypedFormControl(
                '',
                formValidators.requiredAndNoWhiteSpaceValidator
              );
            } else {
              group[`${q.id}`] = new UntypedFormControl('', [
                Validators.required,
              ]);
            }
          }
          if (q.type.toString() === 'Radio_Text') {
            group[`${q.id}_text`] = new UntypedFormControl('');
          }
        });
    });

    this.feedbackForm = new UntypedFormGroup(group);
    this.selectedTypeLoaded = true;
  }

  populateQuestionOptionGroupIDs(group: QuestionGroup) {
    this.questions.forEach(q => {
      if (q.optionGroupId && q.groupId === group.id) {
        if (this.questionOptionGroupIDs.length > 0) {
          if (!this.questionOptionGroupIDs.some(qo => qo === q.optionGroupId)) {
            this.questionOptionGroupIDs.push(q.optionGroupId);
          }
        } else {
          this.questionOptionGroupIDs.push(q.optionGroupId);
        }
      }
    });
  }

  toggleThirdPartyForm = () => {
    this.isThirdParty = !this.isThirdParty;

    if (this.isThirdParty) {
      this.selectedType = this.thirdPartyFormType;
    } else {
      this.selectedType =
        this.formTypes.find(f => f.Id === this.initialSelectedType?.Id) ||
        this.selectedType;
    }
    this.createFeedbackForm(this.selectedType);
  };

  onSubmittedSelfAssessmentLinkClick() {
    this.router.navigate(['/viewYearlyAssessmentReport']);
  }

  get isLargeWindow() {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return windowWidth >= 768;
  }

  private getQuestionsForSelfAssessment(scheduleId: number) {
    if (!this.submittedForThisYear) {
      forkJoin([
        this.commonService.getQuestionsForSchedule(scheduleId),
        this.commonService.getQuestionGroups(),
        this.commonService.getQuestionOptions(),
        this.commonService.getQuestionOptionGroups(),
      ]).subscribe(
        ([
          questions,
          questionGroups,
          questionOptions,
          questionOptionGroups,
        ]) => {
          this.questionGroups = [...questionGroups];
          this.questions = [...questions].filter(q =>
            questionGroups.some(g => g.id === q.groupId)
          );
          this.questionOptions = [...questionOptions];
          this.questionOptionGroups = [...questionOptionGroups];
          this.feedbackService
            .getUserDetails()
            .subscribe((data: OnlinerPersonalInfo) => {
              this.user = data;
              this.user.startDate = new Date(data.startDate);
              const month = this.user.startDate.toLocaleString('en-US', {
                month: 'long',
              });
              this.startDate = `${month} ${this.user.startDate.getDay()}, ${this.user.startDate.getFullYear()}`;
              this.isRetreivingData = false;
            });

          if (this.feedbackTypeId === undefined) {
            this.feedbackTypeId = this.getFeedbackTypeId(this.routePostfix);
          }

          this.selectedType = this.selfAssessmentFormType;

          const group = this.questionGroups.filter(
            qg => qg.type === this.selfAssessmentFormType.Id
          );
          if (group) {
            if (group.length === 1) {
              this.questionGroupIDs = [group[0].id];
              this.populateQuestionOptionGroupIDs(group[0]);
            } else if (group.length > 1) {
              if (!this.questionGroupIDs) {
                this.questionGroupIDs = [];
              }
              group.forEach(q => {
                this.questionGroupIDs.push(q.id);
                this.populateQuestionOptionGroupIDs(q);
              });
            }
          }

          this.initialSelectedType = { ...this.selectedType };
          this.createFeedbackForm(this.selectedType);
        }
      );
    }
    this.isLoading = false;
  }

  private getFeedbackTypeId(routePostfix: string) {
    switch (routePostfix) {
      case 'cmFeedback':
        return FeedbackTypeEnum.CareerMentorFeedback;
      case 'weeklyDelivery':
        return FeedbackTypeEnum.WeeklyHealthDelivery;
      case 'selfAssessment':
        return FeedbackTypeEnum.SelfAssessment;
      case 'confidentialNotes':
        return FeedbackTypeEnum.ConfidentialFeedback;
      default:
        return undefined;
    }
  }

  private getSelfAssessment() {
    this.selfAssessmentService.CheckUserAssessment().subscribe(data => {
      this.assessment = data;
      if (this.assessment !== null) {
        this.assessment.log.forEach(a => {
          if (a.statusId === FeedbackStatusEnum.Posted) {
            if (this.latestScheduled.id === this.assessment.scheduleId) {
              this.submittedForThisYear = true; // when this is true the form does not open, it displays the corresponing message instead
            }
          }
        });
      }
    });
  }

  private getLatestSchedule() {
    this.selfAssessmentService
      .GetLatestSelfAssessmentSchedule()
      .subscribe(data => {
        this.latestScheduled = data;
      });
  }

  private getMyHealthDeliveryFormSubmission(date: string) {
    this.weeklyReportService.getWeeklyReportChecks(date).subscribe(data => {
      this.reports = data;

      if (
        this.reports[0] === null ||
        (this.reports[0].log.length === 1 &&
          this.reports[0].log[0].statusId === FeedbackStatusEnum.Draft)
      ) {
        this.submittedForThisWeek = false;
      } else {
        this.submittedForThisWeek = true;
      }
    });
  }
}

export class FormType {
  Id!: number;
  name!: string;
}
