import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { CustomLayoutComponent } from './core/custom-layout/custom-layout.component';
import { authGuard } from './core/guards/auth-guard';
import { WhdDisplayGuard } from './core/guards/whd-display.guard';
import { BlankSplashComponentComponent } from './pages/blank-splash-component/blank-splash-component.component';
import { roles } from './shared/models/roles.model';

export const childrenRoutes: VexRoutes = [
  // Home
  {
    path: 'home',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import('./features/home/dashboard/dashboard.module').then(
        m => m.DashboardModule
      ),
  },
  // Provide -> Feedback
  {
    path: 'feedback',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import('./features/feedback/feedback/feedback.module').then(
        m => m.FeedbackModule
      ),
  },
  {
    path: 'feedback/:id',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import('./features/feedback/feedback/feedback.module').then(
        m => m.FeedbackModule
      ),
  },
  // Provide -> CM Feedback
  {
    path: 'cmFeedback',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import('./features/feedback/feedback/feedback.module').then(
        m => m.FeedbackModule
      ),
  },
  {
    path: 'cmFeedback/:id',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import('./features/feedback/feedback/feedback.module').then(
        m => m.FeedbackModule
      ),
  },
  // Provide -> Confidential Notes
  {
    path: 'confidentialNotes',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.lead] },
    loadChildren: () =>
      import('./features/feedback/feedback/feedback.module').then(
        m => m.FeedbackModule
      ),
  },
  {
    path: 'confidentialNotes/:id',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.lead] },
    loadChildren: () =>
      import('./features/feedback/feedback/feedback.module').then(
        m => m.FeedbackModule
      ),
  },
  // My Surveys -> Weekly PulseCheck
  {
    path: 'weeklyDelivery',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard, WhdDisplayGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import('./features/feedback/feedback/feedback.module').then(
        m => m.FeedbackModule
      ),
  },
  // My Surveys -> Self Assessment
  {
    path: 'selfAssessment',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import('./features/feedback/feedback/feedback.module').then(
        m => m.FeedbackModule
      ),
  },
  // Drafts
  {
    path: 'savedFeedback',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import('./features/feedback/saved-feedback/saved-feedback.module').then(
        m => m.SavedFeedbackModule
      ),
  },
  {
    path: 'feedback/:id/:feedbackTypeId/:isFeedbackRequest',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import('./features/feedback/feedback/feedback.module').then(
        m => m.FeedbackModule
      ),
  },
  // Request -> Onliner
  {
    path: 'requestOnlinerFeedback',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import(
        './features/feedback-request/request-onliner-feedback/request-onliner-feedback.module'
      ).then(m => m.RequestOnlinerFeedbackModule),
  },
  // Request -> Client
  {
    path: 'requestClientFeedback',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import(
        './features/feedback-request/request-client-feedback/request-client-feedback.module'
      ).then(m => m.RequestClientFeedbackModule),
  },
  // Request -> Relocation
  {
    path: 'requestRemoteWorkWorkflow',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin, roles.user] },
    loadChildren: () =>
      import(
        './features/remote-work/request-remote-work-workflow/request-remote-work-workflow.module'
      ).then(m => m.RequestRemoteWorkWorkflowModule),
  },
  // Request -> Relocation Request
  {
    path: 'remoteWorkRequests/:isRequestApproval',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin, roles.user] },
    loadChildren: () =>
      import(
        './features/remote-work/remote-work-requests/remote-work-requests.module'
      ).then(m => m.RemoteWorkRequestsModule),
  },
  {
    path: 'requestRemoteWorkWorkflow/:id/:isRequestApproval',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin, roles.user] },
    loadChildren: () =>
      import(
        './features/remote-work/request-remote-work-workflow/request-remote-work-workflow.module'
      ).then(m => m.RequestRemoteWorkWorkflowModule),
  },
  // My Feedback -> Received
  {
    path: 'feedbackReceived',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import(
        './features/my-feedback/feedback-received/feedback-received.module'
      ).then(m => m.FeedbackReceivedModule),
  },
  // My Feedback -> Provided
  {
    path: 'feedbackProvided',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import(
        './features/my-feedback/feedback-provided/feedback-provided.module'
      ).then(m => m.FeedbackProvidedModule),
  },
  {
    path: 'viewFeedback/:id/:feedbackTypeId',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin, roles.cm, roles.user] },
    loadChildren: () =>
      import('./features/feedback/view-feedback/view-feedback.module').then(
        m => m.ViewFeedbackModule
      ),
  },
  // My Feedback -> Feedback Requests
  {
    path: 'feedbackRequests',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import(
        './features/my-feedback/feedback-requests/feedback-requests.module'
      ).then(m => m.FeedbackRequestsModule),
  },
  // My Feedback -> Weekly Reports
  {
    path: 'weeklyDeliveryHistory',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard, WhdDisplayGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import(
        './features/weekly-health-delivery/weekly-health-delivery-history/weekly-health-delivery-history.module'
      ).then(m => m.WeeklyHealthDeliveryHistoryModule),
  },
  {
    path: 'viewWeeklyReport/:id',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin, roles.cm, roles.user] },
    loadChildren: () =>
      import(
        './features/weekly-health-delivery/view-weekly-health-delivery-report/view-weekly-health-delivery-report.module'
      ).then(m => m.ViewWeeklyHealthDeliveryReportModule),
  },
  // My Feedback -> Self Assessments
  {
    path: 'viewYearlyAssessmentReport',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import(
        './features/self-assessment/personal-self-assessment/personal-self-assessment.module'
      ).then(m => m.PersonalSelfAssessmentModule),
  },
  {
    path: 'viewYearlyAssessmentReport/:scheduleId/:id',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import(
        './features/self-assessment/view-self-assessment-detail/view-self-assessment-detail.module'
      ).then(m => m.ViewSelfAssessmentDetailModule),
  },
  // My CM Feedback -> Reveived
  {
    path: 'receivedCMFeedback',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.cm] },
    loadChildren: () =>
      import(
        './features/my-cm-feedback/cm-feedback-received/cm-feedback-received.module'
      ).then(m => m.CmFeedbackReceivedModule),
  },
  // My CM Feedback -> Provided
  {
    path: 'providedCMFeedback',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.user] },
    loadChildren: () =>
      import(
        './features/my-cm-feedback/cm-feedback-provided/cm-feedback-provided.module'
      ).then(m => m.CmFeedbackProvidedModule),
  },
  {
    path: 'viewCmFeedback/:id',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin, roles.cm, roles.user] },
    loadChildren: () =>
      import(
        './features/my-cm-feedback/view-cmfeedback/view-cmfeedback.module'
      ).then(m => m.ViewCmfeedbackModule),
  },
  // Review -> My Team Feedback
  {
    path: 'teamFeedback',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin, roles.cm, roles.lead] },
    loadChildren: () =>
      import(
        './features/review/cm-group-feedback/cm-group-feedback.module'
      ).then(m => m.CmGroupFeedbackModule),
  },
  {
    path: 'reviewFeedback/:id/:feedbackTypeId',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin, roles.cm, roles.lead] },
    loadChildren: () =>
      import('./features/review/review-feedback/review-feedback.module').then(
        m => m.ReviewFeedbackComponentModule
      ),
  },
  // Review -> My Team Surveys
  {
    path: 'teamSurvey',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin, roles.cm, roles.reviewer] },
    loadChildren: () =>
      import('./features/review/team-survey/team-survey.module').then(
        m => m.TeamSurveyModule
      ),
  },
  // Review -> Confidential Notes
  {
    path: 'reviewConfidentialNotes',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.lead] },
    loadChildren: () =>
      import(
        './features/review/confidential-feedback/confidential-feedback.module'
      ).then(m => m.ConfidentialFeedbackModule),
  },
  {
    path: 'viewConfidentialNotes/:id',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.lead] },
    loadChildren: () =>
      import(
        './features/review/view-confidential-feedback/view-confidential-feedback.module'
      ).then(m => m.ViewConfidentialFeedbackModule),
  },
  // Review -> CM Feedback
  {
    path: 'reviewableCMFeedback',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.peoplecare] },
    loadChildren: () =>
      import(
        './features/review/cm-feedback-reviewable/cm-feedback-reviewable.module'
      ).then(m => m.CmFeedbackReviewableModule),
  },
  {
    path: 'reviewCmFeedback/:id',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.peoplecare] },
    loadChildren: () =>
      import(
        './features/review/review-cmfeedback/review-cmfeedback.module'
      ).then(m => m.ReviewCmfeedbackModule),
  },
  // Review -> CM Dashboard
  {
    path: 'cmdashboard',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.peoplecare] },
    loadChildren: () =>
      import(
        './features/career-mentor/cm-survey-dashboard/cm-survey-dashboard.module'
      ).then(m => m.CmSurveyDashboardModule),
  },
  // Review -> Client Feedback - Online
  {
    path: 'clientFeedbackOnline',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.marketing] },
    loadChildren: () =>
      import(
        './features/review/client-feedback-on-online/client-feedback-on-online.module'
      ).then(m => m.ClientFeedbackOnOnlineModule),
  },

  // User Dropdown
  {
    path: 'remoteworkconfig',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin] },
    loadChildren: () =>
      import(
        './features/remote-work/remote-work-config/remote-work-config.module'
      ).then(m => m.RemoteWorkConfigModule),
  },
  {
    path: 'cmdelegation',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin] },
    loadChildren: () =>
      import(
        './features/career-mentor/cm-delegation/cm-delegation.module'
      ).then(m => m.CmDelegationModule),
  },
  {
    path: 'cmdelegationreport',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin] },
    loadChildren: () =>
      import(
        './features/career-mentor/cm-delegation-report/cm-delegation-report.module'
      ).then(m => m.CmDelegationReportModule),
  },
  {
    path: 'questionsConfig',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin] },
    loadChildren: () =>
      import('./features/questions-config/questions-config.module').then(
        m => m.QuestionsConfigModule
      ),
  },

  // misc
  {
    path: 'clientFeedback/:id',
    pathMatch: 'full',
    data: { doNotLogin: true },
    loadChildren: () =>
      import('./features/client-feedback/client-feedback.module').then(
        m => m.ClientFeedbackModule
      ),
  },
  // { path: 'selfAssessmentHistory', pathMatch: 'full', loadChildren: () => import('./features/self-assessment/self-assessment-history/self-assessment-history.module').then(m => m.SelfAssessmentHistoryModule) },

  // pages
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'admin',
    pathMatch: 'full',
    canActivate: [authGuard, MsalGuard],
    data: { roles: [roles.admin] },
    loadChildren: () =>
      import('./pages/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'forbidden',
    pathMatch: 'full',
    data: { doNotLogin: true },
    loadChildren: () =>
      import('./pages/errors/error-403/error-403.module').then(
        m => m.Error403Module
      ),
  },
  {
    path: '404',
    pathMatch: 'full',
    data: { doNotLogin: true },
    loadChildren: () =>
      import('./pages/errors/error-404/error-404.module').then(
        m => m.Error404Module
      ),
  },
  {
    path: 'formExpired',
    pathMatch: 'full',
    data: { doNotLogin: true },
    loadChildren: () =>
      import('./pages/errors/form-expired/form-expired.module').then(
        m => m.FormExpiredModule
      ),
  },
  {
    path: 'cookiePolicy',
    pathMatch: 'full',
    data: { doNotLogin: true },
    loadChildren: () =>
      import('./pages/cookie-policy/cookie-policy.module').then(
        m => m.CookiePolicyModule
      ),
  },

  // fallback
  {
    path: '**',
    loadChildren: () =>
      import('./pages/errors/error-404/error-404.module').then(
        m => m.Error404Module
      ),
    pathMatch: 'full',
    data: { doNotLogin: true },
  },
];

const routes: Routes = [
  {
    path: 'id_token',
    redirectTo: 'blank',
    pathMatch: 'full',
    data: { isIgnoreRoute: true },
  },
  {
    path: 'blank',
    component: BlankSplashComponentComponent,
    pathMatch: 'full',
  },
  {
    path: 'login-failed',
    component: BlankSplashComponentComponent,
    pathMatch: 'full',
  }, // placeholder route for login failed
  {
    path: '',
    component: CustomLayoutComponent,
    children: childrenRoutes,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
