<div class="col-lg-12 col-md-12 clearfix mb-3 row container">
  <div class="col-md-3">
    <button type="button" mat-stroked-button class="submit-btn"
      (click)="goBack()"><i class="fas fa-angle-double-left"></i> Back
    </button>
  </div>
</div>

<div *ngIf="operationInProgress !== 'loading' && feedback && !mismatchedIDs">
  <div
    class="feedback-detail-container col-sm-12 col-xs-12 feedback-form-div pb-1">
    <div class="col-md-12 text-right pr-0"
      *ngIf="operationInProgress !== 'loading' && feedback && feedback.isAnonymous">
      <i class="fas fa-eye-slash"></i>
      <span class="col-md-4">Anonymous</span>
    </div>
    <div style="height: 20px;"></div>
    <div class="form-group row">
      <div class="col-md-6">
        <span class="label-header">Submission Date:</span>
        <span class="col-md-4">{{feedback.submittedDate | date:'MM/dd/yyyy'}}</span>
      </div>
      <div class="col-md-6">
        <span class="label-header">Status:</span>
        <span class="col-md-8">{{getFeedbackStatusEnum(feedback.currentStatusId)}}</span>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <span class="label-header">Career Mentor:</span>
        <span class="col-md-8">{{feedback.employeeName}}</span>
      </div>
      <div class="col-md-6"
        *ngIf="!(isFeedbackReceived && feedback.isAnonymous)">
        <span class="label-header">Submission By:</span>
        <span class="col-md-8">{{feedback.submittedByName}}</span>
      </div>
    </div>

    <div *ngFor="let answer of feedback.answers">
      <div *ngIf="answer">
        <div *ngIf="!isFeedbackReceived; else showReceivedDetail">
          <span class="label-header"> {{answer.question.question}}
          </span>
          <mat-card appearance="outlined">
            <mat-card-content>
              <div *ngIf="answer.question.type.toString() === 'Radio'">
                <div class="row form-group col-md-12 col-sm-10 col-xs-12">
                  <mat-radio-group [disabled]="true">
                    <mat-radio-button
                      *ngFor="let option of validQuestionOptions(answer.question)"
                      [value]="option.id"
                      [checked]="answer.optionId === option.id">
                      <span class="radio-rap">{{ optionDisplay(option) }}</span>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div *ngIf="answer.question.type.toString() === 'Scale'">
                <mat-slider
                  color="secondary"
                  showTickMarks
                  [disabled]="true"
                  [max]="getIntervalMax(answer.question)"
                  [min]="getIntervalMin(answer.question)"
                  [step]="getSliderTickInterval(answer.question)">
                  <input
                    matSliderThumb
                    [value]="answer.intValue" />
                </mat-slider>
                <div class="flex-display">
                  <span style="font-size:large"><strong>{{displayOptionDescription(answer)}}</strong></span>
                </div>
              </div>
              <div *ngIf="answer.question.type.toString() === 'Select'">
                <div class="row form-group m-0 mt-2">
                  <span style="white-space: pre-line"> {{answer.option?.value}}
                  </span>
                </div>
              </div>
              <div *ngIf="answer.question.type.toString() === 'Text'">
                <div class="row form-group m-0 mt-2">
                  <span style="white-space: pre-line">
                    {{htmlDecode(answer.stringValue || '')}} </span>
                </div>
              </div>
              <div *ngIf="answer.question.type.toString() === 'Radio_Text'">
                <div class="row form-group col-md-12 col-sm-10 col-xs-12">
                  <mat-radio-group [disabled]="true">
                    <mat-radio-button
                      *ngFor="let option of validQuestionOptions(answer.question)"
                      [value]="option.id"
                      [checked]="answer.optionId === option.id">
                      <span class="radio-rap">{{ optionDisplay(option) }}</span>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="row form-group m-0 mt-2">
                  <span style="white-space: pre-line">
                    {{htmlDecode(answer.stringValue || '')}} </span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <div style="height: 30px;"></div>
        </div>
        <ng-template #showReceivedDetail>
          <div *ngIf="answer.question.type.toString() === 'Text'">
            <span class="label-header"> {{answer.question.question}}
            </span>
            <mat-card appearance="outlined">
              <mat-card-content>
                <div class="row form-group m-0 mt-2">
                  <span style="white-space: pre-line">
                    {{htmlDecode(answer.stringValue || '')}} </span>
                </div>
              </mat-card-content>
            </mat-card>
            <div style="height: 30px;"></div>
          </div>
        </ng-template>
      </div>
    </div>

    <div *ngIf="peopleCareComments">
      <span class="label-header">People Care Comments</span>
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="row form-group m-0 mt-2">
            <span style="white-space: pre-line">{{peopleCareComments}}</span>
          </div>
        </mat-card-content>
      </mat-card>
      <div style="height: 30px;"></div>
    </div>
  </div>
</div>

<div *ngIf="operationInProgress === 'loading'" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="mismatchedIDs">
</div>
