<div class="row m-0">
  <div class="component-container">
    <app-feedback-detail [feedback]="feedback" [feedbackTypeIdParam]="feedbackTypeId" [operationInProgress]="operationInProgress"></app-feedback-detail>
    <div *ifRole="cmRole + ',' + adminRole">
      <div *ngIf="showActionPanel" class="row form-group pt-1 feedback-detail-container">
        <div class="feedback-detail-container col-sm-12 col-xs-12 pb-3">
          <button *ifRole="adminRole" type="button" mat-stroked-button color="warn" class="submit-btn ml-3 mr-3" (click)="unpostFeedback()" [disabled]="operationInProgress !== null || feedback.currentStatusId !== postedStatusId">
            <span *ngIf="operationInProgress === 'unposting'"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>UNPOST
          </button>
          <button *ngIf="!isClientFeedback && !isCIAFeedback" type="button" mat-stroked-button color="warn" class="submit-btn mr-3" (click)="reviseFeedback()" [disabled]="operationInProgress !== null || feedback.currentStatusId !== pendingStatusId">
            <span *ngIf="operationInProgress === 'revising'"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span> REVISE
          </button>
          <button type="button" mat-stroked-button class="submit-btn" color="primary" (click)="postFeedback()" [disabled]="operationInProgress !== null || feedback.currentStatusId !== pendingStatusId">
            <span *ngIf="operationInProgress === 'posting'"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>POST
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
