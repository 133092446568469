import { Injectable } from '@angular/core';
import { Observable, Subject, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  FeedbackStatusEnum,
  QuestionGroupType,
  QuestionTypeEnum,
} from 'src/app/shared/enums/enums.model';
import { ApiErrorData } from 'src/app/shared/interfaces/api-error.interface';
import { OnlinerPersonalInfo } from 'src/app/shared/models/onliner-personal-info.model';
import { MatchType } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import {
  FbAnswer,
  FbQuestion,
  SavedAssessment,
  SavedFbConfidential,
} from './assessment.service';
import { AuthorizedHttp } from './authorized-http.service';
import { Client, SelectListItem } from './common.service';
import { Employee } from './onliner.service';
import {
  FbQuestionOption,
  FbSelfAssessmentSchedule,
} from './self-assessment.service';
import { FbWeeklyReport } from './weekly-report-service';

@Injectable()
export class FeedbackService {
  public _allTeamSurveys!: SelfAssessmentDto[];

  private _apiEndpoint = `${environment.apiEndpoint}/api/Feedback`;

  private myTeamFeedbacks!: SavedAssessment[] | null;
  private allReviewableFeedbacks!: SavedAssessment[] | null;
  private _myReceivedCmFeedbackSummary!: CMFeedbackReceivedSummary;
  private allReviewableCmFeedbacks!: SavedAssessment[] | null;

  private _providedFeedbackStatuses!: FeedbackStatus[];
  private _additionalFeedbackStatuses!: FeedbackStatus[];
  private _cmFeedbackQuestionsAndOptions!: CmFeedbackQuestionsAndOptions;

  private _allSurveys!: SelfAssessmentDto[];
  private _allMyTeamSurveys!: SelfAssessmentDto[];

  private feedbackSubmittedSource = new Subject<void>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public feedbackSubmitted$ = this.feedbackSubmittedSource.asObservable();

  private updatedIsAnonymous = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isAnonymousUpdated$ = this.updatedIsAnonymous.asObservable();

  private updatedIsAway = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isAwayUpdated$ = this.updatedIsAway.asObservable();

  constructor(private http: AuthorizedHttp) {}

  public feedbackSubmitted = () => this.feedbackSubmittedSource.next();

  public isAnonymousUpdated = (isAnonymous: boolean) =>
    this.updatedIsAnonymous.next(isAnonymous);

  public isAwayUpdated = (isAway: boolean) => this.updatedIsAway.next(isAway);

  public getDrafts(): Observable<SavedAssessment[]> {
    return this.http
      .get<SavedAssessment[]>(`${this._apiEndpoint}/savedFeedbacks`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getConfidentialDrafts(): Observable<SavedFbConfidential[]> {
    return this.http
      .get<SavedFbConfidential[]>(`${this._apiEndpoint}/savedConfidential`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getAllConfidentialFeedback(
    startDate: string,
    endDate: string
  ): Observable<SavedFbConfidential[]> {
    return this.http
      .get<SavedFbConfidential[]>(
        `${this._apiEndpoint}/getAllConfidentialFeedback/${startDate}/${endDate}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getConfidentialFeedback(
    feedbackId: number,
    isDraft: boolean
  ): Observable<SavedFbConfidential> {
    return this.http
      .get<SavedFbConfidential>(
        `${this._apiEndpoint}/getConfidentialFeedback/${feedbackId}/${isDraft}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getCmFeedbackQuestionsAndOptions(): Observable<CmFeedbackQuestionsAndOptions> {
    return this._cmFeedbackQuestionsAndOptions
      ? of(this._cmFeedbackQuestionsAndOptions)
      : this.http
          .get<CmFeedbackQuestionsAndOptions>(
            `${this._apiEndpoint}/cmFeedbackQuestionsAndOptions`
          )
          .pipe(
            map(result => {
              this._cmFeedbackQuestionsAndOptions = result;
              return this._cmFeedbackQuestionsAndOptions;
            })
          );
  }

  public async GetCurrentFiscalYear(): Promise<
    FbSelfAssessmentSchedule | undefined
  > {
    const response = this.http
      .get<FbSelfAssessmentSchedule>(
        `${this._apiEndpoint}/GetCurrentFiscalYear`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      )
      .toPromise();
    const result = await response;
    return result;
  }

  public getProvidedFeedackStatuses(): Observable<FeedbackStatus[]> {
    return this._providedFeedbackStatuses
      ? of(this._providedFeedbackStatuses)
      : this.http
          .get<FeedbackStatus[]>(
            `${this._apiEndpoint}/filterableFeedbackStatuses`
          )
          .pipe(
            map(result => {
              this._providedFeedbackStatuses = result;
              this._providedFeedbackStatuses.unshift(defaultFeedbackStatus);
              return this._providedFeedbackStatuses;
            }),
            catchError((e: ApiErrorData) =>
              throwError(
                e.error.exceptionMessage || 'Server error (feedback status)'
              )
            )
          );
  }

  public getAdditionalProvidedFeedackStatuses(): Observable<FeedbackStatus[]> {
    return this._additionalFeedbackStatuses
      ? of(this._additionalFeedbackStatuses)
      : this.http
          .get<FeedbackStatus[]>(
            `${this._apiEndpoint}/additionalFilterableFeedbackStatuses`
          )
          .pipe(
            map(result => {
              this._additionalFeedbackStatuses = result;
              this._additionalFeedbackStatuses.unshift(defaultFeedbackStatus);
              return this._additionalFeedbackStatuses;
            }),
            catchError((e: ApiErrorData) =>
              throwError(
                e.error.exceptionMessage || 'Server error (feedback status)'
              )
            )
          );
  }

  // public saveCMFeedback(cmFeedback: Object) {
  //     return this.http
  //         .post(`${this._apiEndpoint}/saveCMFeedback`, cmFeedback)
  //         .pipe(
  //             catchError((errorResponse: ApiErrorData) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
  //         );
  // }

  // public submitCMFeedback(feedback: Object) {
  //     return this.http
  //         .post(`${this._apiEndpoint}/submitCMFeedback`, feedback)
  //         .pipe(
  //             map((feedback) => {
  //                 this.resetRetrievedDbFeedback();
  //                 return feedback;
  //             }),
  //             catchError((errorResponse: ApiErrorData) => throwError(errorResponse.error.exceptionMessage || 'Server error'))
  //         );
  // }

  public getMyTeamFeedbacks(
    startDate: string,
    endDate: string
  ): Observable<SavedAssessment[]> {
    return this.http
      .get<SavedAssessment[]>(
        `${this._apiEndpoint}/myTeamFeedbacks/${startDate}/${endDate}`
      )
      .pipe(
        map(
          this.mapSavedFeedbacks(results => (this.myTeamFeedbacks = results))
        ),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getAllSurveys(): Observable<SelfAssessmentDto[]> {
    return this._allSurveys
      ? of(this._allSurveys)
      : this.http
          .get<SelfAssessmentDto[]>(`${this._apiEndpoint}/allSurveys`)
          .pipe(
            map(results => {
              this._allSurveys = results;
              return this._allSurveys;
            }),
            catchError((errorResponse: ApiErrorData) =>
              throwError(errorResponse.error.exceptionMessage || 'Server error')
            )
          );
  }

  public getAllTeamSurveys(
    scheduleId?: number
  ): Observable<SelfAssessmentDto[]> {
    return this.http
      .get<SelfAssessmentDto[]>(
        `${this._apiEndpoint}/allTeamSurveys/${scheduleId}`
      )
      .pipe(
        map(results => {
          this._allTeamSurveys = results;
          return this._allTeamSurveys;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getMyTeamSurveys(): Observable<SelfAssessmentDto[]> {
    return this._allMyTeamSurveys
      ? of(this._allMyTeamSurveys)
      : this.http
          .get<SelfAssessmentDto[]>(`${this._apiEndpoint}/myTeamSurveys`)
          .pipe(
            map(results => {
              this._allMyTeamSurveys = results;
              return this._allMyTeamSurveys;
            }),
            catchError((errorResponse: ApiErrorData) =>
              throwError(errorResponse.error.exceptionMessage || 'Server error')
            )
          );
  }

  public getAllReviewableFeedbacks(
    startDate: string,
    endDate: string
  ): Observable<SavedAssessment[]> {
    return this.http
      .get<SavedAssessment[]>(
        `${this._apiEndpoint}/allReviewableFeedbacks/${startDate}/${endDate}`
      )
      .pipe(
        map(
          this.mapSavedFeedbacks(
            results => (this.allReviewableFeedbacks = results)
          )
        ),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getAllReviewableCmFeedbacks(): Observable<SavedAssessment[]> {
    return this.allReviewableCmFeedbacks
      ? of(this.allReviewableCmFeedbacks)
      : this.http
          .get<SavedAssessment[]>(
            `${this._apiEndpoint}/allReviewableCmFeedbacks`
          )
          .pipe(
            map(
              this.mapCMSavedFeedbacks(
                results => (this.allReviewableCmFeedbacks = results)
              )
            ),
            catchError((errorResponse: ApiErrorData) =>
              throwError(errorResponse.error.exceptionMessage || 'Server error')
            )
          );
  }

  public updateMyTeamFeebackStatus(feedbackId: number, statusID: number) {
    // add new row to the audit table!
    let feedbackToUpdate: SavedAssessment;
    let index: number;

    if (this.myTeamFeedbacks) {
      index = this.myTeamFeedbacks.findIndex(f => f.id === feedbackId);
      feedbackToUpdate = this.myTeamFeedbacks[index];

      // feedbackToUpdate.log.sort((a,b) => (a.changedDate > b.changedDate) ? 1 : ((b.changedDate > a.changedDate) ? -1 : 0))[0].description = status;
      feedbackToUpdate.log.sort((a, b) =>
        a.changedDate < b.changedDate
          ? 1
          : b.changedDate < a.changedDate
          ? -1
          : 0
      )[0].statusId = statusID;

      this.myTeamFeedbacks[index].log = [...feedbackToUpdate.log];

      feedbackToUpdate.currentStatus = FeedbackStatusEnum[statusID];
    }

    if (this.allReviewableFeedbacks) {
      index = this.allReviewableFeedbacks.findIndex(f => f.id === feedbackId);
      feedbackToUpdate = this.allReviewableFeedbacks[index];

      // feedbackToUpdate.log.sort((a,b) => (a.changedDate > b.changedDate) ? 1 : ((b.changedDate > a.changedDate) ? -1 : 0))[0].description = status;
      feedbackToUpdate.log.sort((a, b) =>
        a.changedDate < b.changedDate
          ? 1
          : b.changedDate < a.changedDate
          ? -1
          : 0
      )[0].statusId = statusID;

      this.allReviewableFeedbacks[index].log = [...feedbackToUpdate.log];

      feedbackToUpdate.currentStatus = FeedbackStatusEnum[statusID];
    }
  }

  public updateReviewableCmFeedbackStatus(
    feedbackId: number,
    statusId: number
  ) {
    let feedbackToUpdate: SavedAssessment;
    let index: number;

    if (this.allReviewableCmFeedbacks) {
      index = this.allReviewableCmFeedbacks.findIndex(f => f.id === feedbackId);
      feedbackToUpdate = this.allReviewableCmFeedbacks[index];

      feedbackToUpdate.log.sort((a, b) =>
        a.changedDate < b.changedDate
          ? 1
          : b.changedDate < a.changedDate
          ? -1
          : 0
      )[0].statusId = statusId;

      this.allReviewableCmFeedbacks[index].log = [...feedbackToUpdate.log];

      feedbackToUpdate.currentStatus = FeedbackStatusEnum[statusId];
    }
  }

  public removeSavedFromMyTeamFeedback(feedbackId: number, isAdmin: boolean) {
    const feedbackListToUpdate: SavedAssessment[] | null = isAdmin
      ? this.allReviewableFeedbacks
      : this.myTeamFeedbacks;

    if (!feedbackListToUpdate) {
      throw new Error('Error: allReviewableFeedbacks are empty.');
    }

    const index = feedbackListToUpdate.findIndex(
      feedback => feedback.id === feedbackId
    );
    if (feedbackListToUpdate && index !== -1) {
      feedbackListToUpdate.splice(index, 1);
    }
  }

  public removeSavedFromReviewableCmFeedback(feedbackId: number) {
    if (!this.allReviewableCmFeedbacks) {
      throw new Error('Error: all reviewable cm feedbacks are empty.');
    }

    const index = this.allReviewableCmFeedbacks.findIndex(
      feedback => feedback.id === feedbackId
    );
    if (index !== -1) {
      this.allReviewableCmFeedbacks.splice(index, 1);
    }
  }

  public getMyReceivedCmFeedbacks(
    year: number
  ): Observable<CMFeedbackReceivedSummary> {
    return this.http
      .get<CMFeedbackReceivedSummary>(
        `${this._apiEndpoint}/myReceivedCmFeedbacks/${year}`
      )
      .pipe(
        map(result => {
          this._myReceivedCmFeedbackSummary = result;
          return this._myReceivedCmFeedbackSummary;
        }),
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getMyActiveFeedbacks(): Observable<ActiveFeedbackDetails> {
    return this.http
      .get<ActiveFeedbackDetails>(`${this._apiEndpoint}/myActiveFeedbacks`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getMyTeamActiveFeedbacks(): Observable<ActiveFeedbackDetails> {
    return this.http
      .get<ActiveFeedbackDetails>(`${this._apiEndpoint}/myTeamActiveFeedbacks`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getMyHealthDeliveryFormSubmission(
    targetDate: string
  ): Observable<FbWeeklyReport[]> {
    return this.http
      .get<FbWeeklyReport[]>(`${this._apiEndpoint}/checkReports/${targetDate}`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getAllActiveFeedbacks(): Observable<ActiveFeedbackDetails> {
    return this.http
      .get<ActiveFeedbackDetails>(`${this._apiEndpoint}/allActiveFeedbacks`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getmyPendingFeedbackRequests(): Observable<ActiveFeedbackDetails> {
    return this.http
      .get<ActiveFeedbackDetails>(
        `${this._apiEndpoint}/myPendingFeedbackRequests`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getUserDetails(): Observable<OnlinerPersonalInfo> {
    return this.http
      .get<OnlinerPersonalInfo>(`${this._apiEndpoint}/getUserDetails`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getCompetencies(): Observable<CompetencyDto[]> {
    return this.http
      .get<CompetencyDto[]>(`${this._apiEndpoint}/getCompetencies`)
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }

  public getSubmitterDetails(userId: string): Observable<OnlinerPersonalInfo> {
    return this.http
      .get<OnlinerPersonalInfo>(
        `${this._apiEndpoint}/getSubmitterDetails/${userId}`
      )
      .pipe(
        catchError((errorResponse: ApiErrorData) =>
          throwError(errorResponse.error.exceptionMessage || 'Server error')
        )
      );
  }
  private resetRetrievedDbFeedback = () => {
    this.myTeamFeedbacks = null;
    this.allReviewableFeedbacks = null;
    this.allReviewableCmFeedbacks = null;
  };

  private mapSavedFeedbacks(
    assignResults: (feedbacks: SavedAssessment[]) => void
  ) {
    return (data: SavedAssessment[]) => {
      const results: SavedAssessment[] = MatchType.MatchDataArray(
        data,
        SavedAssessment
      );
      results.forEach((feedback: SavedAssessment) => {
        feedback.RemoveTimePartFromDate();
      });
      assignResults(results);
      return results;
    };
  }

  private mapCMSavedFeedbacks(
    assignResults: (feedbacks: SavedAssessment[]) => void
  ) {
    return (data: SavedAssessment[]) => {
      const results: SavedAssessment[] = MatchType.MatchDataArray(
        data,
        SavedAssessment
      );
      results.forEach((feedback: SavedAssessment) => {
        feedback.RemoveTimePartFromDate();
        const answers = MatchType.MatchDataArray(feedback.answers, FbAnswer);
        feedback.answers = answers;
      });
      assignResults(results);
      return results;
    };
  }
}

export const defaultClient: Client = { clientId: -1, clientName: 'All' };
export const defaultFeedbackType: FeedbackType = {
  feedbackTypeId: 0,
  typeDescr: 'All',
  onlineTeamId: null,
};
export const defaultFeedbackStatus: FeedbackStatus = {
  statusId: -1,
  statusDescription: 'All',
};
export const defaultPendingFeedbackStatus: FeedbackStatus = {
  statusId: 1,
  statusDescription: 'Pending',
};
export const defaultDraftFeedbackStatus: FeedbackStatus = {
  statusId: 3,
  statusDescription: 'Draft',
};
export const defaultSubmissionType: SelectListItem = { name: 'All', id: 0 };
export const defaultAnonymousOption: AnonymousOption = {
  value: undefined,
  anonymousDesc: 'Show All',
};
export const defaultCompetency: CompetencyDto = {
  competencyId: -1,
  name: 'All',
};

export class CultureInAction {
  feedbackId!: number;
  employeeId!: string;
  clientId!: number;
  otherClient!: string;
  recognition!: string;
  areaForFocus!: string;
  submissionBy!: string;
  submissionDate!: Date;
  statusId!: number;
  statusChangedBy!: string;
  statusChangedDate!: Date;
  dateDiscussed!: Date;
  reasonForReturn!: string;
  requestId!: number;
  feedbackTypeId!: number;
  clientRequestId!: number;
}

export class CompetencyDto {
  competencyId!: number;
  name!: string;
}

export class ActiveFeedbackDetails {
  pendingCount!: number;
  savedCount!: number;
}

export class LegacyFeedbackComment {
  commentId!: number;
  feedbackId!: number;
  commentType!: CommentType;
  strengthsAccomplishments!: string;
  areasForDevelopment!: string;
  additionalItems!: string;
  clientResponsibilities!: string;
  clientAccomplishments!: string;
  corpContributions!: string;
  corpAreaForDevelopment!: string;
  corpObjectives!: string;
}

export class FeedbackType {
  feedbackTypeId!: number;
  typeDescr!: string;
  onlineTeamId?: number | null;
}

export class SubmissionTypeDetail {
  submissionTypeId!: number;
  hasRequest!: Date;
  hasClientRequestUserId!: boolean;
  hasRecognitionCia!: boolean;
  hasRecognitionFeedback!: boolean;
}

export class FeedbackStatus {
  statusId!: number;
  statusDescription!: string;
}

export class CommentType {
  commentTypeId!: number;
  commentTypeDescr!: string;
}

export class AnonymousOption {
  value?: boolean;
  anonymousDesc!: string;
}

export class FeedbackAnswer {
  id!: number;
  // feedbackId!: number;
  questionId!: number;
  question!: Question;
  intValue?: number | null;
  stringValue!: string;
  optionId?: number | null;
  option?: QuestionOption | null;

  // isShortOptionGroup() {
  //   return (
  //     this.option &&
  //     [
  //       'CF_Always-Never',
  //       'CF_Satisfied-Dissatisfied',
  //       'OF_Agree-Disagree',
  //     ].includes(this.option.groupLabel)
  //   );
  // }

  // isExtraShortOptionGroup() {
  //   return this.option && ['CM_yes-no'].includes(this.option.groupLabel);
  // }
}

export class CMFeedbackAnswer {
  cmFeedbackAnswerId!: number;
  cmFeedbackId!: number;
  questionId!: number;
  intValue!: number;
  stringValue!: string;
  optionId!: number;
  isAnonymous!: boolean;
}

export class CMFeedback {
  cmFeedbackId!: number;
  careerMentor!: string;
  submissionBy!: string;
  submissionDate!: Date;
  statusId!: number;
  statusChangedBy!: string;
  statusChangedDate!: Date;
  isAnonymous!: boolean;
  answers!: CMFeedbackAnswer[];

  RemoveTimePartFromDate(): void {
    const date = new Date(this.submissionDate);
    this.submissionDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
}

export class CMSavedFeedback {
  cmFeedbackId!: number;
  careerMentor!: string; // ID fk to Employees
  careerMentorName!: string;
  submissionBy!: string; // ID fk to Employees
  submissionByName!: string;
  submissionDate!: Date;
  statusID!: FeedbackStatusEnum; // ID fk to Status
  statusChangedBy!: string; // ID fk to Employees
  statusChangedByName!: string;
  statusChangedDate!: Date;
  isAnonymous!: boolean;
  answers!: FeedbackAnswer[];

  // to have a good sorting in UI
  RemoveTimePartFromDate(): void {
    const date = new Date(this.submissionDate);
    this.submissionDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
}

export class CmFeedbackQuestionsAndOptions {
  questions!: Question[];
  options!: QuestionOption[];
}

export class Question {
  question!: string;
  id!: number;
  isOptional!: boolean;
  isActive!: boolean;
  sortOrder!: number;
  type!: QuestionTypeEnum;
  groupId!: number;
  groupSortOrder!: number;
  groupType?: QuestionGroupType;
  optionGroupId?: number | null;
  optionGroup?: QuestionOptionGroup | null;
  questionEnum!: string;
  reviewerQuestion!: ReviewerQuestion;
  charLimit?: number;
}

export class ReviewerQuestion {
  questionId!: number;
  question!: string;
  isOptional!: boolean;
  type!: QuestionTypeEnum;
  groupId!: number;
  optionGroupId!: number;
  optionGroup!: QuestionOptionGroup;
  questionEnum!: string;
  charLimit!: number;
}

export class QuestionOption {
  id!: number;
  isActive!: boolean;
  value!: string;
  groupId!: number;
  sortOrder!: number;
  score!: number;
  groupLabel!: string;
}

export class QuestionGroup {
  id!: number;
  isActive!: boolean;
  sortOrder!: number;
  name!: string;
  type!: QuestionGroupType;
  questions!: Question[];
}

export class QuestionOptionGroup {
  id!: number;
  label!: string;
  options!: QuestionOption[];
}

export class CMFeedbackReceivedSummary {
  year!: number;
  quantitativeData!: CmFeedbackQuantitativeData[];
  qualitativeQuestions!: FbQuestion[];
  cmFeedbacks!: SavedAssessment[];
}

export class CmFeedbackQuantitativeData {
  optionGroupId!: number;
  maxGroupScore!: number;
  questionId!: number;
  question!: string;
  averageScore!: number;
  numScores!: number;
}

export class SelfAssessmentDto {
  id!: number;
  answers!: FbSelfAssessmentAnswer[];
  submittedByEmployee!: Employee;
  submittedBy!: string;
  submittedDate!: Date;
  log!: FbSelfAssessmentAudit[];
  careerMentor!: string;
  careerMentorName!: string;
  practice!: string;
  competencyId!: number;
  competencyName!: string;
  onliner!: string;
  fiscalYear!: number;
  assesmentAuditStatus!: string;
  actualReviewerId!: string;
  actualReviewerName!: string;
  reviewDate!: Date;
  scheduleId!: number;
}

export class FbSelfAssessmentAnswer {
  id!: number;
  questionId!: number;
  question!: Question;
  intValue!: number;
  stringValue!: string;
  optionId!: number;
  option!: FbQuestionOption;
}

export class FbSelfAssessmentAudit {
  id!: number;
  statusId!: number;
  changedByEmployee!: Employee;
  changedBy!: string;
  changedDate!: Date;
  description!: string;
}

export class filterFeedbackDates {
  startDate!: Date;
  endDate!: Date;
}

// function lastValueFrom(response: Observable<any>) {
//     throw new Error('Function not implemented.');
// }
