import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AssessmentService,
  SavedAssessment,
} from 'src/app/core/services/assessment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { FeedbackReviewService } from 'src/app/core/services/feedback-review.service';
import { FeedbackService } from 'src/app/core/services/feedback.service';
import { SnackBarService } from 'src/app/core/services/snackbar.service';
import {
  CommentDialog,
  CommentDialogComponent,
} from 'src/app/shared/components/comment-dialog/comment-dialog.component';
import {
  FeedbackStatusEnum,
  FeedbackTypeEnum,
} from 'src/app/shared/enums/enums.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-review-feedback',
  templateUrl: './review-feedback.component.html',
  styleUrls: ['./review-feedback.component.scss'],
})
export class ReviewFeedbackComponent implements OnInit {
  feedbackId!: number;
  feedbackTypeId!: number;

  feedback!: SavedAssessment;
  operationInProgress: string | null = null;
  showActionPanel = true;
  isLegacyFeedback!: boolean;
  isClientFeedback!: boolean;
  isCIAFeedback!: boolean;

  adminRole: string = environment.roles.FeedbackAdmin;
  cmRole: string = environment.roles.FeedbackCm;
  userRole: string = environment.roles.FeedbackUser;

  isAdmin!: boolean;

  pendingStatusId: number = FeedbackStatusEnum.Pending;
  postedStatusId: number = FeedbackStatusEnum.Posted;

  constructor(
    private feedbackReviewService: FeedbackReviewService,
    private feedbackService: FeedbackService,
    private assessmentService: AssessmentService,
    private snackBarService: SnackBarService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.isAdmin = this.authService.doesUserHaveRole([this.adminRole]);

    this.route.params.subscribe(params => {
      this.feedbackId = +params['id'];
      this.feedbackTypeId = +params['feedbackTypeId'];

      this.isClientFeedback =
        this.feedbackTypeId === FeedbackTypeEnum.ClientFeedback;
      this.isCIAFeedback =
        this.feedbackTypeId === FeedbackTypeEnum.CultureInAction;

      this.operationInProgress = 'loading';
      this.isLegacyFeedback =
        this.feedbackId !== null &&
        this.feedbackTypeId !== null &&
        this.feedbackTypeId !== 0 &&
        this.feedbackTypeId !== FeedbackTypeEnum.AdhocFeedback &&
        this.feedbackTypeId !== FeedbackTypeEnum.ClientFeedback &&
        this.feedbackTypeId !== FeedbackTypeEnum.CultureInAction;

      this.getFeedbackDetail(this.feedbackId);
    });
  }

  // might not be using legacy data anymore.....
  // private getLegacyFeedbackDetail(feedbackId) {
  // this.feedbackService.getLegacyFeedbackDetail(feedbackId)
  //   .subscribe(data => {
  //     this.feedback = data;
  //     this.operationInProgress = null;
  //   }, error => {
  //     this.snackBarService.error(error);
  //     this.operationInProgress = null;
  //   });
  // }

  postFeedback() {
    const gatherCommentsDialog = new CommentDialog();

    gatherCommentsDialog.title = 'Post Feedback';
    gatherCommentsDialog.message = 'CM Comments (optional)';
    gatherCommentsDialog.isEmptyInputAllowed = true;
    gatherCommentsDialog.okButtonTitle = 'Submit';
    gatherCommentsDialog.cancelButtonTitle = 'Cancel';

    const dialogRef: MatDialogRef<CommentDialogComponent, CommentDialog> =
      this.dialog.open<CommentDialogComponent, CommentDialog>(
        CommentDialogComponent,
        {
          width: '400px',
          height: '350px',
          data: gatherCommentsDialog,
          disableClose: true,
        }
      );

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.performFeedbackPosting(data.reasons);
      }
    });
  }

  reviseFeedback() {
    const gatherCommentsDialog = new CommentDialog();

    gatherCommentsDialog.title = 'Revise Feedback';
    gatherCommentsDialog.message =
      'Please enter notes explaining what needs to be revised.';
    gatherCommentsDialog.okButtonTitle = 'Submit';
    gatherCommentsDialog.cancelButtonTitle = 'Cancel';

    const dialogRef = this.dialog.open(CommentDialogComponent, {
      width: '400px',
      height: '400px',
      data: gatherCommentsDialog,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.performFeedbackRevision(data.reasons);
      }
    });
  }

  unpostFeedback() {
    this.operationInProgress = 'unposting';
    this.feedbackReviewService.unpostFeedback(this.feedbackId).subscribe(
      () => {
        this.feedback.currentStatusId = FeedbackStatusEnum.Pending;
        this.feedback.currentStatus =
          FeedbackStatusEnum[this.feedback.currentStatusId];
        this.feedbackService.updateMyTeamFeebackStatus(
          this.feedback.id,
          FeedbackStatusEnum.Pending
        );
        this.operationInProgress = null;
        this.snackBarService.message(
          `Feedback from ${this.feedback.submittedByName} has been successfully unposted.`
        );
      },
      error => {
        this.operationInProgress = null;
        this.snackBarService.error(error);
      }
    );
  }

  private getFeedbackDetail(feedbackId: number) {
    this.assessmentService.getFeedbackDetail(feedbackId).subscribe(
      data => {
        this.feedback = data;
        if (this.isClientFeedback) {
          this.feedback.answers = data.answers.filter(
            a => a.question.groupId !== 3
          );
          const contactName = data.answers.find(a => a.questionId === 45);

          if (contactName !== null) {
            this.feedback.submittedByName = contactName?.stringValue ?? '';
            const ix = this.feedback.answers.findIndex(
              a => a.questionId === 45
            );
            this.feedback.answers.splice(ix, 1);
          }
        }
        this.operationInProgress = null;
      },
      error => {
        this.snackBarService.error(error);
        this.operationInProgress = null;
      }
    );
  }
  private performFeedbackPosting(cmComments: string) {
    this.operationInProgress = 'posting';
    this.feedbackReviewService
      .postFeedback(this.feedbackId, cmComments)
      .subscribe(
        () => {
          this.feedback.currentStatusId = FeedbackStatusEnum.Posted;
          this.feedback.currentStatus =
            FeedbackStatusEnum[this.feedback.currentStatusId];
          this.getFeedbackDetail(this.feedbackId);
          this.feedbackService.updateMyTeamFeebackStatus(
            this.feedback.id,
            FeedbackStatusEnum.Posted
          );
          this.operationInProgress = null;
          this.snackBarService.message(
            `Feedback from ${this.feedback.submittedByName} has been successfully posted and an email was sent to ${this.feedback.employeeName}. `
          );
        },
        error => {
          this.operationInProgress = null;
          this.snackBarService.error(error);
        }
      );
  }

  private performFeedbackRevision(reasons: string) {
    // grab the new info from the new row
    this.operationInProgress = 'revising';
    this.feedbackReviewService
      .reviseFeedback(this.feedbackId, reasons)
      .subscribe(
        () => {
          this.feedback.currentStatusId = FeedbackStatusEnum.Draft;
          this.feedbackService.removeSavedFromMyTeamFeedback(
            this.feedback.id,
            this.isAdmin
          );
          this.snackBarService.message(
            `Feedback for ${this.feedback.employeeName} from ${this.feedback.submittedByName} has been sent back for revision. An email was sent to ${this.feedback.submittedByName}. `
          );
          this.operationInProgress = null;
          this.router.navigate(['teamFeedback']);
        },
        error => {
          this.operationInProgress = null;
          this.snackBarService.error(error);
        }
      );
  }
}
