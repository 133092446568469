<div class="col-lg-12 col-md-12 clearfix mb-3">
  <div class="pull-right-on-lg-screen">
    <button type="button" mat-stroked-button class="submit-btn"
      (click)="goBack()"><i class="fas fa-angle-double-left"></i> Back
    </button>
  </div>
</div>

<div *ngIf="operationInProgress !== 'loading' && feedback && !mismatchedIDs">
  <div class="feedback-detail-container col-sm-12 col-xs-12 pb-1">
    <div class="form-group row">
      <div class="col-md-6">
        <span class="label-header">Submission Date:</span>
        <span class="col-md-4">{{feedback.submittedDate | date:'MM/dd/yyyy'}}</span>
      </div>
      <div class="col-md-6">
        <span class="label-header">Status:</span>
        <span class="col-md-8">{{getFeedbackStatusEnum(feedback.currentStatusId)}}</span>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <span class="label-header">Onliner:</span>
        <span class="col-md-8">{{feedback.employeeName}}</span>
      </div>
      <div class="col-md-6">
        <span class="label-header">Client:</span>
        <span class="col-md-8">{{feedback.clientName}}</span>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6">
        <span class="label-header">Submission By:</span>
        <div>
          <span class="col-md-8 no-pad">{{htmlDecode(feedback.submittedByName)}}</span>
        </div>
      </div>
      <div class="col-md-6">
        <span class="label-header">Feedback Type:</span>
        <span *ngIf="feedback.feedbackType" class="col-md-8">{{feedback.feedbackType}}</span>
      </div>
    </div>

    <div *ngIf="isLegacyFeedback" class="form-group row">
      <div *ngFor="let fbComment of $any(feedback)['fbComments']">
        <app-legacy-feedback-detail [legacyFeedbackComment]="fbComment"></app-legacy-feedback-detail>
      </div>
    </div>

    <div *ngIf="!isLegacyFeedback">
      <div *ngFor="let answer of feedback.answers">
        <div *ngIf="answer">
          <span class="label-header"> {{answer.question.question}}
          </span>
          <mat-card appearance="outlined">
            <mat-card-content>
              <div *ngIf="answer.question.type.toString() === 'Radio_Text'">
                <div class="row form-group m-0 mt-2">
                  <span style="white-space: pre-line">
                    {{htmlDecode(answer.option?.value || '')}}
                  </span>
                </div>
                <mat-form-field appearance="outline" *ngIf="htmlDecode(answer.stringValue || '')"
                    class="row form-group col-md-12 col-sm-10 col-xs-12 mb-0">
                    <textarea class="text" type="text" matInput [disabled]="true" [value]="htmlDecode(answer.stringValue || '')"
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="5"
                        style="overflow:hidden"></textarea>
                </mat-form-field>
              </div>
              <div *ngIf="answer.question.type.toString() !== 'Radio_Text'" class="row form-group m-0 mt-2">
                <span style="white-space: pre-line">
                  {{htmlDecode(answer.stringValue || '')}}
                </span>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div style="height: 30px;"></div>
      </div>
    </div>
    <div *ngIf="cmComments">
      <span class="label-header">CM Comments</span>
      <mat-card appearance="outlined">
        <mat-card-content>
          <div class="row form-group m-0 mt-2">
            <span style="white-space: pre-line">{{cmComments}}</span>
          </div>
        </mat-card-content>
      </mat-card>
      <div style="height: 30px;"></div>
    </div>
  </div>
</div>

<div *ngIf="operationInProgress === 'loading'" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>
