<div *ngIf="!isLoading">
  <form  *ngIf="feedbackForm" [formGroup]="feedbackForm" novalidate>
      <div class="row form-group col-md-12 col-sm-6 col-xs-12" >
        <div *ngIf="questionGroupsForThisForm">
          <div *ngFor="let questionGroup of questionGroupsForThisForm">
            <div *ngIf="questionGroup.id < 2">
              <span class="label-header mat-question-group-display">{{ displayQuestionGroup(questionGroup) }}</span>
            </div>
            <div *ngIf="questionGroup.id === 3">
              <span class="mat-dialog-sub-header my-3">Thank you for taking the time to contribute to {{onlinerName}}'s growth and development.</span>
              <hr>
              <h3 class="page-title">Feedback on Online as a Company</h3>
              <span class="label-header">We value our relationships with our Clients.
                In addition to your feedback on our team members being important to us, we value your feedback on doing business with us.</span>

              <span class="label-header">If you can spare another moment, would you please respond to any or all of these questions about Online as a company?</span>

              <div *ngIf="!onlineSelectionMade" class="row form-group">
                <button type="button" mat-stroked-button class="save-btn" (click)="openOnlineFeedback()" [disabled]="isSaving || isSubmitting">
                  OK
                </button>
                <button type="button" mat-stroked-button class="submit-btn" (click)="skipOnlineFeedback()" [disabled]="isSubmitting || isSaving">
                  Skip
                </button>
              </div>
            </div>
            <div *ngIf="questionGroup.id === 0 || questionGroup.id === 1">
              <div *ngIf="questionGroup.id === 0">
                <span class="mat-dialog-sub-header"> We place a lot of importance on Onliners living our Core Values ("The Onliner's Code") in their professional lives.
                                                    <br>Do you believe this Onliner displays them?</span>
              </div>
              <div *ngIf="questionGroup.id === 1">
                <span class="mat-dialog-sub-header">Which of the following words would you use to describe this Onliner?</span>
              </div>

              <div class="row">
                <div class="form-group col-md-3">
                  <mat-grid-list cols="1" rowHeight="80px">
                    <mat-grid-tile></mat-grid-tile>
                    <mat-grid-tile id="multi-grid-question-title" *ngFor="let q of buildRadioGrid(questionGroup.id)">
                      <span class="font-weight-bold displaySpan leftAlignQuestions" [ngClass]="{'required-label' : !q.isOptional }">{{ displayQuestion(q) }}</span>
                    </mat-grid-tile>
                  </mat-grid-list>
                </div>

                <div class="form-group col-md-9">
                  <mat-grid-list cols="{{displayedColumns(questionGroup).length}}" rowHeight="80px" >
                    <mat-grid-tile *ngFor="let c of displayedColumns(questionGroup)"><span class="font-weight-bold displaySpan centerText ">{{c.value}}</span></mat-grid-tile>
                  </mat-grid-list>

                  <mat-grid-list cols="{{displayedColumns(questionGroup).length}}" rowHeight="80px">
                    <span *ngFor="let q of buildRadioGrid(questionGroup.id)">
                      <mat-radio-group formControlName="{{q.id}}" >
                        <mat-grid-tile *ngFor="let c of displayedColumns(questionGroup)">
                          <mat-radio-button value="{{c.id}}" class="question-radio-button" (change)="questionFilled()"></mat-radio-button>
                        </mat-grid-tile>
                      </mat-radio-group>
                    </span>
                  </mat-grid-list>
                </div>
              </div>

            </div>
            <div *ngFor="let question of questionsPerGroup(questionGroup.id)">
              <span class="label-header generated-header" [ngClass]="{'required-label' : !question.isOptional }">{{ displayQuestion(question) }}<span *ngIf="question.id === 45">:&nbsp;<strong>{{ contactName }}</strong></span></span>
              <div id="radio-parent" *ngIf="question.type.toString() === 'Radio' && !(questionGroup.id === 0 || questionGroup.id === 1)">
                <div class="row">
                  <div class="form-group col-md-12">
                    <mat-grid-list cols="{{validQuestionOptions(question).length}}" rowHeight="60px" >
                      <mat-grid-tile id="mat-grid-option-display" *ngFor="let c of validQuestionOptions(question)"><span class="font-weight-bold displaySpan centerText">{{optionDisplay(c)}}</span></mat-grid-tile>
                    </mat-grid-list>

                    <div *ngIf="optionsHaveScore(question)">
                      <mat-grid-list cols="{{validQuestionOptions(question).length}}" rowHeight="60px" >
                        <mat-grid-tile id="mat-grid-option-display" *ngFor="let option of validQuestionOptions(question)"><span class="font-weight-bold displaySpan centerText" >{{radioOptionValueDisplay(option)}}</span></mat-grid-tile>
                      </mat-grid-list>
                    </div>

                    <mat-grid-list cols="{{validQuestionOptions(question).length}}" rowHeight="60px">
                      <mat-radio-group formControlName="{{question.id}}" >
                        <mat-grid-tile id="mat-grid-option-display" *ngFor="let c of validQuestionOptions(question)">
                          <mat-radio-button value="{{c.id}}" class="question-radio-button" (change)="questionFilled()"></mat-radio-button>
                        </mat-grid-tile>
                      </mat-radio-group>
                    </mat-grid-list>
                  </div>
                </div>
              </div>
              <div *ngIf="question.type.toString() === 'Scale'">
                <mat-card class="col-md-12 col-sm-10 col-xs-12">
                  <mat-card-content>
                    <div class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                      <div class="row col-md-12 col-sm-10 col-xs-12">
                        <span >{{ getIntervalTextValue(question, 0) }}</span>
                        <span class="far-right-text" >{{ getIntervalTextValue(question, 1) }}</span>
                      </div>
                      <mat-slider
                      color="secondary"
                      showTickMarks
                      [max]="getIntervalMax(question)"
                      [min]="getIntervalMin(question)"
                      [step]="getSliderTickInterval(question)"
                      #ngSlider>
                      <input matSliderThumb
                        formControlName="{{question.id}}"
                        (change)="questionFilled()"
                        #ngSliderThumb="matSliderThumb" />
                    </mat-slider>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div *ngIf="question.type.toString() === 'Select'">
                <mat-card class="col-md-12 col-sm-10 col-xs-12">
                  <mat-card-content>
                    <div class="row form-group col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                      <mat-select
                        #selectInput
                        id="{{question.id}}"
                        formControlName="{{question.id}}"
                        (input)="onSearchChange(selectInput.value)"
                        (change)="questionFilled()">
                        <mat-option *ngFor="let option of validQuestionOptions(question)"
                            [value]="option.id">
                            {{option.value}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="feedbackForm.get(getID(question.id))?.errors">Please enter an answer</mat-error>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
              <div *ngIf="question.type.toString() === 'Text'">
                <mat-card class="col-md-12 col-sm-10 col-xs-12">
                  <mat-card-content>
                    <mat-form-field appearance="outline" class="row form-group generated-text-div col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                      <textarea #textInput type="text"
                                matInput
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="5"
                                style="overflow:hidden"
                                id="{{getID(question.id)}}"
                                formControlName="{{getID(question.id)}}"
                                (keyup)="textQuestionFilled(textInput.value, question.id)"> </textarea>
                    </mat-form-field>
                  </mat-card-content>
                </mat-card>
              </div>
              <div *ngIf="question.type.toString() === 'Radio_Text'">
                <div class="row">
                  <div class="form-group col-md-12">
                    <mat-grid-list cols="{{validQuestionOptions(question).length}}" rowHeight="60px" >
                      <mat-grid-tile id="mat-grid-option-display" *ngFor="let option of validQuestionOptions(question)"><span class="font-weight-bold displaySpan centerText">{{optionDisplay(option)}}</span></mat-grid-tile>
                    </mat-grid-list>

                    <div *ngIf="optionsHaveScore(question)">
                      <mat-grid-list cols="{{validQuestionOptions(question).length}}" rowHeight="60px" >
                        <mat-grid-tile id="mat-grid-option-display" *ngFor="let option of validQuestionOptions(question)"><span class="font-weight-bold displaySpan centerText" >{{radioOptionValueDisplay(option)}}</span></mat-grid-tile>
                      </mat-grid-list>
                    </div>

                    <mat-grid-list cols="{{validQuestionOptions(question).length}}" rowHeight="60px">
                      <mat-radio-group formControlName="{{question.id}}" >
                        <mat-grid-tile id="mat-grid-option-display" *ngFor="let option of validQuestionOptions(question)">
                          <mat-radio-button value="{{option.id}}" class="question-radio-button" (change)="radioTextChange($event.value, question.id)"></mat-radio-button>
                        </mat-grid-tile>
                      </mat-radio-group>
                    </mat-grid-list>
                  </div>
                </div>
                <mat-card class="col-md-12 col-sm-10 col-xs-12">
                  <mat-card-content>
                    <mat-form-field appearance="outline" class="row form-group generated-text-div col-md-12 col-sm-10 col-xs-12" [ngClass]="{'has-error': feedbackForm.get(getID(question.id))?.errors && feedbackForm.get(getID(question.id))?.touched}">
                      <textarea #textInput type="text"
                                matInput
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="5"
                                style="overflow:hidden"
                                id="{{getRadioTextQuestionId(question.id)}}"
                                formControlName="{{getRadioTextQuestionId(question.id)}}"
                                (keyup)="textQuestionFilled(textInput.value, question.id)"> </textarea>
                    </mat-form-field>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="onlineSelectionMade" class="row form-group">
        <button type="button" mat-stroked-button class="save-btn" (click)="saveClientFeedback()" [disabled]="!(isQuestionFilled || isTextQuestionFilled) || isSaving || isSubmitting || isSubmitted || feedbackForm.pristine || !hasQuestions">
            <span *ngIf="isSaving"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span> SAVE
        </button>
        <button type="button" mat-stroked-button class="submit-btn" (click)="submitClientFeedback()" [disabled]="!feedbackForm.valid || isSubmitting || isSaving || !hasQuestions">
            <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span> SUBMIT
        </button>
      </div>
  </form>
</div>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>
