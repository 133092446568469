<vex-secondary-toolbar [current]="'feedback#cmFeedback#confidentialNotes'.includes(this.routePostfix) ?  'Provide' : 'My Surveys'">
  <vex-breadcrumbs *ngIf="this.routePostfix === 'feedback'"           [crumbs]="['Provide', 'Feedback']" class="flex-auto"></vex-breadcrumbs>
  <vex-breadcrumbs *ngIf="this.routePostfix === 'cmFeedback'"         [crumbs]="['Provide', 'CM Feedback']" class="flex-auto"></vex-breadcrumbs>
  <vex-breadcrumbs *ngIf="this.routePostfix === 'confidentialNotes'"  [crumbs]="['Provide', 'Confidential Notes']" class="flex-auto"></vex-breadcrumbs>
  <vex-breadcrumbs *ngIf="this.routePostfix === 'weeklyDelivery'"     [crumbs]="['My Surveys', 'Weekly PulseCheck']" class="flex-auto"></vex-breadcrumbs>
  <vex-breadcrumbs *ngIf="this.routePostfix === 'selfAssessment'"     [crumbs]="['My Surveys', 'Self Assessment']" class="flex-auto"></vex-breadcrumbs>
</vex-secondary-toolbar>

<div *ngIf="isLoading" class="grid place-items-center absolute inset-0">
  <mat-spinner></mat-spinner>
</div>

<div class="component-container" *ngIf="!isLoading">
  <div *ngIf="this.routePostfix === 'feedback'">
    <div class="mb-3">
      <h3 class="page-title col-md-3 col-sm-12 col-xs-12"> Provide Feedback</h3>

      <mat-form-field appearance="outline" class="col-md-2 col-sm-12 col-xs-12"
        *ifRole="cfrAdminRole">
        <mat-label>Choose Feedback Type</mat-label>
        <mat-select
          [disabled]="isThirdParty || isDraft || isFeedbackRequest || hasBeenSaved"
          name="feedbackFormType" [(value)]="selectedType"
          (selectionChange)="createFeedbackForm($event.value)"
          aria-label="Choose Feedback Type">
          <mat-option *ngFor="let formType of formTypes" [value]="formType"
            aria-label="Choose Feedback Type">{{ formType.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="isUnsolicited || isThirdParty">
        <mat-slide-toggle [disabled]="isDraft || hasBeenSaved"
          [checked]="isThirdParty"
          (change)="toggleThirdPartyForm()">
          3rd Party Feedback
        </mat-slide-toggle>
      </ng-container>

    </div>

    <p class="mandatory-text">* All questions marked with an asterisk are
      mandatory. They will need to be answered before the feedback can be
      submitted.</p>

    <div class="responsive row m-0">
      <div class="col-md-8 col-sm-12 col-xs-12 feedback-form-div">
        <app-feedback-form [feedbackId]="feedbackId"
          [feedbackForm]="feedbackForm"
          [selectedFormId]="selectedType!.Id"
          [selectedTypeLoaded]="selectedTypeLoaded"
          [questionGroupIDs]="questionGroupIDs"
          [questions]="questions"
          [questionOptions]="questionOptions"
          [questionOptionGroupIDs]="questionOptionGroupIDs"
          [isThirdParty]="isThirdParty"
          (draftCheck)="draftCheck($event)"
          (feedbackHasBeenSaved)="isSaved($event)">
        </app-feedback-form>
      </div>

      <div class="col-md-4 col-sm-12 col-xs-12">
        <div class="tips">

          <mat-expansion-panel class="no-border-radius" [expanded]="isLargeWindow">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h4><i class="fas fa-info-circle" title="Tips for Feedback"></i>
                  Tips for Feedback</h4>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <p>Thanks for taking the time to provide feedback on a fellow Onliner.
            </p>
            <p>Please consider their performance and overall business conduct, as
              well as how they demonstrate our core values and corporate
              citizenship.</p>
            <p>
              Please provide specific examples. Onliners want to grow
              professionally, so while you recognize their strengths and
              accomplishments,
              also identify ways in which they can continue to grow and develop.
            </p>
            <p>
              If the Onliner has agreed to make their goals public, they will be
              visible on the form,
              and we encourage you to refer to those specific areas wherever
              possible.
            </p>
          </mat-expansion-panel>

          <div class="credo">
            <mat-expansion-panel class="no-border-radius" [expanded]="isLargeWindow" >
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5>
                    The Onliner's Code<span class="small font-italic">CORE VALUES</span></h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div>
                <p class="text-center">
                  We look out for each other<br />
                  <span class="small font-italic">CARING & EMPATHY</span>
                </p>
                <p class="text-center">
                  We are energized by what we do<br />
                  <span class="small font-italic">PASSION</span>
                </p>
                <p class="text-center">
                  We do what's right<br />
                  <span class="small font-italic">INTEGRITY</span>
                </p>
                <p class="text-center">
                  We are always evolving<br />
                  <span class="small font-italic">FORWARD THINKING</span>
                </p>
                <p class="text-center">
                  We finish what we start<br />
                  <span class="small font-italic">COMMITMENT & PERSERVERANCE</span>
                </p>
              </div>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3" *ngIf="this.routePostfix === 'cmFeedback'">
    <div class="mb-3">
      <h3 class="page-title col-md-3 col-sm-12 col-xs-12"> Provide CM Feedback</h3>

      <mat-slide-toggle [checked]="isAnonymous"
        (change)="isAnonymous = !isAnonymous">
        Anonymous
      </mat-slide-toggle>
    </div>

    <p class="mandatory-text">* All questions marked with an asterisk are
      mandatory. They will need to be answered before the feedback can be
      submitted.</p>

    <div class="responsive row m-0">
      <div class="col-md-8 col-sm-12 col-xs-12 cm-feedback-form-div">
        <app-cm-feedback-form
          [feedbackId]="feedbackId"
          [feedbackForm]="feedbackForm"
          [selectedFormId]="selectedType!.Id"
          [questionGroupIDs]="questionGroupIDs"
          [questions]="questions"
          [questionOptions]="questionOptions"
          [questionOptionGroupIDs]="questionOptionGroupIDs"
          [isAnonymous]="isAnonymous"
          (formSubmit)="isAnonymous = false">
        </app-cm-feedback-form>
      </div>

      <div class="col-md-4 col-sm-12 col-xs-12">

        <mat-expansion-panel class="tips no-border-radius" [expanded]="isLargeWindow">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4><i class="fas fa-info-circle" title="Tips for Feedback"></i>
                Tips for Feedback</h4>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <p>Thanks for taking the time to provide feedback on your Career
            Mentor.</p>
          <p>
            Your feedback helps our Career Mentors understand where they are
            providing value and where they need to focus their efforts.
            It also helps us consider how we can improve the existing framework
            and support our Career Mentors in their development.
          </p>
          <p>There are two types of questions:</p>
          <ul>
            <li>Multiple choice ("quantitative"): Your responses to these
              questions are anonymous. Results will be summarized by People Care
              and shared with your Career Mentor.</li>
            <li>Open-ended ("qualitative"): These will be shared with your
              Career Mentor as you submit them. You can choose to remain
              anonymous if you prefer.</li>
          </ul>
        </mat-expansion-panel>

      </div>
    </div>
  </div>

  <div class="mb-3" *ngIf="this.routePostfix === 'weeklyDelivery'">
    <div class="mb-3" *ngIf="this.submittedForThisWeek">
      <p class="mandatory-text">Your submission for the week of
        {{formatDate(targetWeekMonday)}} - {{formatDate(targetWeek)}} is
        complete. Thank you for your attentiveness.
      </p>
    </div>
    <div class="mb-3" *ngIf="!this.submittedForThisWeek">
      <div class="mb-3">
        <h3 class="page-title col-md-6 col-sm-12 col-xs-12"> Provide Weekly
          PulseCheck</h3>

        <span *ngIf="presentFormSelected">
          <p class="col-md-6 col-sm-12 col-xs-12">For the week ending -
            {{formatDate(targetWeek)}}</p>
        </span>
      </div>

      <p class="mandatory-text">* All questions marked with an asterisk are
        mandatory. They will need to be answered before the feedback can be
        submitted.</p>

      <div class="row m-0">
        <div class="col-md-8 col-sm-12 col-xs-12 cm-feedback-form-div">
          <app-weekly-health-delivery-form
            [reportId]="feedbackId" [reportForm]="feedbackForm"
            [selectedFormId]="selectedType!.Id"
            [selectedTypeLoaded]="selectedTypeLoaded"
            [questionGroupIDs]="questionGroupIDs"
            [questions]="questions"
            [questionOptions]="questionOptions"
            [questionOptionGroupIDs]="questionOptionGroupIDs"
            (draftCheck)="draftCheck($event)"
            [targetWeek]="targetWeek"
            (updateFormSelection)="updateFormSelection($event)">
          </app-weekly-health-delivery-form>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-3" *ngIf="this.routePostfix === 'selfAssessment'">
    <div class="mb-3" *ngIf="this.submittedForThisYear === true">
      <p class="mandatory-text">
        Your <a class="redirect-link"
          (click)="onSubmittedSelfAssessmentLinkClick()" (keyup.enter)="onSubmittedSelfAssessmentLinkClick()" [tabindex]="0">Self Assessment for
          {{latestScheduled.fiscalYear}}</a> is complete.
        Thank you!
      </p>
    </div>
    <div class="mb-3"
      *ngIf="this.submittedForThisYear === false && !isRetreivingData">
      <h3 class="page-title col-md-12"> Provide Self-Assessment</h3>
      <table class="mt-2 mb-3">
        <tr>
          <td class="col-md-6 font-weight-bold"><span>Onliner</span></td>
          <td><span>{{user.firstName}} {{user.lastName}}</span></td>
        </tr>
        <tr>
          <td class="col-md-6 font-weight-bold"><span>Start Date</span></td>
          <td><span>{{startDate}}</span></td>
        </tr>
        <tr>
          <td class="col-md-6 font-weight-bold"><span>Primary Competency</span></td>
          <td><span>{{user.primaryCompetency}} </span></td>
        </tr>
        <tr>
          <td class="col-md-6 font-weight-bold"><span>Consultant Competency</span></td>
          <td><span>{{user.consultantCompetency}}</span></td>
        </tr>
        <tr>
          <td class="col-md-6 font-weight-bold"><span>Practice</span></td>
          <td><span>{{user.practice}}</span></td>
        </tr>
      </table>

      <p class="col-md-12 font-italic">
        This Self-Assessment is intended to elicit conversation between you,
        your CM, and your direct manager, and to encourage further dialogue
        around your strengths and your growth opportunities. A self-assessment
        ensures that we are self-aware, listening to the feedback others have
        given us, and are taking steps to grow our skills. For each section, you
        will see information that has been extracted from your Onliner Profile
        Summary. Each section asks you to evaluate yourself and provide comments
        to expand.
      </p>
      <p class="mandatory-text">* All questions marked with an asterisk are
        mandatory. They will need to be answered before the self-assessment can
        be submitted.</p>
      <div class="row m-0">
        <div class="col-md-12 col-sm-12 col-xs-12 feedback-form-div">
          <!--Component tag-->
          <app-self-assessment
            [reportId]="feedbackId"
            [reportForm]="feedbackForm"
            [selectedFormId]="selectedType!.Id"
            [selectedTypeLoaded]="selectedTypeLoaded"
            [questionGroupIDs]="questionGroupIDs"
            [questions]="questions"
            [questionOptions]="questionOptions"
            [questionOptionGroupIDs]="questionOptionGroupIDs"
            [role]="user.role"
            (draftCheck)="draftCheck($event)">
          </app-self-assessment>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="this.routePostfix === 'confidentialNotes'">
    <div class="mb-3">
      <h3 class="page-title col-md-6 col-sm-12 col-xs-12"> Provide Confidential
        Notes</h3>
    </div>
    <p class="mandatory-text">* All questions marked with an asterisk are
      mandatory. They will need to be answered before the feedback can be
      submitted.</p>
    <div class="row m-0">
      <div class="col-md-8 col-sm-12 col-xs-12">
        <app-confidential-feedback-form
          [feedbackId]="feedbackId"
          [feedbackForm]="feedbackForm"
          [selectedFormId]="selectedType!.Id"
          [selectedTypeLoaded]="selectedTypeLoaded"
          [questionGroupIDs]="questionGroupIDs"
          [questions]="questions"
          [questionOptions]="questionOptions"
          [questionOptionGroupIDs]="questionOptionGroupIDs">
        </app-confidential-feedback-form>
      </div>
      <div class="col-md-4 col-sm-12 col-xs-12">
      </div>
    </div>
  </div>
</div>
