<form *ngIf="!isLoading && reportForm" [formGroup]="reportForm" novalidate>

  <div *ngFor="let question of questionsForThisForm">
    <div *ngIf="question.type.toString() !== 'Date_Input'">
      <span class="label-header reduce-label-spacing"
        [ngClass]="{'required-label' : !question.isOptional }">{{
        displayQuestion(question) }}</span>
      <mat-card appearance="outlined" class="col-md-12 col-sm-10 col-xs-12">
        <mat-card-content>
          <div *ngIf="question.type.toString() === 'Radio'">
            <div class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': reportForm.get(getID(question.id))?.errors && reportForm.get(getID(question.id))?.touched}">
              <mat-radio-group formControlName="{{question.id}}">
                <mat-radio-button
                  *ngFor="let option of validQuestionOptions(question)"
                  [value]="option.id"
                  (change)="assignmentChangeCheck($event.value, question.id)">
                  <span class="radio-rap">{{ optionDisplay(option) }}</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div *ngIf="question.type.toString() === 'Radio_Text'">
            <div class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': reportForm.get(getID(question.id))?.errors && reportForm.get(getID(question.id))?.touched}">
              <mat-radio-group formControlName="{{question.id}}">
                <mat-radio-button
                  *ngFor="let option of validQuestionOptions(question)"
                  [value]="option.id" (change)="radioTextChange($event.value)">
                  <span class="radio-rap">{{ optionDisplay(option) }}</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <mat-form-field appearance="outline"
              class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': reportForm.get(getRadioTextQuestionId(question.id))?.errors && reportForm.get(getRadioTextQuestionId(question.id))?.touched }">
              <textarea type="text"
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="5"
                style="overflow:hidden"
                id="{{getRadioTextQuestionId(question.id)}}"
                formControlName="{{getRadioTextQuestionId(question.id)}}"
                placeholder="Enter comments here..."></textarea>
            </mat-form-field>
          </div>
          <div *ngIf="question.type.toString() === 'Scale'">
            <div class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': reportForm.get(getID(question.id))?.errors && reportForm.get(getID(question.id))?.touched}">
              <div class="row col-md-12 col-sm-10 col-xs-12">
                <span>{{ getIntervalTextValue(question, 0) }}</span>
                <span class="far-right-text">{{ getIntervalTextValue(question,
                  1) }}</span>
              </div>
              <mat-slider
                color="secondary"
                showTickMarks
                [max]="getIntervalMax(question)"
                [min]="getIntervalMin(question)"
                [step]="getSliderTickInterval(question)"
                >
                <input
                  matSliderThumb
                  formControlName="{{question.id}}" />
              </mat-slider>
            </div>
          </div>
          <div *ngIf="question.type.toString() === 'Select'">
            <div class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': reportForm.get(getID(question.id))?.errors && reportForm.get(getID(question.id))?.touched}">
              <mat-select
                #selectInput
                id="{{question.id}}"
                [formControlName]="question.id"
                (input)="onSearchChange(selectInput.value)">
                <mat-option
                  *ngFor="let option of validQuestionOptions(question)"
                  [value]="option.id">
                  {{option.value}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="reportForm.get(getID(question.id))?.errors">Please
                enter an answer</mat-error>
            </div>
          </div>
          <div *ngIf="question.type.toString() === 'Text'">
            <mat-form-field appearance="outline"
              class="row form-group col-md-12 col-sm-10 col-xs-12"
              [ngClass]="{'has-error': reportForm.get(getID(question.id))?.errors && reportForm.get(getID(question.id))?.touched}">
              <textarea type="text"
                matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="5"
                style="overflow:hidden; border: 1px;"
                id="{{getID(question.id)}}"
                formControlName="{{getID(question.id)}}"
                placeholder="Enter comments here..."> </textarea>
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <span *ngIf="question.type.toString() === 'Date_Input'"
      class="label-header reduce-label-spacing"
      [ngClass]="{'required-label' : !question.isOptional }">{{
      displayQuestion(question) }}</span>
    <mat-card appearance="outlined" *ngIf="question.id === 47"
      class="col-md-4 col-sm-10 col-xs-12">
      <mat-card-content>
        <mat-form-field class="col-md-12 search">
          <div
            [ngClass]="{'has-error': reportForm.get(getID(question.id))?.errors && reportForm.get(getID(question.id))?.touched}">
            <input
              matInput
              [readonly]="true"
              formControlName="{{question.id}}"
              [matDatepicker]="startDatePicker"
              placeholder="MM/DD/YYYY"
              maxDateRangeValidator="48"
              (click)="startDatePicker.open()"
              (focus)="startDatePicker.open()"
              [min]="minDate"
              [matDatepickerFilter]="$any(filterDates)">
            <mat-datepicker #startDatePicker></mat-datepicker>
          </div>
          <span class="error-message"
            *ngIf="reportForm.get(getID(question.id))?.hasError('datePatternError') && reportForm.get(getID(question.id))?.dirty">
            Must be in Date format
          </span>
          <span class="error-message"
            *ngIf="(reportForm.get(getID(question.id))?.hasError('invalidStartDate') && reportForm.get(getID(question.id))?.touched)">
            Start Date must be a date and before the End Date.
          </span>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" *ngIf="question.id === 48"
      class="col-md-4 col-sm-10 col-xs-12">
      <mat-card-content>
        <mat-form-field class="col-md-12 search">
          <div
            [ngClass]="{'has-error': reportForm.get(getID(question.id))?.errors && reportForm.get(getID(question.id))?.touched}">
            <input
              matInput
              [readonly]="true"
              formControlName="{{question.id}}"
              [matDatepicker]="endDatePicker"
              placeholder="MM/DD/YYYY"
              minDateRangeValidator="47"
              (click)="endDatePicker.open()"
              (focus)="endDatePicker.open()"
              [min]="minDate"
              [matDatepickerFilter]="$any(filterDates)">
            <mat-datepicker #endDatePicker></mat-datepicker>
          </div>
          <span class="error-message"
            *ngIf="reportForm.get(getID(question.id))?.hasError('datePatternError') && reportForm.get(getID(question.id))?.dirty">
            Must be in Date format
          </span>
          <span class="error-message"
            *ngIf="(reportForm.get(getID(question.id))?.hasError('invalidEndDate') && reportForm.get(getID(question.id))?.touched)">
            End Date must be a date and greater than Start Date.
          </span>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="row form-group">
    <button *ngIf="!vacationSelected" type="button" mat-stroked-button
      class="save-btn"
      color="basic"
      (click)="saveFeedback()"
      [disabled]="reportForm.pristine || isSubmitting || isSaving || submittedForThisWeek || !hasQuestions">
      <span *ngIf="isSaving"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>{{
      isSaved ? 'UPDATE' : 'SAVE' }}
    </button>
    <button type="button" mat-stroked-button
      class="submit-btn"
      color="primary"
      (click)="displayPreSubmissionConfirmation()"
      [disabled]="reportForm.invalid || isSubmitting || isSaving || (submittedForThisWeek && !vacationSelected) || !hasQuestions">
      <span *ngIf="isSubmitting"><i class="fa fa-spinner fa-spin"
          aria-hidden="true"></i></span> SUBMIT
    </button>
  </div>
</form>

<div *ngIf="isLoading" class="grid place-items-center inset-0">
  <mat-spinner></mat-spinner>
</div>

